import React from 'react';
import { Button, Grid, Card, CardContent, Typography, CardHeader } from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import TextField from '../../forms/TextField';
import { required, composeValidators, emailFormat } from '../../forms/utils';
import api from '../../../lib/api';
import useRoutes from '../../hooks/routes';
import { useHistory, Link } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import messages from './messages';
import useNotify from '../../hooks/notification';

const useSyles = makeStyles(theme => ({
  button: {
    width: '100%',
    padding: theme.spacing(0.5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  loginIcon: {
    width: '3em',
    height: 'auto',
    '@media screen and (max-height: 430px) and (orientation: landscape)': {
      width: '2em',
      height: 'auto',
    },
  },
  logoContainer: {
    '@media screen and (max-height: 430px) and (orientation: landscape)': {
      flex: 1,
    },
  },
  cardHeader: { textAlign: 'center', paddingBottom: '0px' },
  card: {
    padding: theme.spacing(2),
    '@media screen and (max-height: 430px) and (orientation: landscape)': {
      padding: theme.spacing(0),
    },
    width: '100%',
  },

  textError: {
    color: '#ea4554',
  },
  textField: {
    margin: theme.spacing(1),
    '@media screen and (max-height: 430px) and (orientation: landscape)': {
      marginTop: theme.spacing(0),
    },
    width: '100%',
  },
  signIn: { textDecoration: 'none', marginLeft: theme.spacing(1) },
  textRequest: { fontSize: theme.spacing(2), textAlign: 'center' },
  container: {
    marginTop: theme.spacing(2),
    width: '100%',

    [theme.breakpoints.down('xs')]: { marginTop: theme.spacing(1) },
  },
  cardContainer: {
    maxWidth: '480px',
  },
  text: { fontSize: theme.spacing(1.5), '&:hover': { textDecoration: 'underline' } },
  cardContent: { paddingTop: theme.spacing(1) },
}));

export const RequestNewPassword = () => {
  const classes = useSyles();
  const routes = useRoutes();
  const history = useHistory();
  const notify = useNotify();
  const intl = useIntl();

  const onSubmit = values => {
    api.auth
      .requestNewPassword(values)
      .then(() => {
        notify.success({ title: intl.formatMessage(messages.requestSent, { email: values.email }) });
        history.push(routes.resetAlmostThere.path);
      })
      .catch(() =>
        notify.error({
          title: intl.formatMessage(messages.error),
        }),
      );
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, pristine, invalid }) => (
        <form onSubmit={handleSubmit}>
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            className={classes.container}
            spacing={1}
          >
            <Grid item container xs={11} justify="center" className={classes.cardContainer}>
              <Card className={classes.card}>
                <Grid item container direction="column" alignItems="center" className={classes.logoContainer}>
                  <LockIcon color="secondary" className={classes.loginIcon} />
                  <CardHeader className={classes.cardHeader} title={<FormattedMessage {...messages.request} />} />
                </Grid>
                <CardContent className={classes.cardContent}>
                  <Grid container direction="column" alignItems="center">
                    <Field
                      required
                      validate={composeValidators(
                        required(intl.formatMessage(messages.required)),
                        emailFormat(intl.formatMessage(messages.invalidEmail)),
                      )}
                      className={classes.textField}
                      component={TextField}
                      label={<FormattedMessage {...messages.email} />}
                      name="email"
                      type="email"
                    />
                    <Button
                      type="submit"
                      disabled={pristine || invalid}
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      <Typography className={classes.textButton}>
                        <FormattedMessage {...messages.requestButton} />
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item container xs={12} justify="center" alignItems="center" className={classes.cardContainer}>
                    <Typography className={classes.text}>
                      <FormattedMessage {...messages.haveLogin} />
                    </Typography>
                    <Typography className={classes.signIn} to={routes.calendars.path} component={Link} color="primary">
                      <FormattedMessage {...messages.signIn} />
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default RequestNewPassword;
