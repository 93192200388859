import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText } from '@material-ui/core';

const DatePickerForm = ({ input: { value, onChange, name, ...restInput }, disabled, meta, ...rest }) => {
  const showError = meta.touched ? !meta.valid : meta.error && meta.touched && meta.dirty;

  return (
    <FormControl fullWidth>
      <KeyboardDatePicker
        {...rest}
        disabled={disabled}
        name={name}
        value={value.toString('dd/MM/yyyy') || null}
        ampm={false}
        onChange={onChange}
        format="dd/MM/yyyy"
        inputProps={restInput}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        InputLabelProps={{ shrink: true }}
      />
      {!showError && <FormHelperText style={{ color: 'red' }}>{meta.error || meta.submitError}</FormHelperText>}
    </FormControl>
  );
};
DatePickerForm.propTypes = {
  classes: PropTypes.object,
  input: PropTypes.object,
  meta: PropTypes.object,
  requiredField: PropTypes.bool,
  disabled: PropTypes.any,
};
export default DatePickerForm;
