import React, { useState, useEffect, Fragment } from 'react';
import { Grid, Typography, MenuItem, Box, Hidden } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Popover from '@material-ui/core/Popover';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AdjustIcon from '@material-ui/icons/Adjust';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { prop, filter, map, compose, propOr } from 'ramda';
import WarningIcon from '@material-ui/icons/Warning';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import Timeline from '../Timeline';
import { UserAvatar, MEDIUM } from '../Avatar';
import CalendarActionMenu from '../CalendarActionMenu';
import Timesheet from '../Timesheet';
import useNotify from '../../hooks/notification';
import useCurrentMonth from '../../hooks/currentMonth';
import useLoading from '../../hooks/loading';
import { timezone, projectTeamEventsTypes, companyTeamEventsTypes } from '../../../lib/models/events';
import { startOfMonth, endOfMonth } from 'date-fns';
import { convertEventToUTC } from '../Timeline/utils';
import { convertEventstoUTC, isActiveMember } from '../Timeline/utils';
import useRoutes from '../../hooks/routes';
import { convertSpareDaysFromUTC } from './utils';
import { isWorker, isHeadWorker } from '../../../lib/models/members';
import { isProject } from '../../../lib/models/teams';
import messages from './messages';
import { hasSpareDaysRight } from '../../roles';
import { useEvents } from './hooks';

const useStyles = makeStyles(theme => ({
  alert: {
    width: '100%',
    marginTop: theme.spacing(4),
  },
  agendaIcon: { marginRight: theme.spacing(1) },
  grow: {
    flexGrow: 1,
  },
  noPadding: {
    padding: 0,
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  info: {
    fontSize: '1.1em',
    marginBottom: theme.spacing(1),
  },
  icon: {
    fontSize: '12em',
    color: '#F0F0F0',
  },
  message: {
    color: theme.palette.grey[700],
    padding: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  legend: {
    marginLeft: theme.spacing(2),
    userSelect: 'none',
  },
  month: {
    userSelect: 'none',
  },
}));

const Title = ({ team }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(false);

  return (
    <Grid container alignItems="center" >
      <Grid item>
        <DateRangeIcon className={classes.agendaIcon} />
      </Grid>
      <Grid item>
        <Typography variant="h6">
          <FormattedMessage {...messages.calendar}></FormattedMessage>
        </Typography>
      </Grid>
      <Grid item>
        <Popover
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
        >
          <Alert
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAnchorEl(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {isProject(team) ? (
              <FormattedMessage {...messages.projectAlert} values={{ name: team.name }} />
            ) : (
              <FormattedMessage {...messages.companyAlert} values={{ name: team.name }} />
            )}
          </Alert>
        </Popover>
        <IconButton onClick={event => setAnchorEl(event.currentTarget)} className={classes.info} >
          <InfoIcon className={classes.info} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

Title.propTypes = {
  team: PropTypes.object.isRequired,
};

const Calendar = ({ team, user }) => {
  const classes = useStyles();
  const notify = useNotify();
  const setLoading = useLoading();
  const theme = useTheme();
  const routes = useRoutes();
  const history = useHistory();
  const currentMonth = useCurrentMonth();

  const activeTeam = {
    ...team,
    members: filter(member => isActiveMember(member, currentMonth()))(propOr([], 'members', team)),
  };

  const members = compose(
    filter(member => isHeadWorker(member) || isWorker(member)),
    prop('members'),
  )(activeTeam);

  const [calendarSpareDays, calendarEvents, eventsLoading] = useEvents(
    {
      teamId: team.id,
      from: convertEventToUTC(startOfMonth(currentMonth()), timezone),
      to: convertEventToUTC(endOfMonth(currentMonth()), timezone),
    },
    {
      onError: error => notify.error({ title: error.message }),
    },
  );

  const spareDays = convertSpareDaysFromUTC(calendarSpareDays);
  const events = convertEventstoUTC(calendarEvents, timezone);

  useEffect(() => {
    setLoading(eventsLoading);
  }, [eventsLoading, setLoading]);

  return (
    <>
      <Grid container direction="row" justify="space-between" alignItems="center" className={classes.title}>
        <Grid item>
          <Title team={team} />
        </Grid>
          <Hidden xsDown>
            <Grid item>
              <CalendarActionMenu>
                <MenuItem>
                  <Timesheet
                    team={team}
                    members={members}
                    date={currentMonth()}
                    spareDays={spareDays}
                    events={events}
                  />
                </MenuItem>
                {hasSpareDaysRight(user) && (
                  <MenuItem
                    onClick={() =>
                      history.push({
                        pathname: routes.spareDayCreate.path,
                        state: { id: team.id, country: team.country },
                      })
                    }
                  >
                    <Grid container spacing={2}>
                      <Grid item>
                        <EventBusyIcon />
                      </Grid>
                      <Grid item>
                        <Typography>
                          <FormattedMessage {...messages.addSpareDay}></FormattedMessage>
                        </Typography>
                      </Grid>
                    </Grid>
                  </MenuItem>
                )}
              </CalendarActionMenu>
            </Grid>
          </Hidden>
      </Grid>
      <Grid id={`calendar-${team.id}`} data-testid="calendar" container justify="flex-start" alignItems="center">
        <Grid item container alignItems="center" justify="flex-start">
          <Grid item sm={12} container justify="center" alignItems="center" wrap="nowrap">
            <Grid item></Grid>
            <Grid item className={classes.month}>
              <Typography>
                <FormattedDate value={currentMonth()} month="long" year="numeric" />
              </Typography>
            </Grid>
          </Grid>
          <Grid item sm={12} container justify="center" wrap="nowrap" alignItems="center">
            <Grid item>
              <IconButton onClick={() => currentMonth.previous()}>
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => currentMonth.now()}
                disabled={
                  getMonth(currentMonth()) === getMonth(new Date()) && getYear(currentMonth()) === getYear(new Date())
                }
              >
                <AdjustIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton onClick={() => currentMonth.next()}>
                <ArrowForwardIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={1}>
        </Grid>
        <Grid item container sm={11}>
          <Timeline
            month={currentMonth()}
            spareDays={spareDays}
            events={events}
            resourceAvatar={user => (
              <UserAvatar
                className={classes.noPadding}
                user={user}
                showTooltip
                size={MEDIUM}
                to={routes.getPathByName('userEdit', user.id)}
              />
            )}
            team={activeTeam}
            user={user}
          />
        </Grid>
        <Grid item sm={1}>
        </Grid>
        {members.length ? (
          <Grid item sm={5} container spacing={1} className={classes.legend}>
            {map(type => (
              <Fragment key={type}>
                <Grid item>
                  <Box
                    style={{ width: '20px', height: '20px', backgroundColor: theme.palette[type], borderRadius: '50%' }}
                  ></Box>
                </Grid>
                <Grid item>
                  <Typography>
                    <FormattedMessage {...messages.type[type]}></FormattedMessage>
                  </Typography>
                </Grid>
              </Fragment>
            ))(isProject(team) ? projectTeamEventsTypes : companyTeamEventsTypes)}
          </Grid>
        ) : (
          <Grid container item direction="column" justify="center" alignItems="center">
            <Grid item>
              <WarningIcon className={classes.icon} />
            </Grid>
            <Grid item>
              <Typography variant="h6" className={classes.message} align="center">
                <FormattedMessage {...messages.noMembers}></FormattedMessage>
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

Calendar.propTypes = {
  team: PropTypes.object.isRequired,
  user: PropTypes.object,
};
export default Calendar;
