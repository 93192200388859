import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import useStyles from './styles';
import { useDebounce } from './hooks';
import messages from './messages';

const DEBOUNCE_DELAY = 500;

const SearchBar = ({
  value,
  placeholder: incomingPlaceholder,
  onChange,
  onSubmit,
  disabled = false,
  minify = false,
  className,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const [isFocus, setIsFocus] = useState(false);
  const [searchTerm, setSearchTerm] = useState(value);
  const debouncedSearchTerm = useDebounce(searchTerm, DEBOUNCE_DELAY);

  useEffect(() => {
    setSearchTerm(value);
  }, [value]);

  useEffect(() => {
    onChange(debouncedSearchTerm);
  }, [debouncedSearchTerm, onChange]);

  const handleClear = () => {
    setSearchTerm('');
    onSubmit && onSubmit('');
  };
  const handleChange = event => setSearchTerm(event.target.value);
  const handleFocus = isFocus => () => setIsFocus(isFocus);
  const handleSubmit = () => onSubmit && onSubmit(searchTerm);
  const handleKeyPress = event => event.key === 'Enter' && handleSubmit();

  const placeholder = incomingPlaceholder || intl.formatMessage(messages.defaultPlaceholder);

  return (
    <Paper
      className={classNames(
        classes.root,
        {
          [classes.extend]: isFocus && !minify,
          [classes.highlight]: isFocus || onSubmit,
          [classes.hover]: !isFocus && !onSubmit,
        },
        className,
      )}
    >
      <Grid container alignItems="center">
        {!minify && !onSubmit && (
          <Box className={classes.iconBox}>
            <SearchIcon className={classes.searchIcon} />
          </Box>
        )}
        <InputBase
          value={searchTerm}
          className={classes.input}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={handleFocus(false)}
          onFocus={handleFocus(true)}
          disabled={disabled}
          onKeyPress={handleKeyPress}
        />
        {searchTerm && (
          <IconButton className={classes.clearBox} onClick={handleClear}>
            <ClearIcon className={classes.clearIcon} />
          </IconButton>
        )}
        {onSubmit && (
          <Box className={classes.searchBox} onClick={handleSubmit}>
            <SearchIcon />
          </Box>
        )}
      </Grid>
    </Paper>
  );
};

SearchBar.propTypes = {
  placeholder: string,
  value: string,
  onChange: func.isRequired,
  onSubmit: func,
  disabled: bool,
  className: string,
  minify: bool,
};

export default SearchBar;
