import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'components.Teams.title',
    defaultMessage: 'Teams',
  },
  createTeam: {
    id: 'components.Teams.createTeam',
    defaultMessage: 'Create Team',
  },
  name: {
    id: 'components.Teams.name',
    defaultMessage: 'Name',
  },
  description: {
    id: 'components.Teams.description',
    defaultMessage: 'Description',
  },
  members: {
    id: 'components.Teams.members',
    defaultMessage: 'Members',
  },
  teamCreated: {
    id: 'components.Teams.teamCreated',
    defaultMessage: 'Team {name} created',
  },
  cannotCreateNewTeam: {
    id: 'components.Teams.cannotCreateNewTeam',
    defaultMessage: 'Cannot create the team',
  },
  addMembers: {
    id: 'components.Teams.addMembers',
    defaultMessage: 'ADD MEMBERS',
  },
  user: {
    id: 'components.Teams.user',
    defaultMessage: 'User',
  },
  role: {
    id: 'components.Teams.role',
    defaultMessage: 'Role',
  },
  expirationDate: {
    id: 'components.Teams.expirationDate',
    defaultMessage: 'Expiration date',
  },
  confirm: {
    id: 'components.Teams.confirm',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'components.Teams.cancel',
    defaultMessage: 'Cancel',
  },
  teamEdited: {
    id: 'components.Teams.teamEdited',
    defaultMessage: 'Team {name} updated',
  },
  cannotEditTeam: {
    id: 'components.Teams.cannotEditTeam',
    defaultMessage: 'Cannot edit the team',
  },
  teamDeleted: {
    id: 'components.Teams.teamDeleted',
    defaultMessage: 'Team deleted',
  },
  cannotDeleteTeam: {
    id: 'components.Teams.cannotDeleteTeam',
    defaultMessage: 'Failed to delete team!',
  },
  requiredName: {
    id: 'components.Teams.requiredName',
    defaultMessage: 'Required Name',
  },
  nameTaken: {
    id: 'components.Teams.nameTaken',
    defaultMessage: 'Name already exists !',
  },

  requiredSelectUser: {
    id: 'components.Teams.requiredSelectUser',
    defaultMessage: 'User Required',
  },
  requiredRole: {
    id: 'components.Teams.requiredRole',
    defaultMessage: 'Role Required',
  },
  noData: {
    id: 'components.Teams.noData',
    defaultMessage: 'No data available',
  },
  editTeam: {
    id: 'components.Teams.editTeam',
    defaultMessage: 'Edit Team',
  },
  worker: {
    id: 'components.Teams.worker',
    defaultMessage: 'Worker',
  },
  observer: {
    id: 'components.Teams.observer',
    defaultMessage: 'Observer',
  },
  manager: {
    id: 'components.Teams.manager',
    defaultMessage: 'Manager',
  },
  headWorker: {
    id: 'components.Teams.headWorker',
    defaultMessage: 'HeadWorker',
  },
  type: {
    company: {
      id: 'components.Teams.company',
      defaultMessage: 'Company',
    },
    project: {
      id: 'components.Teams.project',
      defaultMessage: 'Project',
    },
  },
  noMoreMembers: {
    id: 'components.Teams.noMoreMembers',
    defaultMessage: 'No More Members To Add',
  },
  country: {
    id: 'components.Teams.country',
    defaultMessage: 'Country',
  },
  typeLabel: {
    id: 'components.Teams.type',
    defaultMessage: 'Type',
  },
  timeZone: {
    id: 'components.Teams.timeZone',
    defaultMessage: 'Time Zone',
  },
  requiredCountry: {
    id: 'components.Teams.requiredCountry',
    defaultMessage: 'Required Country',
  },
  requiredType: {
    id: 'components.Teams.requiredType',
    defaultMessage: 'Required Type',
  },
  requiredTimeZone: {
    id: 'components.Teams.requiredTimeZone',
    defaultMessage: 'Required Time Zone',
  },
  teams: {
    id: 'components.Teams.teams',
    defaultMessage: 'Teams',
  },
  inceptionDate: {
    id: 'components.Teams.inceptionDate',
    defaultMessage: 'Inception Date',
  },
  prompt: {
    id: 'components.Teams.prompt',
    defaultMessage: 'Are you sure you want to leave?',
  },
  delete: {
    id: 'components.Teams.delete',
    defaultMessage: 'Delete',
  },
  teamDeleteMessage: {
    id: 'components.Teams.userDeleteMessage',
    defaultMessage: 'Are you sure you want to delete the team "{name}" ?',
  },
  calendar: {
    id: 'components.Teams.calendar',
    defaultMessage: 'View Calendar',
  },
  noOptions: {
    id: 'components.Teams.noOptions',
    defaultMessage: 'No Options',
  },
});
