import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { FormControl, FormHelperText } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
  },
  chip: {
    margin: '2px',
  },

  inputRoot: {
    padding: '3px 24px 7px 0',
  },
  inputNoEdit: {
    minWidth: 0,
  },
  inputEdit: {
    minWidth: '100%',
  },
  popupIndicator: {
    position: 'absolute',
    right: 0,
    padding: 2,
    color: theme.palette.action.active,
  },
  clearIndicator: {
    position: 'absolute',
    right: 25,
    padding: 2,
    color: theme.palette.action.active,
    visibility: 'hidden',
  },
}));

const AutocompleteField = ({
  input: { value, onChange },
  options,
  isDisabled,
  requiredField,
  meta,
  label,
  ...rest
}) => {
  const classes = useStyles();
  const showError = meta.touched && requiredField ? !meta.valid : meta.error && meta.touched && meta.dirty;

  return (
    <FormControl fullWidth>
      <Autocomplete
        options={options}
        onChange={(event, value) => onChange(value)}
        value={value ? value : null}
        renderInput={params => <TextField label={label} fullWidth {...params} />}
        className={classes.formControl}
        classes={{
          inputRoot: classes.inputRoot,
          input: isDisabled ? classes.inputNoEdit : classes.inputEdit,
          popupIndicator: classes.popupIndicator,
          clearIndicator: classes.clearIndicator,
        }}
        {...rest}
      />
      {showError && <FormHelperText style={{ color: 'red' }}>{meta.error || meta.submitError}</FormHelperText>}
    </FormControl>
  );
};

AutocompleteField.propTypes = {
  input: PropTypes.object.isRequired,
  options: PropTypes.array,
  isDisabled: PropTypes.bool,
  label: PropTypes.object,
  meta: PropTypes.object,
  requiredField: PropTypes.bool,
};

export default AutocompleteField;
