import React, { useEffect, useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import { useApolloClient } from '@apollo/react-hooks';
import TextField from '../../forms/TextField';
import SelectField from '../../forms/SelectField';
import MultiSelectField from '../../forms/MultiSelectField';
import { useHistory, Prompt } from 'react-router';
import useRoutes from '../../hooks/routes';
import { useCreateUser } from './hooks';
import { reject, map, values as rvalues } from 'ramda';
import { EMPTY_ARRAY } from './utils';
import { isAdmin, RIGHT } from '../../roles';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { composeValidators, required, requiredSelect, emailFormat, checkEmailUniqueness } from '../../forms/utils';
import ConfirmButton from '../Buttons/confirm';
import CancelButton from '../Buttons/cancel';
import useNotify from '../../hooks/notification';
import useLoading from '../../hooks/loading';
import NavTitle from '../NavTitle';
import { useLoggedUser } from '../../hooks/user';
import { ROLE, STATUS } from '../../../lib/models/user';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
  },
  screen: {
    height: '80vh',
  },
  form: {
    width: '100%',
  },
  field: { marginTop: theme.spacing(3) },
  buttonContainer: { marginTop: theme.spacing(1), [theme.breakpoints.down('xs')]: {} },
  button: { marginTop: theme.spacing(2) },
  root: { width: '100%' },
  teamsContainer: { marginTop: theme.spacing(2) },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: 100,
  },
}));

const UserCreate = () => {
  const classes = useStyles();
  const [loggedUser, loadingLoggedUser] = useLoggedUser();
  const routes = useRoutes();
  const history = useHistory();
  const notify = useNotify();
  const setLoading = useLoading();
  const intl = useIntl();
  const [showPasswordField, setShowPasswordField] = useState(false);

  const [createUser, { loading: createLoading }] = useCreateUser({
    onCompleted: ({ createUser }) =>
      notify.success({ title: intl.formatMessage(messages.userCreated, { fullname: createUser.fullname }) }),
    onError: error => notify.error({ title: `${intl.formatMessage(messages.cannotCreateNewUser)} : ${error}` }),
  });

  const apolloClient = useApolloClient();

  const onSubmit = async values => {
    await createUser({
      variables: {
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        roles: values.roles,
        status: values.status,
        sendInvitation: true,
        password: values.password,
      },
    });
    history.push(routes.userList.path);
  };

  useEffect(() => {
    setLoading(createLoading || loadingLoggedUser);
  }, [createLoading, loadingLoggedUser, setLoading]);

  useEffect(() => {
    if (!isAdmin(loggedUser)) history.replace(routes.getDefaultPath());
  }, []); // eslint-disable-line

  return (
    <>
      <NavTitle message={intl.formatMessage(messages.createUser)} />
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, pristine, invalid, submitting, form, values }) => (
          <form onSubmit={handleSubmit}>
            <Prompt when={!pristine && !submitting} message={intl.formatMessage(messages.prompt)} />
            <Grid container alignItems="flex-start" spacing={3}>
              <Grid item xs={12} sm={6}>
                <Field
                  fullWidth
                  required
                  validate={required(<FormattedMessage {...messages.requiredFirstname} />)}
                  component={TextField}
                  label={<FormattedMessage {...messages.firstname} />}
                  name="firstname"
                  data-testid="user.create.input.firstname"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  fullWidth
                  required
                  validate={required(<FormattedMessage {...messages.requiredLastname} />)}
                  component={TextField}
                  label={<FormattedMessage {...messages.lastname} />}
                  name="lastname"
                  data-testid="user.create.input.lastname"
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  fullWidth
                  required
                  validate={composeValidators(
                    required(<FormattedMessage {...messages.requiredEmail} />),
                    emailFormat(<FormattedMessage {...messages.invalidEmail} />),
                    checkEmailUniqueness(apolloClient)(<FormattedMessage {...messages.emailTaken} />),
                  )}
                  component={TextField}
                  label={<FormattedMessage {...messages.email} />}
                  name="email"
                  data-testid="user.create.input.email"
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  fullWidth
                  required
                  component={MultiSelectField}
                  validate={composeValidators(
                    required(<FormattedMessage {...messages.requiredRoles} />),
                    requiredSelect(<FormattedMessage {...messages.requiredRoles} />),
                  )}
                  label={<FormattedMessage {...messages.roles} />}
                  name="roles"
                  initialValue={EMPTY_ARRAY}
                  data-testid="user.create.input.roles"
                  renderValue={selected => (
                    <Grid className={classes.chips}>
                      {selected.map(value => (
                        <Chip
                          onMouseDown={event => {
                            event.stopPropagation();
                          }}
                          key={value}
                          onDelete={() =>
                            form.change(
                              'roles',
                              reject(v => v === value, values.roles),
                            )
                          }
                          label={<FormattedMessage {...messages.role[value]} />}
                        />
                      ))}
                    </Grid>
                  )}
                >
                  {map(role => (
                    <MenuItem key={role} value={role}>
                      <FormattedMessage {...messages.role[role]} />
                    </MenuItem>
                  ))(rvalues(ROLE))}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field
                  readOnly={!isAdmin(loggedUser)}
                  fullWidth
                  component={MultiSelectField}
                  label={<FormattedMessage {...messages.rights} />}
                  name="rights"
                  data-testid="user.edit.input.rights"
                  renderValue={selected => (
                    <Grid className={classes.chips}>
                      {selected.map(value => (
                        <Chip
                          onMouseDown={event => {
                            event.stopPropagation();
                          }}
                          key={value}
                          onDelete={() =>
                            form.change(
                              'rights',
                              reject(v => v === value, values.rights),
                            )
                          }
                          label={<FormattedMessage {...messages.right[value]} />}
                        />
                      ))}
                    </Grid>
                  )}
                >
                  {map(right => (
                    <MenuItem key={right} value={right}>
                      <FormattedMessage {...messages.right[right]} />
                    </MenuItem>
                  ))(rvalues(RIGHT))}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  component={SelectField}
                  name="status"
                  label={<FormattedMessage {...messages.statusLabel} />}
                  disabled={!isAdmin(loggedUser)}
                  data-testid="user.create.input.status"
                  initialValue="pending"
                >
                  {map(status => (
                    <MenuItem key={status} value={status}>
                      <FormattedMessage {...messages.status[status]} />
                    </MenuItem>
                  ))(rvalues(STATUS))}
                </Field>
              </Grid>
              {showPasswordField && (
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    required
                    type="password"
                    validate={required(<FormattedMessage {...messages.requiredPassword} />)}
                    component={TextField}
                    label={<FormattedMessage {...messages.password} />}
                    name="password"
                    data-testid="user.create.input.password"
                  />
                </Grid>
              )}
              <FormSpy
                subscription={{
                  values: true,
                }}
                onChange={async ({ values }) => {
                  if (values.status === STATUS.active) {
                    setShowPasswordField(true);
                  } else {
                    setShowPasswordField(false);
                  }
                }}
              />
              <Grid item xs={12} sm={6}>
                <CancelButton data-testid="user.create.cancel" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ConfirmButton data-testid="user.create.submit" disabled={pristine || invalid} />
              </Grid>
            </Grid>
          </form>
        )}
      />
    </>
  );
};

export default UserCreate;
