import axios from 'axios';
import { prop } from 'ramda';

export default ({ endPoint, bearer }) => ({
  signIn: (email, password) => axios.post(endPoint(`/api/auth/signIn`), { email, password }).then(prop('data')),
  signUp: (firstname, lastname, email, password) =>
    axios.post(endPoint(`/api/auth/signUp`), { firstname, lastname, email, password }).then(prop('data')),
  signInWithGoogle: token => axios.post(endPoint(`/api/auth/signInWithGoogle`), { token }).then(prop('data')),
  signUpWithGoogle: token => axios.post(endPoint(`/api/auth/signUpWithGoogle`), { token }).then(prop('data')),
  signInWithFacebook: ({ accessToken, userID, email }) =>
    axios.post(endPoint(`/api/auth/signInWithFacebook`), { accessToken, userID, email }).then(prop('data')),
  signUpWithFacebook: ({ accessToken, userID, email }) =>
    axios.post(endPoint(`/api/auth/signUpWithFacebook`), { accessToken, userID, email }).then(prop('data')),
  resetPassword: password =>
    axios.post(endPoint(`/api/auth/resetPassword`), { password }, { headers: bearer() }).then(prop('data')),
  confirmAccount: password =>
    axios.post(endPoint(`/api/auth/confirmAccount`), { password }, { headers: bearer() }).then(prop('data')),
  confirmAccountWithGoogle: token =>
    axios.post(endPoint(`/api/auth/confirmAccountWithGoogle`), { token }, { headers: bearer() }).then(prop('data')),
  confirmAccountWithFacebook: ({ accessToken, userID, email }) =>
    axios
      .post(endPoint(`/api/auth/confirmAccountWithFacebook`), { accessToken, userID, email }, { headers: bearer() })
      .then(prop('data')),
  requestNewPassword: ({ email }) => axios.post(endPoint(`/api/auth/requestNewPassword`), { email }).then(prop('data')),
  signOut: () => axios.get(endPoint(`/api/auth/signOut`), { headers: bearer() }).then(prop('data')),
  getUser: () => axios.get(endPoint(`/api/auth/getUser`), { headers: bearer() }).then(prop('data')),
});
