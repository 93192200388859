import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl, FormattedMessage, FormattedDate } from 'react-intl';
import messages from './messages';
import useNotify from '../../hooks/notification';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  TableSortLabel,
  IconButton,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import useRoutes from '../../hooks/routes';
import useLoading from '../../hooks/loading';
import { useDeleteSpareDay, useSpareDays } from './hooks';
import NavTitle from '../NavTitle';
import AlertDialog, { AlertDialogContent, AlertDialogTitle } from '../AlertDialog';
import { convertSpareDays, convertDateTimeToUTC, sortCountriesByName } from './utils';
import { equals, map, isEmpty } from 'ramda';
import { startOfYear, endOfYear, getYear } from 'date-fns';
import { getName } from 'country-list';
import ReactCountryFlag from 'react-country-flag';
import { hasSpareDaysRight } from '../../roles';
import { useLoggedUser } from '../../hooks/user';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  root: {
    height: '100%',
    width: '100%',
    overflowX: 'auto',
    margin: 'auto',
  },
  row: {
    cursor: 'pointer',
  },
  bold: {
    fontWeight: 'bold',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const SpareDayList = () => {
  const classes = useStyles();
  const intl = useIntl();
  const routes = useRoutes();
  const setLoading = useLoading();
  const [loggedUser, loadingLoggedUser] = useLoggedUser();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('from');
  const [year, setYear] = useState(getYear(new Date()));
  const [country, setCountry] = useState('');
  const [filter, setFilter] = useState({
    from: convertDateTimeToUTC(startOfYear(new Date(year, 1, 1))),
    to: convertDateTimeToUTC(endOfYear(new Date(year, 1, 1))),
  });
  const notify = useNotify();
  const history = useHistory();
  const [indexOfRowTodDelete, setIndexOfRowTodDelete] = useState(-1);

  const handleEditRow = spareDayId => {
    history.push(routes.getPathByName('spareDayEdit', spareDayId));
  };

  const handleEditTeam = teamId => {
    history.push(routes.getPathByName('teamEdit', teamId));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };

  const handleYearChange = event => {
    setYear(event.target.value);
    setFilter({
      ...filter,
      from: convertDateTimeToUTC(startOfYear(new Date(event.target.value, 0, 1))),
      to: convertDateTimeToUTC(endOfYear(new Date(event.target.value, 0, 1))),
    });
  };

  const handleCountryChange = event => {
    setCountry(event.target.value);
    setFilter({ ...filter, country: event.target.value });
  };

  const requestParams = {
    orderBy: orderBy + '_' + order.toUpperCase(),
    filter,
  };

  const [spareDays, countries, years, loading] = useSpareDays(requestParams, {
    onError: error => notify.error({ title: error.message }),
  });

  const [deleteSpareDay, { loading: deleteLoading }] = useDeleteSpareDay({
    onCompleted: () => notify.success({ title: intl.formatMessage(messages.spareDayDeleted) }),
    onError: () => notify.error({ title: intl.formatMessage(messages.cannotDeleteSpareDay) }),
  });

  const handleDelete = id => {
    deleteSpareDay({ variables: { id } });
  };

  useEffect(() => {
    setLoading(loading || deleteLoading || loadingLoggedUser);
  }, [loading, deleteLoading, setLoading, loadingLoggedUser]);

  useEffect(() => {
    if (!hasSpareDaysRight(loggedUser)) history.replace(routes.getDefaultPath());
  }, []); // eslint-disable-line

  return (
    <>
      <NavTitle message={intl.formatMessage(messages.title)} />
      <Grid data-testid="spareday.list" container spacing={2} direction="column" alignItems="center">
        <Grid item container justify="center">
          <Grid item>
            <FormControl className={classes.formControl}>
              <InputLabel shrink>
                <FormattedMessage {...messages.yearLabel} />
              </InputLabel>
              {years && (
                <Select value={year} onChange={handleYearChange}>
                  {map(year => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))(years)}
                </Select>
              )}
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl className={classes.formControl}>
              <InputLabel shrink>
                <FormattedMessage {...messages.countryLabel} />
              </InputLabel>
              {countries && (
                <Select value={country} onChange={handleCountryChange} displayEmpty>
                  <MenuItem value="">
                    <FormattedMessage {...messages.allCountries} />
                  </MenuItem>
                  {map(country => (
                    <MenuItem key={country} value={country}>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <ReactCountryFlag
                            style={{
                              width: '1.3em',
                              height: '1.3em',
                            }}
                            countryCode={country}
                            svg
                          />
                        </Grid>
                        <Grid item>
                          <Typography>{getName(country)}</Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  ))(sortCountriesByName(countries))}
                </Select>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid item className={classes.root}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'from'}
                      direction={orderBy === 'from' ? order : 'asc'}
                      onClick={createSortHandler('from')}
                      className={classes.bold}
                    >
                      <FormattedMessage {...messages.day} />
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'label'}
                      direction={orderBy === 'label' ? order : 'asc'}
                      onClick={createSortHandler('label')}
                      className={classes.bold}
                    >
                      <FormattedMessage {...messages.label} />
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'country'}
                      direction={orderBy === 'country' ? order : 'asc'}
                      onClick={createSortHandler('country')}
                      className={classes.bold}
                    >
                      <FormattedMessage {...messages.country} />
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'team'}
                      direction={orderBy === 'team' ? order : 'asc'}
                      onClick={createSortHandler('team')}
                      className={classes.bold}
                    >
                      <FormattedMessage {...messages.team} />
                    </TableSortLabel>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {convertSpareDays(spareDays).map((spareDay, index) => (
                  <TableRow
                    hover
                    onClick={() => handleEditRow(spareDay.id)}
                    className={classes.row}
                    data-testid={`spareday#${spareDay.id}`}
                    key={spareDay.id}
                  >
                    <TableCell component="th" scope="row">
                      <FormattedDate value={spareDay.from} year="numeric" month="long" day="numeric" weekday="long" />
                    </TableCell>
                    <TableCell align="center">{spareDay.label}</TableCell>
                    <TableCell align="center">
                      {spareDay?.country ? (
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item>
                            <ReactCountryFlag
                              style={{
                                width: '1.5em',
                                height: '1.5em',
                              }}
                              countryCode={spareDay.country}
                              svg
                            />
                          </Grid>
                          <Grid item>
                            <Typography>{getName(spareDay.country)}</Typography>
                          </Grid>
                        </Grid>
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {spareDay?.team ? (
                        <Chip
                          label={spareDay.team.name}
                          onClick={e => {
                            e.stopPropagation();
                            handleEditTeam(spareDay.team.id);
                          }}
                          style={{ zIndex: 3 }}
                        />
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        wrap="nowrap"
                        onClick={event => event.stopPropagation()}
                      >
                        <Grid item>
                          <IconButton
                            onClick={() => history.push(routes.getPathByName('spareDayEdit', spareDay.id))}
                            color="primary"
                          >
                            <EditIcon />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <IconButton
                            onClick={() => {
                              setIndexOfRowTodDelete(index);
                            }}
                            style={{ zIndex: 5 }}
                          >
                            <DeleteIcon style={{ color: 'red' }} />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <AlertDialog
                            open={equals(indexOfRowTodDelete, index)}
                            onClose={() => setIndexOfRowTodDelete(-1)}
                            onConfirm={() => {
                              setIndexOfRowTodDelete(-1);
                              handleDelete(spareDay.id);
                            }}
                          >
                            <AlertDialogTitle>
                              <FormattedMessage {...messages.delete} />
                            </AlertDialogTitle>
                            <AlertDialogContent>
                              <FormattedMessage
                                {...messages.spareDayDeleteMessage}
                                values={{ label: spareDay.label }}
                              />
                            </AlertDialogContent>
                          </AlertDialog>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {isEmpty(spareDays) && (
          <Grid item>
            <Typography>
              <FormattedMessage {...messages.noSpareDays} />
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default SpareDayList;
