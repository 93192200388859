import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { equals, prop } from 'ramda';
import { startOfMonth, endOfMonth } from 'date-fns';
import { NO_TEAM } from '../../../lib/models/teams';
import { convertDateFromUTC } from './utils';

export const GET_PERIOD_SPARE_DAYS = gql`
  query PeriodSpareDays($filter: SpareDayFilter) {
    allSpareDays(filter: $filter) {
      id
      from
      label
      country
      team {
        id
      }
    }
  }
`;

export const GET_SPARE_DAY = gql`
  query SpareDay($id: ID!) {
    SpareDay(id: $id) {
      id
      from
      label
      country
      team {
        id
        name
        country
      }
    }
  }
`;

export const usePeriodSpareDays = (params, options) => {
  let filter, from, to, country, teamId;

  if (!params) [from, to] = [startOfMonth(new Date()), endOfMonth(new Date())];
  else if (params.from && !params.to) [from, to] = [startOfMonth(params.from), endOfMonth(params.from)];
  else if (params.from && params.to) [from, to] = [startOfMonth(params.from), endOfMonth(params.to)];
  else [from, to] = [startOfMonth(params), endOfMonth(params)];

  filter = { from, to };
  if (params) {
    country = params.country;
    teamId = params.teamId;
    filter = equals(teamId, NO_TEAM) ? { ...filter, country } : { ...filter, teamId };
  }

  const res = useQuery(GET_PERIOD_SPARE_DAYS, {
    ...options,
    fetchPolicy: 'no-cache',
    variables: {
      filter: filter,
    },
  });
  return [res.data?.allSpareDays || [], res.loading, res.error];
};

export const GET_SPARE_DAYS = gql`
  query SpareDays($first: Int, $skip: Int, $orderBy: String, $filter: SpareDayFilter) {
    items: allSpareDays(first: $first, skip: $skip, orderBy: $orderBy, filter: $filter)
      @connection(key: "SpareDaysPage") {
      id
      from
      label
      country
      team {
        id
        name
        country
      }
    }
    spareDaysCountries: allSpareDaysCountries @connection(key: "SpareDaysCountries")
    spareDaysYears: allSpareDaysYears @connection(key: "SpareDaysYears")
  }
`;

export const useSpareDays = (params, options) => {
  const res = useQuery(GET_SPARE_DAYS, {
    ...options,
    fetchPolicy: 'cache-and-network',
    variables: params,
  });
  return [res.data?.items || [], res.data?.spareDaysCountries, res.data?.spareDaysYears, res.loading, res.error];
};

export const useSpareDay = (params, options) => {
  const res = useQuery(GET_SPARE_DAY, { ...options, variables: params });
  return [
    res.data ? { ...res.data.SpareDay, from: convertDateFromUTC(prop('from', res.data.SpareDay)) } : null,
    res.loading,
    res.error,
  ];
};

export const useCreateSpareDay = options => {
  const gqlRequest = gql`
    mutation CreateSpareDay($from: DateTime!, $to: DateTime, $country: String, $label: String!, $teamId: ObjectID) {
      createSpareDay(from: $from, to: $to, country: $country, label: $label, teamId: $teamId) {
        id
        from
        label
        country
        team {
          id
        }
      }
    }
  `;

  return useMutation(gqlRequest, { ...options, refetchQueries: ['SpareDays'] });
};

export const useUpdateSpareDay = options => {
  const gqlRequest = gql`
    mutation UpdateSpareDay($id: ID!, $from: DateTime, $to: DateTime, $label: String) {
      updateSpareDay(id: $id, from: $from, to: $to, label: $label) {
        id
        from
        label
      }
    }
  `;

  return useMutation(gqlRequest, {
    ...options,
    refetchQueries: ['SpareDays'],
  });
};

export const useDeleteSpareDay = options => {
  const gqlRequest = gql`
    mutation DeleteSpareDay($id: ID!) {
      deleteSpareDay(id: $id) {
        id
      }
    }
  `;

  return useMutation(gqlRequest, {
    ...options,
    refetchQueries: ['SpareDays'],
  });
};
