import React from 'react';
import propTypes from 'prop-types';
import ReactExport from 'react-data-export';
import { buildExcelDataSet } from './utils';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useIntl } from 'react-intl';
import { Typography, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const DownloadFileButton = () => (
  <Grid container spacing={2}>
    <Grid item>
      <CloudDownloadIcon />
    </Grid>
    <Grid item>
      <Typography>
        <FormattedMessage {...messages.timesheet} />
      </Typography>
    </Grid>
  </Grid>
);

const Timesheet = ({ members, date, team, spareDays, events }) => {
  const intl = useIntl();

  const currentYear = intl.formatDate(date, { year: 'numeric' });
  const currentMonth = intl.formatDate(date, { month: 'long' });
  const fileName = `${team.name}_${currentMonth}-${currentYear}`;

  const [dataSet, loading] = buildExcelDataSet(members, currentYear, currentMonth, date, spareDays, events, team);

  if (loading) return <Typography>{<FormattedMessage {...messages.waitLoadingFile} />}</Typography>;

  return (
    <div>
      <ExcelFile filename={fileName} element={<DownloadFileButton />}>
        <ExcelSheet dataSet={dataSet} name="Fiche" />
      </ExcelFile>
    </div>
  );
};

export default Timesheet;

Timesheet.propTypes = {
  members: propTypes.array,
  date: propTypes.number.isRequired,
  spareDays: propTypes.any.isRequired,
  events: propTypes.array.isRequired,
  team: propTypes.object.isRequired,
};
