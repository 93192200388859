import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import propTypes from 'prop-types';

const Tooltip = ({ data }) => {
  return (
    <Card square elevation={2}>
      <CardContent>
        <Typography>{data.label}</Typography>
      </CardContent>
    </Card>
  );
};

Tooltip.propTypes = {
  data: propTypes.object.isRequired,
};

export default Tooltip;
