import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles, FormHelperText } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  },
  helper: { color: 'red' },
}));

const MultiSelectField = ({ input: { value, name, onChange, ...restInput }, required, label, meta, ...rest }) => {
  const classes = useStyles();
  const showError = meta.touched && required ? !meta.valid : meta.error && meta.touched && meta.dirty;
  return (
    <FormControl className={classes.formControl} required={required} fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        {...rest}
        name={name}
        inputProps={restInput}
        onChange={onChange}
        value={[...value]}
        helpertext={showError ? meta.error || meta.submitError : undefined}
        error={showError}
      />
      {showError && <FormHelperText className={classes.helper}>{meta.error || meta.submitError}</FormHelperText>}
    </FormControl>
  );
};

MultiSelectField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.object,
  meta: PropTypes.object,
  required: PropTypes.bool,
};

export default MultiSelectField;
