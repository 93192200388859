import { compose, map, omit, path, find, prop } from 'ramda';
import htmlToDraft from 'html-to-draftjs';
import { ContentState, EditorState } from 'draft-js';
import { convertDateToUTC, sortMembersByFirstnameCaseInsensitive } from '../../utils';
import { convertDateFromUTC, convertDateToUTCEndOfDay } from '../SpareDays/utils';

export const COUNTRIES_CODE = ['FR', 'TN', 'CA', 'BE', 'MA', 'DE', 'CH', 'IT'];

export const FRANCE_CODE = 'FR';

export const EMPTY_ARRAY = [];

export const formatActivityPeriodes = (activityPeriods, activityPeriod) => {
  if (activityPeriod) {
    return {
      ...activityPeriod,
      inceptionDate: activityPeriod.inceptionDate
        ? convertDateToUTC(new Date(activityPeriod.inceptionDate))
        : activityPeriod.inceptionDate,
      expirationDate: activityPeriod.expirationDate
        ? convertDateToUTCEndOfDay(new Date(activityPeriod.expirationDate))
        : activityPeriod.expirationDate,
    };
  }
  if (activityPeriods) {
    const UTCActivityPeriods = map(activityPeriod => ({
      ...activityPeriod,
      inceptionDate: activityPeriod.inceptionDate
        ? convertDateToUTC(new Date(activityPeriod.inceptionDate))
        : activityPeriod.inceptionDate,
      expirationDate: activityPeriod.expirationDate
        ? convertDateToUTCEndOfDay(new Date(activityPeriod.expirationDate))
        : activityPeriod.expirationDate,
    }))(activityPeriods);

    return map(omit(['__typename']), UTCActivityPeriods);
  }
  return activityPeriods;
};
export const formatMembersArray = members => {
  if (members) {
    const newArrayMembers = map(
      compose(omit(['person', '__typename', 'activityPeriod']), item => ({
        ...item,
        personId: path(['person', 'id'], item),
        activityPeriods: formatActivityPeriodes(item.activityPeriods, item.activityPeriod),
      })),
      members,
    );
    return newArrayMembers;
  }

  return null;
};

export const htmlToEditorState = description => {
  if (description) {
    const blocksFromHtml = htmlToDraft(description);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }
  return EditorState.createEmpty();
};

export const checkMember = (memberId, members) => find(member => path(['person', 'id'], member) === memberId, members);

export const convertActivityPeriodsFromUTC = activityPeriods => {
  if (activityPeriods)
    return map(activityPeriod => ({
      ...activityPeriod,
      inceptionDate: activityPeriod.inceptionDate
        ? convertDateFromUTC(activityPeriod.inceptionDate)
        : activityPeriod.inceptionDate,
      expirationDate: activityPeriod.expirationDate
        ? convertDateFromUTC(activityPeriod.expirationDate)
        : activityPeriod.expirationDate,
    }))(activityPeriods);
  return activityPeriods;
};

export const convertTeam = team => {
  const members = prop('members', team);
  if (members) {
    const newMembers = map(member => ({
      ...member,
      activityPeriods: convertActivityPeriodsFromUTC(member.activityPeriods),
    }))(members);
    return { ...team, members: sortMembersByFirstnameCaseInsensitive(newMembers) };
  }
  return team;
};
