import { defineMessages } from 'react-intl';

export default defineMessages({
  profile: {
    id: 'components.userCard.profile',
    defaultMessage: 'Profile',
  },
  disconnect: {
    id: 'components.userCard.disconnect',
    defaultMessage: 'Logout',
  },
});
