import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const useCreateEvent = options => {
  const gqlRequest = gql`
    mutation CreateEvent(
      $from: DateTime!
      $to: DateTime!
      $personId: ObjectID!
      $teamId: ObjectID!
      $description: String
      $type: EventType!
    ) {
      createEvent(from: $from, to: $to, personId: $personId, description: $description, type: $type, teamId: $teamId) {
        id
        from
        to
      }
    }
  `;
  return useMutation(gqlRequest, { ...options });
};

export const useUpdateEvent = options => {
  const gqlRequest = gql`
    mutation UpdateEvent(
      $id: ID!
      $from: DateTime
      $to: DateTime
      $description: String
      $type: EventType
      $teamId: ObjectID
    ) {
      updateEvent(id: $id, from: $from, to: $to, description: $description, type: $type, teamId: $teamId) {
        id
      }
    }
  `;
  return useMutation(gqlRequest, { ...options });
};

export const useDeleteEvent = options => {
  const gqlRequest = gql`
    mutation DeleteEvent($id: ID!) {
      deleteEvent(id: $id) {
        id
      }
    }
  `;
  return useMutation(gqlRequest, { ...options });
};
