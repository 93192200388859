import { defineMessages } from 'react-intl';

export default defineMessages({
  noMembers: {
    id: 'components.Calendar.noMembers',
    defaultMessage: 'No Active workers this month',
  },
  type: {
    vacation: {
      id: 'components.Calendar.vacation',
      defaultMessage: 'Vacation',
    },
    dayOff: {
      id: 'components.Calendar.dayOff',
      defaultMessage: 'DayOff',
    },
    unpaidLeave: {
      id: 'components.Calendar.unpaidLeave',
      defaultMessage: 'Unpaid Leave',
    },
    sickLeave: {
      id: 'components.Calendar.sickLeave',
      defaultMessage: 'Sick Leave',
    },
    workingDay: {
      id: 'components.Calendar.workingDay',
      defaultMessage: 'Working Day',
    },
  },
  amendments: {
    id: 'components.Calendar.amendments',
    defaultMessage: 'Amendments',
  },
  calendar: {
    id: 'components.Calendar.title',
    defaultMessage: 'Calendar',
  },
  addSpareDay: {
    id: 'components.Calendar.addSpareDay',
    defaultMessage: 'Add Spare Day',
  },
  projectAlert: {
    id: 'components.Calendar.projectAlert',
    defaultMessage:
      '"{name}" is a project based team, you can therefore only declare "day off" and "working day". If you are a member of a company based team, all your vacation days will appear here but you can only create/update them in the relative company\'s team. ',
  },
  companyAlert: {
    id: 'components.Calendar.companyAlert',
    defaultMessage:
      '"{name}" is a company based team, you may therefore declare "vacation", "unpaid leave" and "sick leave" days. All those days will appear in your project based teams as vacations. You can only update them in the relative company\'s team.',
  },
});
