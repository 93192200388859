import { defineMessages } from 'react-intl';

export default defineMessages({
  nav: {
    id: 'components.Forbidden.title',
    defaultMessage: 'Forbidden 403',
  },
  title: {
    id: 'components.Forbidden.title',
    defaultMessage: 'Access Denied/Forbidden',
  },
  detail: {
    id: 'components.Forbidden.title',
    defaultMessage: 'The page or resource you were trying to reach is absolutely forbidden for some reason.',
  },
  goBack: {
    id: 'components.Forbidden.title',
    defaultMessage: 'Go back',
  },
});
