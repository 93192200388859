import React, { useEffect, useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { Button, Typography, MenuItem, Chip, CircularProgress, IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useApolloClient } from '@apollo/react-hooks';
import TextField from '../../forms/TextField';
import { useHistory, useParams, Prompt } from 'react-router';
import useRoutes from '../../hooks/routes';
import { useTeamById, useUpdateTeam, searchUsers, useDebounce } from './hooks';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import DeleteIcon from '@material-ui/icons/Delete';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AddIcon from '@material-ui/icons/Add';
import { required, composeValidators, checkTeamNameUniqueness } from '../../forms/utils';
import { formatMembersArray, checkMember, COUNTRIES_CODE } from './utils';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { getName } from 'country-list';
import SelectField from '../../forms/SelectField';
import { map, prop, path, pathOr, values as rvalues, filter, remove } from 'ramda';
import { UserAvatar, XSMALL } from '../Avatar';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DatePickerForm from '../../forms/DateTimePicker';
import ConfirmButton from '../Buttons/confirm';
import useLoading from '../../hooks/loading';
import useNotify from '../../hooks/notification';
import NavTitle from '../NavTitle';
import CancelButton from '../Buttons/cancel';
import { ROLE, isManager, isObserver, rolesForTeamsCreateRight } from '../../../lib/models/members';
import { TYPE } from '../../../lib/models/teams';
import AutocompleteField from '../../forms/AutoCompleteField';
import ReactCountryFlag from 'react-country-flag';
import { isAdmin } from '../../roles';
import { useLoggedUser } from '../../hooks/user';
import { isManagerOrheadWorker } from '../../utils';

const DEBOUNCE_DELAY = 500;

const useStyles = makeStyles(theme => ({
  screen: {
    height: '80vh',
  },
  deleteIcon: {
    marginTop: theme.spacing(1.5),
  },
  activityButton: { marginTop: theme.spacing(2.5) },

  datePicker: {
    marginTop: theme.spacing(2),
  },
  text: {
    backgroundColor: '#FFF',
    width: '100%',
    border: '1px solid #F1F1F1',
    borderTop: '0px',
    padding: theme.spacing(1),
  },
  itemMember: { height: theme.spacing(3) },

  addMembersForm: { marginBottom: theme.spacing(2.5), width: '100%', justifyContent: 'flex-end' },
  selectCountry: { marginTop: theme.spacing(0.3) },
}));

const TeamEdit = () => {
  const classes = useStyles();
  const intl = useIntl();
  const routes = useRoutes();
  const history = useHistory();
  const notify = useNotify();
  const { id } = useParams();
  const [team, teamLoading] = useTeamById({ variables: { id } });
  const setLoading = useLoading();
  const [options, setOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState();
  const [index, setIndex] = useState();
  const debouncedSearchTerm = useDebounce(searchTerm, DEBOUNCE_DELAY);
  const [loggedUser, loadingLoggedUser] = useLoggedUser();

  useEffect(() => {
    if (debouncedSearchTerm) {
      searchUsers(apolloClient, debouncedSearchTerm).then(results => {
        const updatedOptions = [...options];
        updatedOptions[index] = results;
        setOptions([...updatedOptions]);
      });
    }
  }, [debouncedSearchTerm]); // eslint-disable-line

  const [updateTeam, { loading: editLoading }] = useUpdateTeam({
    onCompleted: ({ updateTeam }) =>
      notify.success({ title: intl.formatMessage(messages.teamEdited, { name: updateTeam.name }) }),
    onError: error => notify.error({ title: `${intl.formatMessage(messages.cannotEditTeam)} : ${error}` }),
  });

  const apolloClient = useApolloClient();

  const onSubmit = async values => {
    await updateTeam({
      variables: {
        id: id,
        name: values.name,
        description: values.description,
        members: formatMembersArray(values.members),
        country: values.country,
        type: values.type,
      },
    });
    history.push(routes.teamList.path);
  };

  useEffect(() => {
    setLoading(teamLoading || editLoading || loadingLoggedUser);
  }, [teamLoading, editLoading, setLoading, loadingLoggedUser]);

  useEffect(() => {
    if (!teamLoading && !(isAdmin(loggedUser) || isManagerOrheadWorker(loggedUser, team)))
      history.replace(routes.getDefaultPath());
  }, [loadingLoggedUser || teamLoading]); // eslint-disable-line

  if (teamLoading)
    return (
      <>
        <NavTitle message={intl.formatMessage(messages.editTeam)} />;
        <Grid className={classes.screen} container justify="center" alignItems="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      </>
    );

  return (
    <Grid container justify="flex-end">
      <NavTitle message={intl.formatMessage(messages.editTeam)} />
      <Grid item>
        <Button
          color="primary"
          startIcon={<CalendarTodayIcon />}
          onClick={() => history.push(routes.getPathByName('calendar', prop('id', team)))}
        >
          <FormattedMessage {...messages.calendar}></FormattedMessage>
        </Button>
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            name: prop('name', team),
            description: prop('description', team),
            members: prop('members', team),
            country: prop('country', team),
            type: prop('type', team),
          }}
          keepDirtyOnReinitialize
          mutators={{
            ...arrayMutators,
          }}
          render={({
            handleSubmit,
            pristine,
            invalid,
            values,
            errors,
            submitting,
            form,
            form: {
              mutators: { push },
            },
          }) => (
            <form onSubmit={handleSubmit}>
              <Prompt when={!pristine && !submitting} message={intl.formatMessage(messages.prompt)} />
              <Grid container alignItems="flex-start" spacing={3} className={classes.root}>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    required
                    validate={value =>
                      prop('name', team) === value
                        ? undefined
                        : composeValidators(
                            required(<FormattedMessage {...messages.requiredName} />),
                            checkTeamNameUniqueness(apolloClient)(<FormattedMessage {...messages.nameTaken} />),
                          )(value)
                    }
                    component={TextField}
                    label={<FormattedMessage {...messages.name} />}
                    name="name"
                    margin="normal"
                    data-testid="team.edit.input.name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    component={TextField}
                    label={<FormattedMessage {...messages.description} />}
                    name="description"
                    multiline
                    rows={3}
                    data-testid="team.edit.input.description"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    disabled
                    fullWidth
                    required
                    component={SelectField}
                    label={<FormattedMessage {...messages.typeLabel} />}
                    name="type"
                    validate={required(<FormattedMessage {...messages.requiredType} />)}
                  >
                    {map(type => (
                      <MenuItem key={type} value={type}>
                        <Typography>
                          <FormattedMessage {...messages.type[type]}></FormattedMessage>
                        </Typography>
                      </MenuItem>
                    ))(rvalues(TYPE))}
                  </Field>
                </Grid>
                <Grid item xs={12} className={classes.selectCountry}>
                  <Field
                    fullWidth
                    required
                    component={SelectField}
                    label={<FormattedMessage {...messages.country} />}
                    placeholder={`${intl.formatMessage(messages.country)}`}
                    name="country"
                    validate={required(<FormattedMessage {...messages.requiredCountry} />)}
                  >
                    {map(code => (
                      <MenuItem key={code} value={code}>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item>
                            <ReactCountryFlag
                              style={{
                                width: '1.3em',
                                height: '1.3em',
                              }}
                              countryCode={code}
                              svg
                            />
                          </Grid>
                          <Grid item>
                            <Typography>{getName(code)}</Typography>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    ))(COUNTRIES_CODE)}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    disabled={!!errors.members}
                    size="small"
                    color="primary"
                    onClick={() => push('members', {})}
                    data-testid="team.edit.button.addMembers"
                  >
                    <AddIcon />
                    <Typography color="inherit" noWrap>
                      <FormattedMessage {...messages.addMembers} />
                    </Typography>
                  </Button>
                </Grid>

                <Grid item xs={12} container direction="column-reverse">
                  <FieldArray name="members">
                    {({ fields }) =>
                      fields.map((member, index) => (
                        <Grid key={member} item container spacing={3} className={classes.addMembersForm}>
                          <Grid item xs={3} className={classes.itemMember}>
                            <Field
                              fullWidth
                              requiredField
                              disabled={
                                !(isAdmin(loggedUser) || !isManagerOrheadWorker(values.members[index]?.person, team))
                              }
                              component={AutocompleteField}
                              size="small"
                              name={`${member}.person`}
                              label={<FormattedMessage {...messages.user} />}
                              validate={required(<FormattedMessage {...messages.requiredSelectUser} />)}
                              data-testid="team.edit.input.person.id"
                              options={options[index] ? options[index] : []}
                              filterOptions={options =>
                                filter(
                                  user =>
                                    !checkMember(user.id, fields.value) ||
                                    user.id === path(['members', index, 'person', 'id'], values),
                                )(options)
                              }
                              getOptionSelected={(option, value) => option.fullname === value.fullname}
                              noOptionsText={<FormattedMessage {...messages.noOptions}></FormattedMessage>}
                              getOptionLabel={option => (option ? option.fullname : option)}
                              renderOption={user => (
                                <Chip
                                  key={prop('id', user)}
                                  color="primary"
                                  avatar={<UserAvatar user={user} size={XSMALL} />}
                                  label={user.fullname}
                                  size="small"
                                />
                              )}
                              onInputChange={async (event, searchTerm) => {
                                if (!searchTerm.length) {
                                  const updatedOptions = [...options];
                                  updatedOptions[index] = [];
                                  setOptions([...updatedOptions]);
                                }
                                if (searchTerm.length >= 4 && !pristine) {
                                  setSearchTerm(searchTerm);
                                  setIndex(index);
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={3} className={classes.itemMember}>
                            <Field
                              fullWidth
                              required
                              component={SelectField}
                              disabled={
                                !(isAdmin(loggedUser) || !isManagerOrheadWorker(values.members[index]?.person, team))
                              }
                              label={<FormattedMessage {...messages.role} />}
                              name={`${member}.role`}
                              validate={required(<FormattedMessage {...messages.requiredRole} />)}
                              data-testid="team.edit.input.person.role"
                            >
                              {map(role => (
                                <MenuItem key={role} value={role}>
                                  <Typography>
                                    <FormattedMessage {...messages[role]} />
                                  </Typography>
                                </MenuItem>
                              ))(
                                !isAdmin(loggedUser) &&
                                  values.members[index]?.person?.id !== loggedUser?.id &&
                                  !isManagerOrheadWorker(values.members[index]?.person, team)
                                  ? rolesForTeamsCreateRight
                                  : rvalues(ROLE),
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={6} container spacing={2}>
                            <FormSpy
                              subscription={{
                                values: true,
                              }}
                              onChange={async ({ values }) => {
                                if (isObserver(values?.members[index]) || isManager(values?.members[index])) {
                                  values.members[index].activityPeriods = null;
                                }
                              }}
                            />
                            <Grid item xs={5}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Field
                                  disabled={
                                    isObserver(values.members[index]) ||
                                    isManager(values.members[index]) ||
                                    !(
                                      isAdmin(loggedUser) || !isManagerOrheadWorker(values.members[index]?.person, team)
                                    )
                                  }
                                  className={classes.datePicker}
                                  name={`${member}.activityPeriods[0].inceptionDate`}
                                  component={DatePickerForm}
                                  maxDate={pathOr(
                                    new Date(2099, 1, 1),
                                    ['members', index, 'activityPeriods', 0, 'expirationDate'],
                                    values,
                                  )}
                                  placeholder={`${intl.formatMessage(messages.inceptionDate)}`}
                                  data-testid="team.edit.input.person.inceptionDate"
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={5}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Field
                                  disabled={
                                    isObserver(values.members[index]) ||
                                    isManager(values.members[index]) ||
                                    !(
                                      isAdmin(loggedUser) || !isManagerOrheadWorker(values.members[index]?.person, team)
                                    )
                                  }
                                  className={classes.datePicker}
                                  name={`${member}.activityPeriods[0].expirationDate`}
                                  component={DatePickerForm}
                                  minDate={pathOr(
                                    new Date(1980, 1, 1),
                                    ['members', index, 'activityPeriods', 0, 'inceptionDate'],
                                    values,
                                  )}
                                  placeholder={`${intl.formatMessage(messages.expirationDate)}`}
                                  data-testid="team.edit.input.person.expirationDate"
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={2}>
                              <IconButton
                                disabled={
                                  !(isAdmin(loggedUser) || !isManagerOrheadWorker(values.members[index]?.person, team))
                                }
                                onClick={() => {
                                  fields.remove(index);
                                  setOptions(remove(index, 1, options));
                                }}
                                className={classes.deleteIcon}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))
                    }
                  </FieldArray>
                </Grid>

                <Grid item xs={12} sm={6} className={classes.button}>
                  <CancelButton />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.button}>
                  <ConfirmButton data-testid="team.edit.submit" disabled={pristine || invalid} />
                </Grid>
              </Grid>
            </form>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default TeamEdit;
