import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

const CalendarActionMenu = ({ children }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton aria-label="Calendar-Action-Menu" onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {children}
      </Menu>
    </div>
  );
};

CalendarActionMenu.propTypes = {
  children: PropTypes.node,
};

export default CalendarActionMenu;
