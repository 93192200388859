import { compose, filter, propOr, propEq, isNil } from 'ramda';
import { isHeadWorker, isWorker, isActiveMember } from './members';

export const FRANCE_CODE = 'FR';
export const TUNISIA_CODE = 'TN';
export const CANADA_CODE = 'CA';
export const BELGIUM_CODE = 'BE';
export const MOROCCO_CODE = 'MA';
export const GERMANY_CODE = 'DE';
export const SWITZERLAND_CODE = 'CH';
export const ITALY_CODE = 'IT';

export const COUNTRIES_CODE = [
  FRANCE_CODE,
  TUNISIA_CODE,
  CANADA_CODE,
  BELGIUM_CODE,
  MOROCCO_CODE,
  GERMANY_CODE,
  SWITZERLAND_CODE,
  ITALY_CODE,
];

export const NO_TEAM = 'noTeam';

export const TYPE = {
  company: 'company',
  project: 'project',
};

const { company, project } = TYPE;

export const isCompany = team => !isNil(team) && propEq('type', company)(team);
export const isProject = team => !isNil(team) && propEq('type', project)(team);
export const hasMembers = team => team.members?.length;
export const getActiveMembers = (team, from, to) =>
  compose(
    filter(member => isActiveMember(member, from, to)),
    propOr([], 'members'),
  )(team);
export const getActiveWorkers = (team, from, to) =>
  filter(member => isHeadWorker(member) || isWorker(member), getActiveMembers(team, from, to));
