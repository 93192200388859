import {
  eachDayOfInterval,
  startOfMonth,
  endOfMonth,
  isSameDay,
  startOfDay,
  isWeekend,
  eachHourOfInterval,
  areIntervalsOverlapping,
  isWithinInterval,
  isAfter,
  isBefore,
  differenceInHours,
  subHours,
  addHours,
  setHours,
  getHours,
} from 'date-fns';
import {
  find,
  map,
  any,
  filter,
  chain,
  addIndex,
  modulo,
  __,
  compose,
  prop,
  head,
  sort,
  ascend,
  descend,
  flatten,
  tail,
  init,
} from 'ramda';
import { utcToZonedTime } from 'date-fns-tz';
import { timezone, isWorkingDay, isVacation, isUnpaidLeave, isSickLeave } from '../../../lib/models/events';
import { isProject, isCompany } from '../../../lib/models/teams';

export const AM = 'AM';
export const PM = 'PM';

export const getAllDaysOfMonth = month =>
  eachDayOfInterval({ start: startOfMonth(new Date(month)), end: endOfMonth(new Date(month)) });

export const getAllHalfDaysOfMonth = month => {
  const allDays = getAllDaysOfMonth(month);
  const duplicate = n => [n, n];
  return chain(duplicate, allDays);
};

export const intervalToHalfDays = (from, to) => {
  const allDays = eachDayOfInterval({ start: new Date(from), end: new Date(to) });
  let halfDays = compose(
    flatten,
    map(day => [setHours(day, 0), setHours(day, 12)]),
  )(allDays);
  if (getHours(from) === 12) halfDays = tail(halfDays);
  if (getHours(to) === 11) halfDays = init(halfDays);
  return halfDays;
};

export const isSpareDay = (day, spareDays) =>
  find(spareDay => isSameDay(startOfDay(new Date(spareDay.from)), startOfDay(day)))(spareDays);

export const isDayOff = (day, spareDays) => isSpareDay(day, spareDays) || isWeekend(startOfDay(day));

export const getMonthWorkingDaysNumber = (month, spareDays) => {
  let workingDays = 0;
  let selectedDays = eachDayOfInterval({ start: startOfMonth(month), end: endOfMonth(month) });
  map(selectedDay => {
    if (!isDayOff(selectedDay, spareDays)) workingDays += 1;
  })(selectedDays);
  return workingDays;
};

export const getEventWorkingDaysNumber = (event, spareDays, personId, members) => {
  let workingDays = 0;
  let selectedDays = eachHourOfInterval(
    {
      start: new Date(event.from),
      end: new Date(event.to),
    },
    { step: 12 },
  );
  members &&
    map(selectedDay => {
      if (!isDayOff(selectedDay, spareDays) && isActiveDay(personId, members, selectedDay)) workingDays += 0.5;
    })(selectedDays);
  return workingDays;
};

export const getPersonWorkingDaysNumber = (personId, events, month, spareDays, members, team) => {
  if (isProject(team)) {
    let workingDays = 0;
    const personEvents = filter(event => event.person.id === personId && isWorkingDay(event))(events);
    map(event => {
      workingDays = workingDays + getEventWorkingDaysNumber(event, spareDays, personId, members);
    })(personEvents);
    return workingDays;
  }
  if (isCompany(team)) {
    let workingDays = getMonthWorkingDaysNumber(month, spareDays);
    const allDays = getAllDaysOfMonth(month);
    members &&
      map(day => {
        if (!isActiveDay(personId, members, day) && !isDayOff(day, spareDays)) workingDays -= 1;
      })(allDays);
    const personEvents = filter(
      event => event.person.id === personId && (isVacation(event) || isUnpaidLeave(event) || isSickLeave(event)),
    )(events);
    map(event => {
      workingDays = workingDays - getEventWorkingDaysNumber(event, spareDays, personId, members);
    })(personEvents);
    return workingDays;
  }
};

export const isActiveDay = (personId, members, day) => {
  const member = find(member => member.person.id === personId)(members);
  const activityPeriods = prop('activityPeriods', member);
  if (!activityPeriods) return true;

  return any(({ inceptionDate, expirationDate }) => {
    return (
      (inceptionDate &&
        expirationDate &&
        isWithinInterval(day, { start: new Date(inceptionDate), end: new Date(expirationDate) })) ||
      (!inceptionDate && !expirationDate) ||
      (!inceptionDate && expirationDate && day <= new Date(expirationDate)) ||
      (!expirationDate && inceptionDate && day >= new Date(inceptionDate))
    );
  })(activityPeriods);
};

export const mapIndexed = addIndex(map);

export const isOdd = modulo(__, 2);

export const isAM = day => day.period === AM;

export const isPM = day => !isAM(day);

export const convertEventstoUTC = (events, timezone) =>
  map(event => ({
    ...event,
    from: utcToZonedTime(event.from, timezone),
    to: utcToZonedTime(event.to, timezone),
  }))(events);

export const isActiveMember = (member, month) => {
  const activityPeriods = prop('activityPeriods', member);
  let result = false;
  if (!activityPeriods) return true;
  map(activityPeriod => {
    if (
      (activityPeriod.inceptionDate &&
        activityPeriod.expirationDate &&
        areIntervalsOverlapping(
          { start: startOfMonth(month), end: endOfMonth(month) },
          {
            start: utcToZonedTime(new Date(activityPeriod.inceptionDate), timezone),
            end: utcToZonedTime(new Date(activityPeriod.expirationDate), timezone),
          },
        )) ||
      (!activityPeriod.inceptionDate && !activityPeriod.expirationDate) ||
      (!activityPeriod.inceptionDate &&
        activityPeriod.expirationDate &&
        (isWithinInterval(utcToZonedTime(new Date(activityPeriod.expirationDate), timezone), {
          start: startOfMonth(month),
          end: endOfMonth(month),
        }) ||
          isAfter(utcToZonedTime(new Date(activityPeriod.expirationDate), timezone), endOfMonth(month)))) ||
      (!activityPeriod.expirationDate &&
        activityPeriod.inceptionDate &&
        (isWithinInterval(utcToZonedTime(new Date(activityPeriod.inceptionDate), timezone), {
          start: startOfMonth(month),
          end: endOfMonth(month),
        }) ||
          isBefore(utcToZonedTime(new Date(activityPeriod.inceptionDate), timezone), startOfMonth(month))))
    )
      result = true;
  })(activityPeriods);
  return result;
};

export const getMaxDate = (events, date) => {
  const rightEvents = filter(element => element.from > date)(events);
  return compose(prop('from'), head, sort(ascend(prop('from'))))(rightEvents);
};

export const getMinDate = (events, date) => {
  const leftEvents = filter(element => element.to < date)(events);
  return compose(prop('to'), head, sort(descend(prop('to'))))(leftEvents);
};

export const convertEventToUTC = (date, timezone) => {
  const ZonedDate = utcToZonedTime(date, timezone);
  const timezoneDifference = differenceInHours(date, ZonedDate);
  if (timezoneDifference < 0) {
    return subHours(date, Math.abs(timezoneDifference));
  }
  if (timezoneDifference > 0) {
    return addHours(date, timezoneDifference);
  }
  return date;
};
