import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import PermIdentity from '@material-ui/icons/PermIdentity';
import MuiAvatar from '@material-ui/core/Avatar';
import WarningIcon from '@material-ui/icons/Warning';
import { compose, join, map, take, split, toUpper, values, pick } from 'ramda';
import { useHistory } from 'react-router-dom';

const size2Dim = size => ({ LARGE: '60px', MEDIUM: '36px', SMALL: '28px', XSMALL: '21px' }[size] || '36px');
const fontSize2Dim = size => ({ LARGE: '28px', MEDIUM: '20px', SMALL: '12px', XSMALL: '10' }[size] || '20px');
export const LARGE = 'LARGE';
export const MEDIUM = 'MEDIUM';
export const SMALL = 'SMALL';
export const XSMALL = 'XSMALL';

const stringToHslColor = str => {
  let hash = 0;
  for (let i = 0; i < str?.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let h = hash % 360;
  return 'hsl(' + h + ',50%,50%)';
};

const fullname = compose(join(' '), values, pick(['firstname', 'lastname']), x => x || {});

export const UserAvatar = ({ user, disabled, ...props }) => {
  return (
    <Avatar
      user={user}
      color={disabled ? '#A6A795' : stringToHslColor(user?.email)}
      name={fullname(user)}
      disabled={disabled}
      {...props}
    />
  );
};
UserAvatar.propTypes = {
  user: PropTypes.object,
  disabled: PropTypes.bool,
};

const Avatar = ({ color, user, name, size = SMALL, showTooltip, to, className, picture, warning, disabled }) => {
  const history = useHistory();
  const style = {
    height: size2Dim(size),
    width: size2Dim(size),
    backgroundColor: warning ? 'transparent' : user && color,
    color: warning && 'orange',
    fontSize: fontSize2Dim(size),
    margin: 2,
    zIndex: 3,
  };
  const props = { style, className };
  const initials = compose(join(''), map(take(1)), take(1), split(' '), toUpper);

  const widget = warning ? (
    <Tooltip>
      <MuiAvatar {...props}>
        <WarningIcon style={{ height: 48, width: 48 }} />
      </MuiAvatar>
    </Tooltip>
  ) : !user ? (
    <MuiAvatar {...props}>
      <PermIdentity />
    </MuiAvatar>
  ) : picture ? (
    <Tooltip title={showTooltip ? name : ''}>
      <MuiAvatar src={picture} {...props} />
    </Tooltip>
  ) : disabled ? (
    <Tooltip title={showTooltip ? name : ''}>
      <MuiAvatar style={{ color: 'green' }} {...props}>
        {initials(name)}
      </MuiAvatar>
    </Tooltip>
  ) : (
    <Tooltip title={showTooltip ? name : ''}>
      <MuiAvatar {...props}>{initials(name)}</MuiAvatar>
    </Tooltip>
  );

  if (!to) return widget;
  return (
    <IconButton onClick={() => history.push(to)} className={className}>
      {widget}
    </IconButton>
  );
};

Avatar.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  to: PropTypes.string,
  showTooltip: PropTypes.bool,
  picture: PropTypes.string,
  user: PropTypes.object,
  warning: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Avatar;
