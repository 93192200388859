import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { convertDateFromUTC } from '../SpareDays/utils';
import { prop } from 'ramda';

export const GET_AMENDMENTS = gql`
  query Amendments($first: Int, $skip: Int, $orderBy: String, $filter: AmendmentFilter) {
    items: allAmendments(first: $first, skip: $skip, orderBy: $orderBy, filter: $filter)
      @connection(key: "Amendments") {
      id
      from
      to
      days
      description
      person {
        id
        lastname
        firstname
        email
        fullname
      }
      team {
        id
        name
        country
      }
    }
    amendmentsUsers: allAmendmentsUsers @connection(key: "AmendmentsUsers") {
      id
      fullname
    }
    amendmentsTeams: allAmendmentsTeams @connection(key: "AmendmentsTeams") {
      id
      name
    }
  }
`;

export const useAmendments = (params, options) => {
  const res = useQuery(GET_AMENDMENTS, {
    ...options,
    fetchPolicy: 'cache-and-network',
    variables: params,
  });
  return [res.data?.items || [], res.data?.amendmentsUsers, res.data?.amendmentsTeams, res.loading, res.error];
};

export const useCreateAmendment = options => {
  const gqlRequest = gql`
    mutation CreateAmendment(
      $from: DateTime!
      $to: DateTime!
      $description: String
      $days: Int!
      $teamId: ObjectID!
      $personId: ObjectID!
    ) {
      createAmendment(
        from: $from
        to: $to
        description: $description
        days: $days
        teamId: $teamId
        personId: $personId
      ) {
        id
        days
        person {
          id
          fullname
        }
        team {
          id
          name
        }
      }
    }
  `;

  return useMutation(gqlRequest, { ...options, refetchQueries: ['Amendments'] });
};

export const WORKING_DAYS_OF_PERIOD = gql`
  query workingDaysOfPeriod($teamId: ObjectID!, $from: DateTime!, $to: DateTime!) {
    workingDaysOfPeriod(teamId: $teamId, from: $from, to: $to) {
      workingDays
    }
  }
`;

export const GET_AMENDMENT = gql`
  query Amendment($id: ID!) {
    Amendment(id: $id) {
      id
      from
      to
      days
      description
      person {
        id
        fullname
      }
      team {
        id
        name
      }
    }
  }
`;
export const useAmendment = (params, options) => {
  const res = useQuery(GET_AMENDMENT, { ...options, variables: params });
  return [
    res.data
      ? {
          ...res.data.Amendment,
          from: convertDateFromUTC(prop('from', res.data.Amendment)),
          to: convertDateFromUTC(prop('to', res.data.Amendment)),
        }
      : null,
    res.loading,
    res.error,
  ];
};

export const useUpdateAmendment = options => {
  const gqlRequest = gql`
    mutation UpdateAmendment(
      $id: ID!
      $from: DateTime
      $to: DateTime
      $description: String
      $days: Int
      $teamId: ObjectID
      $personId: ObjectID
    ) {
      updateAmendment(
        id: $id
        from: $from
        to: $to
        description: $description
        days: $days
        teamId: $teamId
        personId: $personId
      ) {
        id
        days
        person {
          id
          fullname
        }
        team {
          id
          name
        }
      }
    }
  `;

  return useMutation(gqlRequest, {
    ...options,
    refetchQueries: ['Amendments'],
  });
};

export const useDeleteAmendment = options => {
  const DELETE_AMENDMENT = gql`
    mutation DeleteAmendment($id: ID!) {
      deleteAmendment(id: $id) {
        id
      }
    }
  `;
  return useMutation(DELETE_AMENDMENT, {
    ...options,
    refetchQueries: ['Amendments'],
  });
};
