import { find } from 'ramda';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import messages from './messages';

const sponsors = [
  { name: 'redpelicans', logo: BeachAccessIcon, regexp: /redpelicans/ },
  { name: 'DareJS', logo: BeachAccessIcon, regexp: /darejs/ },
];
const defaultSponsor = sponsors[0];

export const getTitle = intl => {
  const localSponsor = find(({ regexp }) => regexp.test(window.location.hostname), sponsors) || defaultSponsor;
  const title = intl.formatMessage(messages.title, { name: localSponsor.name });

  return { title, logo: localSponsor.logo };
};
