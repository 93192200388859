import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import CategoryIcon from '@material-ui/icons/Category';

export const getDrawerLinksList = routes => [
  { title: 'Calendars', route: routes.calendars, icon: CalendarTodayIcon },
  { title: 'Users', route: routes.userList, icon: PersonIcon },
  { title: 'Spare Days', route: routes.spareDays, icon: EventBusyIcon },
  { title: 'Teams', route: routes.teamList, icon: GroupIcon },
  { title: 'Amendments', route: routes.amendments, icon: CategoryIcon },
];
