import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useState, useEffect } from 'react';
import { convertTeam } from './utils';

export const GET_TEAMS = gql`
  query TeamsPage($first: Int, $skip: Int, $orderBy: String, $filter: TeamFilter) {
    items: allTeams(first: $first, skip: $skip, orderBy: $orderBy, filter: $filter) @connection(key: "TeamsPage") {
      id
      name
      description
      country
      type
      createdBy
      members {
        person {
          id
          firstname
          lastname
          fullname
          email
        }
        role
        activityPeriods {
          inceptionDate
          expirationDate
        }
      }
    }
    total: _allTeamsMeta(first: $first, skip: $skip, orderBy: $orderBy, filter: $filter)
      @connection(key: "TeamsPageInfo") {
      count
    }
  }
`;

export const useTeams = (params, options) => {
  const res = useQuery(GET_TEAMS, {
    ...options,
    fetchPolicy: 'cache-and-network',
    variables: params,
  });
  return [res.data?.items || [], res.data?.total.count, res.loading, res.error];
};

export const GET_USER_TEAMS = gql`
  query UserTeams($filter: TeamFilter) {
    items: allTeams(filter: $filter) @connection(key: "UserTeams") {
      id
      name
      description
      country
      type
      members {
        person {
          id
          firstname
          lastname
          fullname
          email
        }
        role
        activityPeriods {
          inceptionDate
          expirationDate
        }
      }
    }
  }
`;

export const useUserTeams = (user, options) => {
  const res = useQuery(GET_USER_TEAMS, {
    ...options,
    fetchPolicy: 'cache-and-network',
    variables: { filter: { userId: user?.id } },
  });
  return [res.data?.items, res.loading, res.error];
};

export const useDeleteTeam = options => {
  const DELETE_TEAM = gql`
    mutation DeleteTeam($id: ID!) {
      deleteTeam(id: $id) {
        id
      }
    }
  `;
  return useMutation(DELETE_TEAM, {
    ...options,
    refetchQueries: ['TeamsPage'],
    // update(cache, { data: { deleteTeam } }) {
    //   const { items } = cache.readQuery({ query: GET_TEAMS });
    //   cache.writeQuery({
    //     query: GET_TEAMS,
    //     data: { Teams: reject(team => team.id === deleteTeam.id, items) },
    //   });
    // },
  });
};

export const useCreateTeam = options => {
  const CREATE_TEAM = gql`
    mutation CreateTeam(
      $name: String!
      $description: String
      $country: String!
      $members: [TeamMemberInput]
      $type: TeamType!
    ) {
      createTeam(name: $name, description: $description, country: $country, members: $members, type: $type) {
        id
        name
        description
        country
        members {
          person {
            id
            lastname
            firstname
            fullname
          }
          role
          activityPeriods {
            inceptionDate
            expirationDate
          }
        }
      }
    }
  `;
  return useMutation(CREATE_TEAM, { ...options, refetchQueries: ['TeamsPage'] });
};

export const GET_TEAM_BY_ID = gql`
  query Team($id: ID!) {
    Team(id: $id) {
      id
      name
      description
      country
      type
      members {
        person {
          id
          lastname
          firstname
          fullname
          email
        }
        role
        activityPeriods {
          inceptionDate
          expirationDate
        }
      }
    }
  }
`;
export const useTeamById = params => {
  const res = useQuery(GET_TEAM_BY_ID, params);
  return [res.data ? convertTeam(res.data.Team) : null, res.loading, res.error];
};

export const useUpdateTeam = options => {
  const UPDATE_TEAM = gql`
    mutation UpdateTeam(
      $id: ID!
      $name: String!
      $description: String
      $country: String!
      $members: [TeamMemberInput]
      $type: TeamType!
    ) {
      updateTeam(id: $id, name: $name, description: $description, country: $country, members: $members, type: $type) {
        id
        name
        description
        country
        type
        members {
          person {
            id
            lastname
            firstname
            fullname
            email
          }
          role
          activityPeriods {
            inceptionDate
            expirationDate
          }
        }
      }
    }
  `;
  return useMutation(UPDATE_TEAM, {
    ...options,
    refetchQueries: ['TeamsPage'],
  });
};

export const CHECK_TEAM_UNIQUENESS = gql`
  query checkTeamUniqueness($name: String!) {
    checkTeamUniqueness(name: $name)
  }
`;

export const SEARCH_USERS = gql`
  query searchUsers($match: String) {
    searchUsers(match: $match) {
      id
      firstname
      lastname
      fullname
      email
    }
  }
`;

export const searchUsers = async (client, value) => {
  const variables = { match: value };
  const res = await client.query({ query: SEARCH_USERS, variables });
  return res.data?.searchUsers;
};

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value]); //eslint-disable-line
  return debouncedValue;
};
