import React, { useRef, useEffect, useState } from 'react';
import { select, scaleLinear, scaleBand, range, mouse, selectAll, easeLinear } from 'd3';
import {
  assoc,
  map,
  path,
  prop,
  findIndex,
  pathEq,
  find,
  filter,
  indexOf,
  compose,
  take,
  drop,
  includes,
  pluck,
  propEq,
  values,
} from 'ramda';
import propTypes from 'prop-types';
import { Box, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {
  format,
  getDate,
  getHours,
  setHours,
  endOfHour,
  endOfDay,
  startOfMonth,
  subMilliseconds,
  endOfMonth,
  isWithinInterval,
} from 'date-fns';
import {
  getAllDaysOfMonth,
  isDayOff,
  isSpareDay,
  getAllHalfDaysOfMonth,
  getPersonWorkingDaysNumber,
  getMonthWorkingDaysNumber,
  mapIndexed,
  isOdd,
  isAM,
  PM,
  AM,
  isActiveDay,
  getMinDate,
  getMaxDate,
  intervalToHalfDays,
} from './utils';
import Tooltip from '../Tooltip';
import useRoutes from '../../hooks/routes';
import messages from './messages';
import { FormattedMessage } from 'react-intl';
import { isAdmin } from '../../roles';
import { isWorker, isManager, isHeadWorker } from '../../../lib/models/members';
import { isCompany, isProject } from '../../../lib/models/teams';
import { employeeEventsTypes, nonEmployeeEventsTypes, TYPE } from '../../../lib/models/events';

const HCOLOR = '#ffb74d';
const Timeline = ({ month, spareDays, resourceAvatar, events, disabled, team, updateEventId, user }) => {
  const history = useHistory();
  const theme = useTheme();
  const routes = useRoutes();
  const svgRef = useRef();
  const tooltipRef = useRef();
  const square = 50;
  const allDays = getAllDaysOfMonth(month);
  const allHalfDays = getAllHalfDaysOfMonth(month);
  const members = compose(
    filter(member => isHeadWorker(member) || isWorker(member)),
    prop('members'),
  )(team);

  const allMembers = prop('members', team);

  const getMember = id => find(pathEq(['person', 'id'], id))(allMembers);

  const w = allDays.length * square + 100;
  const h = square * (members.length + 2);
  const daysCreator = id =>
    map(day => (isDayOff(day, spareDays) || !isActiveDay(id, members, day) ? { day, dayOff: true, id } : { day, id }))(
      allDays,
    );

  const halfDaysCreator = id =>
    mapIndexed((day, index) => ({
      day,
      period: isOdd(index) ? PM : AM,
      dayOff: isDayOff(day, spareDays) || !isActiveDay(id, members, day) ? true : false,
      id,
    }))(allHalfDays);

  const classnameCreator = d =>
    `.halfSquare-date-${getDate(d.day)}-period-${d.period}-y-${d.id}-team-${prop('id', team)}`;

  const gridData = map(member => assoc('days', daysCreator(path(['person', 'id'], member)))(member))(members);

  const halfDayGridData = map(member => assoc('days', halfDaysCreator(path(['person', 'id'], member)))(member))(
    members,
  );

  const getPersonIndex = person => findIndex(pathEq(['person', 'id'], prop('id', person)))(members);

  const getPerson = id => find(pathEq(['person', 'id'], id))(members);

  const eventToHalfDays = event => {
    const halfDays = intervalToHalfDays(event.from, event.to);
    return map(halfDay => ({
      halfDay,
      period: getHours(halfDay) === 0 ? AM : PM,
      color: theme.palette[event.type],
      ...event,
    }))(halfDays);
  };

  const eventsData = events =>
    compose(
      map(event => ({
        ...event,
        events: eventToHalfDays(event),
        lineId: getPersonIndex(prop('person', event)) + 1,
      })),
      filter(event => includes(path(['person', 'id'], event), pluck('id', pluck('person', members)))),
    )(events);

  const x = scaleLinear()
    .domain([0, allDays.length])
    .range([0, w - 100]);

  const y = scaleBand()
    .domain(range(gridData.length + 2))
    .range([0, square * (gridData.length + 2)]);

  const [tooltipDatum, setTooltipDatum] = useState();
  const [tooltipPosition, setTooltipPosition] = useState();

  useEffect(() => {
    const svg = select(svgRef.current);
    svg
      .on('mousemove', function () {
        const [x, y] = mouse(this);
        const spacing = 16;
        setTooltipPosition({
          left: x + spacing,
          top: y + spacing,
        });
      })
      .on('mouseup', () => {
        if (!disabled) {
          if (firstDate) {
            clicked = false;
            const newEvents = filter(event => event.person.id === getPerson(selectedLine).person.id)(events);
            let from = firstDate.day > lastDate.day ? lastDate : firstDate;
            let to = firstDate.day > lastDate.day ? firstDate : lastDate;
            if (firstDate.day === lastDate.day) {
              from = firstDate.period === 'AM' ? firstDate : lastDate;
              to = firstDate.period === 'AM' ? lastDate : firstDate;
            }
            from = isAM(from) ? setHours(from.day, 0) : setHours(from.day, 12);
            to = isAM(to) ? endOfHour(setHours(to.day, 11)) : endOfDay(to.day);
            const event = { from, to, id: 'CreateId', person: getPerson(selectedLine).person };
            const state = {
              person: getPerson(selectedLine).person,
              events: newEvents,
              spareDays,
              month,
              team,
              event,
              user,
            };
            history.push({
              pathname: routes.createEvent.path,
              state,
            });
          }
        }
      });
    const svgGrid = svg.select('.grid').attr('transform', `translate(50,${y(1) + y(1) / 2})`);

    const svgHalfDayGrid = svg.select('.halfDayGrid').attr('transform', `translate(50,${y(1) + y(1) / 2})`);

    let clicked = false;
    let selectedLine;
    let firstDate;
    let lastDate;
    let maxDate;
    let minDate;

    svg.attr('width', w).attr('height', h).attr('text-anchor', 'middle').style('color', 'white');

    const halfDayrow = svgHalfDayGrid
      .selectAll('g')
      .data(halfDayGridData)
      .join('g')
      .attr('transform', (d, i) => `translate(0,${y(i) + 0.5})`);

    halfDayrow
      .selectAll('rect')
      .data(d => d.days)
      .join('rect')
      .attr(
        'class',
        d => `halfSquare halfSquare-date-${getDate(d.day)}-period-${d.period}-y-${d.id}-team-${prop('id', team)}`,
      )
      .attr('width', d => (isAM(d) ? square / 2 : square / 2 - 0.5))
      .attr('height', square - 1)
      .attr('x', d => (isAM(d) ? x(getDate(d.day) - 1) + 0.5 : x(getDate(d.day) - 0.5)))
      .attr('fill', 'transparent')
      .on('mousedown', function (d) {
        if (!disabled) {
          if (!d.dayOff) {
            if (
              isAdmin(user) ||
              (isWorker(getMember(user.id)) && d.id === user.id) ||
              isManager(getMember(user.id)) ||
              isHeadWorker(getMember(user.id))
            ) {
              clicked = true;
              selectedLine = d.id;
              firstDate = d;
              lastDate = d;
              minDate = getMinDate(
                filter(pathEq(['person', 'id'], d.id))(events),
                isAM(d) ? d.day : setHours(d.day, 12),
              )
                ? getMinDate(filter(pathEq(['person', 'id'], d.id))(events), isAM(d) ? d.day : setHours(d.day, 12))
                : startOfMonth(month);
              maxDate = getMaxDate(
                filter(pathEq(['person', 'id'], d.id))(events),
                isAM(d) ? d.day : setHours(d.day, 12),
              )
                ? subMilliseconds(
                    getMaxDate(filter(pathEq(['person', 'id'], d.id))(events), isAM(d) ? d.day : setHours(d.day, 12)),
                    1,
                  )
                : endOfMonth(month);
              select(this).attr('fill', HCOLOR);
            }
          }
        }
      })
      .on('mouseover', function (d) {
        if (!disabled) {
          if (clicked && d.id === selectedLine) {
            lastDate =
              isDayOff(d.day, spareDays) ||
              !isActiveDay(d.id, members, d.day) ||
              !isWithinInterval(isAM(d) ? d.day : setHours(d.day, 12), { start: minDate, end: maxDate })
                ? lastDate
                : d;
            selectAll('.halfSquare').attr('fill', 'transparent');
            const days = halfDaysCreator(selectedLine);
            let firstDateIndex = firstDate.day > lastDate.day ? indexOf(lastDate, days) : indexOf(firstDate, days);
            let lastDateIndex = firstDate.day > lastDate.day ? indexOf(firstDate, days) : indexOf(lastDate, days);
            if (firstDate.day === lastDate.day) {
              firstDateIndex = firstDate.period === 'AM' ? indexOf(firstDate, days) : indexOf(lastDate, days);
              lastDateIndex = firstDate.period === 'AM' ? indexOf(lastDate, days) : indexOf(firstDate, days);
            }
            const period = compose(take(lastDateIndex - firstDateIndex + 1), drop(firstDateIndex))(days);
            map(halfDay => {
              select(classnameCreator(halfDay)).attr('fill', d => (d.dayOff ? 'transparent' : HCOLOR));
            })(period);
          }
        }
      });

    const row = svgGrid
      .selectAll('g')
      .data(gridData)
      .join('g')
      .attr('transform', (d, i) => `translate(0,${y(i)})`);

    row
      .selectAll('rect')
      .data(d => d.days)
      .join('rect')
      .attr('class', d => `square square-x-${getDate(d.day)}-y-${d.id}`)
      .attr('width', square)
      .attr('height', square)
      .attr('x', d => x(getDate(d.day) - 1))
      .attr('fill', d => (d.dayOff ? '#C8C8C8' : '#fff'))
      .attr('stroke', '#9eada2');

    const svgEvents = svg.select('.events').attr('transform', `translate(50,${y(1) + y(1) / 2 + 0.5})`);

    const event = svgEvents
      .selectAll('g')
      .data(eventsData(events))
      .join('g')
      .attr('transform', d => `translate(0,${d.lineId ? y(d.lineId - 1) : 0})`);
    event
      .selectAll('rect')
      .data(d => d.events)
      .join('rect')
      .attr('class', 'event')
      .attr('fill', d =>
        isDayOff(d.halfDay, spareDays) || !isActiveDay(d.person.id, members, d.halfDay)
          ? 'transparent'
          : updateEventId === d.id
          ? HCOLOR
          : isProject(team) && includes(d.type, values(employeeEventsTypes))
          ? theme.palette[TYPE.vacation]
          : d.color,
      )
      .on('mouseover', function (d) {
        if (
          !disabled &&
          !isDayOff(d.halfDay, spareDays) &&
          isActiveDay(d.person.id, members, d.halfDay) &&
          !clicked &&
          ((isCompany(team) && includes(d.type, values(employeeEventsTypes))) ||
            (!isCompany(team) && includes(d.type, values(nonEmployeeEventsTypes)))) &&
          (isAdmin(user) ||
            (isWorker(getMember(user.id)) && d.person.id === user.id) ||
            isManager(getMember(user.id)) ||
            isHeadWorker(getMember(user.id)))
        )
          select(this).style('cursor', 'pointer');
      })
      .on('click', function (d) {
        if (
          !disabled &&
          isActiveDay(d.person.id, members, d.halfDay) &&
          ((isCompany(team) && includes(d.type, values(employeeEventsTypes))) ||
            (!isCompany(team) && includes(d.type, values(nonEmployeeEventsTypes)))) &&
          (isAdmin(user) ||
            (isWorker(getMember(user.id)) && d.person.id === user.id) ||
            isManager(getMember(user.id)) ||
            isHeadWorker(getMember(user.id)))
        ) {
          const newEvents = filter(event => event.person.id === d.person.id)(events);
          const event = find(propEq('id', d.id))(events);
          const state = {
            person: d.person,
            events: newEvents,
            spareDays,
            month,
            team,
            update: true,
            event,
            user,
          };
          history.push({
            pathname: routes.updateEvent.path,
            state,
          });
        }
      })
      .attr('width', d => (isAM(d) ? square / 2 : square / 2 - 0.5))
      .attr('height', square - 1);
    if (updateEventId)
      selectAll('.event').attr('x', d => (isAM(d) ? x(getDate(d.halfDay) - 1) + 0.5 : x(getDate(d.halfDay) - 0.5)));
    else
      selectAll('.event')
        .attr('x', d => (isAM(d) ? x(getDate(d.halfDay) - 1) + 0.5 : x(getDate(d.halfDay) - 0.5)))
        .transition()
        .duration(500)
        .ease(easeLinear)
        .attr('opacity', 1);

    svg
      .select('.dayNumbers')
      .attr('transform', `translate(50,0)`)
      .selectAll('text')
      .data(gridData.length ? gridData[0].days : [])
      .join('text')
      .text((d, i) => i + 1)
      .attr('fill', 'black')
      .attr('x', (d, i) => x(i) + square / 2)
      .attr('y', y(1) / 2)
      .style('user-select', 'none');

    svg
      .select('.dayNames')
      .attr('transform', `translate(50,0)`)
      .selectAll('text')
      .data(gridData.length ? gridData[0].days : [])
      .join('text')
      .text(d => format(d.day, 'eee'))
      .attr('fill', d => (isSpareDay(d.day, spareDays) ? '#0088d1' : 'black'))
      .attr('x', (d, i) => x(i) + square / 2)
      .attr('y', y(2) / 2)
      .style('user-select', 'none')
      .on('mouseover', function (d) {
        if (isSpareDay(d.day, spareDays)) setTooltipDatum({ label: isSpareDay(d.day, spareDays).label });
      })
      .on('mouseleave', function () {
        setTooltipDatum(null);
      });
    svg
      .select('.workingDaysNumber')
      .attr('transform', `translate(${x(allDays.length + 1) + x(1) / 2},${y(1) + y(1) / 2 + 3})`)
      .selectAll('text')
      .data(members)
      .join('text')
      .text(d => getPersonWorkingDaysNumber(d.person.id, events, month, spareDays, members, team))
      .attr('fill', 'black')
      .attr('y', (d, i) => y(i) + y(1) / 2)
      .style('user-select', 'none');

    svg
      .select('.monthWorkingDaysNumber')
      .attr('transform', `translate(${x(1) - x(1) / 2 - 5},${y(1)})`)
      .selectAll('text')
      .data([0])
      .join('text')
      .text(`(${getMonthWorkingDaysNumber(month, spareDays)})`)
      .style('user-select', 'none')
      .on('mouseover', () => {
        setTooltipDatum({ label: <FormattedMessage {...messages.workingDaysTooltipLabel} /> });
      })
      .on('mouseleave', function () {
        setTooltipDatum(null);
      });
  }, [month, events]); // eslint-disable-line

  return (
    <>
      <Box style={{ position: 'relative', overflowX: 'auto' }}>
        {members.length ? (
          <svg ref={svgRef}>
            <g className="grid" />
            <g className="halfDayGrid" />
            <g className="events" />
            <g className="dayNumbers" />
            <g className="dayNames" />
            <g className="workingDaysNumber" />
            <g className="monthWorkingDaysNumber" />
          </svg>
        ) : null}
        <Box style={{ position: 'absolute', left: 0, top: 78 }}>
          {gridData.map(resource => (
            <Box style={{ height: square, width: square }} key={resource.person.id}>
              {resourceAvatar(resource.person)}
            </Box>
          ))}
        </Box>
        <div ref={tooltipRef} style={{ position: 'absolute', ...tooltipPosition }}>
          {tooltipDatum && <Tooltip data={tooltipDatum} />}
        </div>
      </Box>
    </>
  );
};

Timeline.propTypes = {
  month: propTypes.number.isRequired,
  spareDays: propTypes.any.isRequired,
  events: propTypes.array.isRequired,
  resourceAvatar: propTypes.func.isRequired,
  disabled: propTypes.bool,
  team: propTypes.object.isRequired,
  updateEventId: propTypes.string,
  user: propTypes.object,
};

export default Timeline;
