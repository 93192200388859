import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'components.SpareDay.title',
    defaultMessage: 'Spare days',
  },
  confirm: {
    id: 'components.SpareDays.confirm',
    defaultMessage: 'Save',
  },
  createSpareDay: {
    id: 'components.SpareDays.createSpareDay',
    defaultMessage: 'Create Spare Day',
  },
  cancel: {
    id: 'components.SpareDays.cancel',
    defaultMessage: 'Cancel',
  },
  updateSpareDay: {
    id: 'components.SpareDays.updateSpareDay',
    defaultMessage: 'Update SpareDay',
  },
  day: {
    id: 'components.SpareDays.day',
    defaultMessage: 'Day',
  },
  label: {
    id: 'components.SpareDays.label',
    defaultMessage: 'Label',
  },
  action: {
    id: 'components.SpareDays.action',
    defaultMessage: 'Action',
  },
  edit: {
    id: 'components.SpareDays.edit',
    defaultMessage: 'Edit',
  },
  delete: {
    id: 'components.SpareDays.delete',
    defaultMessage: 'Delete',
  },
  spareDayCreated: {
    id: 'components.SpareDays.createNewSpareDay',
    defaultMessage: 'Spare day {label} created',
  },
  cannotCreateNewSpareDay: {
    id: 'components.SpareDays.cannotCreateNewSpareDay',
    defaultMessage: 'Cannot create the spare day',
  },
  editSpareDay: {
    id: 'components.SpareDays.editSpareDay',
    defaultMessage: 'Edit Spare Day',
  },
  spareDayEdited: {
    id: 'components.SpareDays.spareDayEdited',
    defaultMessage: 'Spare day {label} updated',
  },
  spareDayDeleted: {
    id: 'components.SpareDays.spareDayDeleted',
    defaultMessage: 'Spare day deleted',
  },
  cannotDeleteSpareDay: {
    id: 'components.SpareDays.cannotDeleteSpareDay',
    defaultMessage: 'Failed to delete spare day!',
  },
  cannotEditSpareDay: {
    id: 'components.SpareDays.cannotEditSpareDay',
    defaultMessage: 'Cannot edit the spare day',
  },
  country: {
    id: 'components.SpareDays.country',
    defaultMessage: 'Country',
  },
  team: {
    id: 'components.SpareDays.team',
    defaultMessage: 'Team',
  },
  noTeam: {
    id: 'components.SpareDays.noTeam',
    defaultMessage: 'No team',
  },
  prompt: {
    id: 'components.SpareDays.prompt',
    defaultMessage: 'Are you sure you want to leave?',
  },
  spareDayDeleteMessage: {
    id: 'components.SpareDays.spareDayDeleteMessage',
    defaultMessage: 'Are you sure you want to delete this spareday "{label}" ?',
  },
  noSpareDays: {
    id: 'components.SpareDays.noSpareDays',
    defaultMessage: 'No Sparedays available',
  },
  yearLabel: {
    id: 'components.SpareDays.yearLabel',
    defaultMessage: 'Year',
  },
  countryLabel: {
    id: 'components.SpareDays.countryLabel',
    defaultMessage: 'Country',
  },
  allCountries: {
    id: 'components.SpareDays.allCountries',
    defaultMessage: 'All Countries',
  },
});
