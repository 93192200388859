import api from '../lib/api';
import { TOKEN } from './utils';
import { loggedUserIdVar } from './store';

export default client => ({
  login: ({ email, password }) =>
    api.auth.signIn(email, password).then(({ token, user }) => {
      localStorage.setItem(TOKEN, token);
      loggedUserIdVar(user._id);
    }),
  register: ({ firstname, lastname, email, password }) => api.auth.signUp(firstname, lastname, email, password),
  registerWithGoogle: ({ token }) => api.auth.signUpWithGoogle(token),
  registerWithFacebook: response => api.auth.signUpWithFacebook(response),
  logout: async () => {
    await client.resetStore();
    loggedUserIdVar(null);
    localStorage.removeItem(TOKEN);
  },
  loginWithGoogle: ({ token }) =>
    api.auth.signInWithGoogle(token).then(({ token, user }) => {
      localStorage.setItem(TOKEN, token);
      loggedUserIdVar(user._id);
    }),
  loginWithFacebook: response =>
    api.auth.signInWithFacebook(response).then(({ token, user }) => {
      localStorage.setItem(TOKEN, token);
      loggedUserIdVar(user._id);
    }),
  checkError: () => Promise.resolve(),
  checkAuth: () => Promise.resolve(),
});
