import React from 'react';
import { filter, map } from 'ramda';
import propTypes from 'prop-types';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import { ListItemText, Divider, Grid, List, ListItem, ListItemIcon, Hidden, Tooltip } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  link: { textDecoration: 'none', color: 'inherit' },
});

const getAuthorizedItems = (items, user) =>
  filter(el => {
    return el.route.isAuthorized(user);
  }, items);

const DrawerMenu = ({ drawerLinksList, user, miniDrawerOpen }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.drawerContainer}>
      <Divider />
      <List>
        {map(
          el => (
            <Link to={el.route.path} key={el.route.path} className={classes.link}>
              <Route path={el.route.path}>
                {({ match }) => (
                  <ListItem button selected={!!match}>
                    <Hidden smDown implementation="css">
                      {miniDrawerOpen ? (
                        <ListItemIcon>
                          <el.icon />
                        </ListItemIcon>
                      ) : (
                        <ListItemIcon>
                          <Tooltip title={el.title}>
                            <el.icon />
                          </Tooltip>
                        </ListItemIcon>
                      )}
                    </Hidden>
                    <ListItemText primary={el.title} />
                  </ListItem>
                )}
              </Route>
            </Link>
          ),
          getAuthorizedItems(drawerLinksList, user),
        )}
      </List>
      <Divider />
    </Grid>
  );
};

DrawerMenu.propTypes = {
  drawerLinksList: propTypes.array,
  user: propTypes.object,
  miniDrawerOpen: propTypes.bool,
};

export default DrawerMenu;
