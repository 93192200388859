import { useEffect } from 'react';
import PropTypes from 'prop-types';
import useNav from '../../hooks/nav';

export const NavImpl = () => {
  const nav = useNav();
  if (!nav()) return null;
  return nav();
};

const Nav = ({ children }) => {
  const nav = useNav();

  useEffect(() => {
    nav(children);
  }, [nav, children]);

  return null;
};

Nav.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Nav;
