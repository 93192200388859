import { map, is, compose, prop, sortBy, pluck } from 'ramda';
import { addMinutes, subMinutes, endOfDay } from 'date-fns';
import { getName } from 'country-list';

export const convertSpareDays = map(spareDay => ({ ...spareDay, from: convertDateFromUTC(spareDay.from) }));

export const convertDateFromUTC = date => {
  const newDate = is(String, date) ? new Date(date) : date;
  const offset = newDate.getTimezoneOffset();
  return addMinutes(newDate, offset);
};

export const convertDateToUTCEndOfDay = date => {
  const newDate = is(String, date) ? new Date(date) : date;
  const offset = newDate.getTimezoneOffset();
  return subMinutes(endOfDay(newDate), offset);
};

export const convertDateTimeToUTC = date => {
  const newDate = is(String, date) ? new Date(date) : date;
  const offset = newDate.getTimezoneOffset();
  return subMinutes(newDate, offset);
};

export const codeToCodeAndName = code => ({ code, name: getName(code) });

export const sortCountriesByName = codes => compose(pluck('code'), sortBy(prop('name')), map(codeToCodeAndName))(codes);
