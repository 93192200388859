import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory } from 'react-router-dom';
import useRoutes from '../../hooks/routes';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { Grid, TablePagination, TableSortLabel, IconButton, Typography, Fab } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useTeams, useDeleteTeam } from './hooks';
import useLoading from '../../hooks/loading';
import { useIntl, FormattedMessage } from 'react-intl';
import messages from './messages';
import SearchBar from '../SearchBar';
import { map, prop, isEmpty, path, equals } from 'ramda';
import AddIcon from '@material-ui/icons/Add';
import useNotify from '../../hooks/notification';
import NavTitle from '../NavTitle';
import { UserAvatar, SMALL } from '../Avatar';
import { getName } from 'country-list';
import ReactCountryFlag from 'react-country-flag';
import { useLoggedUser } from '../../hooks/user';
import { isAdmin, hasRight, RIGHT, hasTeamsCreateRightOrManager } from '../../roles';
import AlertDialog, { AlertDialogContent, AlertDialogTitle } from '../AlertDialog';
import { isActiveDay, mapIndexed } from '../Timeline/utils';
import { isManagerOrheadWorker } from '../../utils';

const useStyles = makeStyles(theme => ({
  userAvatar: {
    padding: '2px',
  },
  title: { color: theme.palette.white },
  root: {
    height: '100%',
    width: '100%',
    overflowX: 'auto',
  },
  row: {
    cursor: 'pointer',
    zIndex: 1,
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },

  columnSort: {
    fontWeight: 'bold',
    paddingLeft: theme.spacing(2.5),
  },
  absolute: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
    zIndex: 5,
  },
  noDataMessage: { textAlign: 'center' },
  descriptionStyle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 750,
  },
}));

const TeamList = () => {
  const [loggedUser, loadingLoggedUser] = useLoggedUser();
  const classes = useStyles();
  const routes = useRoutes();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const rowsPerPageOptions = [20, 50, 100];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const intl = useIntl();
  const notify = useNotify();
  const setLoading = useLoading();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [searchValue, setSearchValue] = useState('');
  const [indexOfRowTodDelete, setIndexOfRowTodDelete] = useState(-1);

  const handleEditRow = id => {
    history.push(routes.getPathByName('teamEdit', id));
  };

  const handleDelete = id => {
    deleteTeam({ variables: { id } });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // eslint-disable-next-line no-unused-vars
  const createSortHandler = property => event => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const requestParams = {
    first: rowsPerPage,
    skip: page * rowsPerPage,
    orderBy: orderBy + '_' + order.toUpperCase(),
  };
  if (searchValue) requestParams.filter = { q: searchValue };

  const [teams, count, loading] = useTeams(requestParams, {
    onError: error => notify.error({ title: error.message }),
  });

  const [deleteTeam, { loading: deleteLoading }] = useDeleteTeam({
    onCompleted: () => notify.success({ title: intl.formatMessage(messages.teamDeleted) }),
    onError: () => notify.error({ title: intl.formatMessage(messages.cannotDeleteTeam) }),
  });

  useEffect(() => {
    if (!loadingLoggedUser && !hasTeamsCreateRightOrManager(loggedUser)) history.replace(routes.getDefaultPath());
  }, []); // eslint-disable-line

  useEffect(() => {
    setLoading(loading || deleteLoading || loadingLoggedUser);
  }, [loading, deleteLoading, loadingLoggedUser, setLoading]);

  return (
    <>
      <NavTitle message={intl.formatMessage(messages.title)} />

      <Grid container spacing={2} direction="column" data-testid="team.list">
        <Grid item container justify="space-between">
          <Grid item>
            <SearchBar value={searchValue} onChange={setSearchValue} />
          </Grid>
        </Grid>
        <Grid item className={classes.root}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'name'}
                      direction={orderBy === 'name' ? order : 'asc'}
                      onClick={createSortHandler('name')}
                      className={classes.columnSort}
                    >
                      <FormattedMessage {...messages.name} />
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'description'}
                      direction={orderBy === 'description' ? order : 'asc'}
                      onClick={createSortHandler('description')}
                      className={classes.columnSort}
                    >
                      <FormattedMessage {...messages.description} />
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'type'}
                      direction={orderBy === 'type' ? order : 'asc'}
                      onClick={createSortHandler('type')}
                      className={classes.columnSort}
                    >
                      <FormattedMessage {...messages.typeLabel} />
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'country'}
                      direction={orderBy === 'country' ? order : 'asc'}
                      onClick={createSortHandler('country')}
                      className={classes.columnSort}
                    >
                      <FormattedMessage {...messages.country} />
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" className={classes.bold}>
                    <FormattedMessage {...messages.members} />
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mapIndexed(
                  (team, index) => (
                    <TableRow
                      hover
                      onClick={() =>
                        (isAdmin(loggedUser) || isManagerOrheadWorker(loggedUser, team)) && handleEditRow(team.id)
                      }
                      className={classes.row}
                      key={prop('id', team)}
                      data-testid={`team#${team.id}`}
                    >
                      <TableCell component="th" scope="row" align="center">
                        {prop('name', team)}
                      </TableCell>
                      <TableCell align="left">
                        <Typography className={classes.descriptionStyle}>{prop('description', team)}</Typography>
                      </TableCell>
                      <TableCell align="center">{prop('type', team)}</TableCell>
                      <TableCell align="center">
                        {prop('country', team) && (
                          <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                              <ReactCountryFlag
                                style={{
                                  width: '1.5em',
                                  height: '1.5em',
                                }}
                                countryCode={prop('country', team)}
                                svg
                              />
                            </Grid>
                            <Grid item>
                              <Typography>{getName(prop('country', team))}</Typography>
                            </Grid>
                          </Grid>
                        )}
                      </TableCell>
                      <TableCell align="center" onClick={event => event.stopPropagation()}>
                        <AvatarGroup max={7}>
                          {map(
                            member => (
                              <UserAvatar
                                className={classes.userAvatar}
                                disabled={
                                  !isActiveDay(path(['person', 'id'], member), prop('members', team), new Date())
                                }
                                key={member.person.id}
                                user={member.person}
                                size={SMALL}
                                to={routes.getPathByName('userEdit', member.person.id)}
                                showTooltip
                              />
                            ),
                            team.members || [],
                          )}
                        </AvatarGroup>
                      </TableCell>
                      <TableCell align="center">
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          wrap="nowrap"
                          onClick={event => event.stopPropagation()}
                        >
                          <Grid item>
                            {isAdmin(loggedUser) || isManagerOrheadWorker(loggedUser, team) ? (
                              <IconButton
                                onClick={() =>
                                  (isAdmin(loggedUser) || isManagerOrheadWorker(loggedUser, team)) &&
                                  handleEditRow(team.id)
                                }
                              >
                                <EditIcon style={{ color: 'blue' }} />
                              </IconButton>
                            ) : (
                              <IconButton disabled>
                                <EditIcon />
                              </IconButton>
                            )}
                          </Grid>
                          <Grid item>
                            {isAdmin(loggedUser) ||
                            (team.createdBy === loggedUser?.id && isManagerOrheadWorker(loggedUser, team)) ? (
                              <IconButton
                                onClick={() => {
                                  setIndexOfRowTodDelete(index);
                                }}
                                style={{ zIndex: 5 }}
                              >
                                <DeleteIcon style={{ color: 'red' }} />
                              </IconButton>
                            ) : (
                              <IconButton disabled>
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </Grid>
                          <Grid item>
                            <AlertDialog
                              open={equals(indexOfRowTodDelete, index)}
                              onClose={() => setIndexOfRowTodDelete(-1)}
                              onConfirm={() => {
                                setIndexOfRowTodDelete(-1);
                                handleDelete(team.id);
                              }}
                            >
                              <AlertDialogTitle>
                                <FormattedMessage {...messages.delete} />
                              </AlertDialogTitle>
                              <AlertDialogContent>
                                <FormattedMessage {...messages.teamDeleteMessage} values={{ name: team.name }} />
                              </AlertDialogContent>
                            </AlertDialog>
                          </Grid>
                          <Grid item>
                            <IconButton
                              onClick={e => {
                                e.stopPropagation();
                                history.push(routes.getPathByName('calendar', prop('id', team)));
                              }}
                            >
                              <CalendarTodayIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ),
                  teams || [],
                )}
              </TableBody>
            </Table>
            {isEmpty(teams) ? (
              <Typography className={classes.noDataMessage}>
                <FormattedMessage {...messages.noData} />
              </Typography>
            ) : null}
            <TablePagination
              className={classes.tablePagination}
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={count || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>

        {(isAdmin(loggedUser) || hasRight(loggedUser, [RIGHT.teamsCreate])) && (
          <Grid item>
            <Link to={routes.teamCreate.path} className={classes.absolute}>
              <Fab color="secondary">
                <AddIcon />
              </Fab>
            </Link>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default TeamList;
