import { format, addDays, getYear, getDate, getMonth } from 'date-fns';
import { curry, find, is, when, propEq, always, map, assoc, sortBy, compose, toLower, any, propOr, path } from 'ramda';
import { isHeadWorker, isManager } from '../../lib/models/members';
import { isAdmin } from '../roles';

const DATE_FORMAT = 'MMddyy';
export const TOKEN = 'peep:site:token';
export const mdy = date => format(is(String, date) ? new Date(date) : date, DATE_FORMAT);
export const getSpareDay = curry((spareDays, date) => find(({ from }) => mdy(from) === mdy(date), spareDays));
export const getNextWorkingDay = curry((spareDays, date) => {
  if (!getSpareDay(spareDays, date)) return date;
  let next = addDays(date, 1);
  while (getSpareDay(spareDays, next)) {
    next = addDays(next, 1);
  }
  return next;
});

export const getLocale = () => (navigator.language === 'fr' ? 'fr' : 'en');
export const updateItemOfArray = curry((newValue, objectType, key, items) => {
  newValue = assoc('__typename', objectType, newValue);
  return map(when(propEq('id', key), always(newValue)), items);
});

export const sortMembersByFirstnameCaseInsensitive = sortBy(compose(toLower, path(['person', 'firstname'])));

export const convertDateToUTC = date => {
  const year = getYear(date);
  const month = getMonth(date);
  const day = getDate(date);
  return new Date(Date.UTC(year, month, day));
};

export const isManagerOrheadWorker = (user, team) =>
  user &&
  team?.members &&
  find(member => member.person?.id === user.id && (isHeadWorker(member) || isManager(member)), team.members);

export const atLeastManager = user => {
  return user && (isAdmin(user) || any(team => isManagerOrheadWorker(user, team), propOr([], 'teams', user)));
};
