import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { useParams, Prompt, useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { CircularProgress, Grid, Typography, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../../forms/TextField';
import SelectField from '../../forms/Select';
import { useSpareDay, useUpdateSpareDay } from './hooks';
import useRoutes from '../../hooks/routes';
import useNotify from '../../hooks/notification';
import useLoading from '../../hooks/loading';
import SpareDayDatePicker from '../SpareDayDatePicker';
import NavTitle from '../NavTitle';
import messages from './messages';
import { NO_TEAM } from '../../../lib/models/teams';
import CancelButton from '../Buttons/cancel';
import ConfirmButton from '../Buttons/confirm';
import { convertDateToUTC } from '../../utils';
import { startOfDay } from 'date-fns';
import { convertDateToUTCEndOfDay } from './utils';
import ReactCountryFlag from 'react-country-flag';
import { getName } from 'country-list';
import { hasSpareDaysRight } from '../../roles';
import { useLoggedUser } from '../../hooks/user';

const getCountry = spareDay => {
  return spareDay.country || spareDay.team?.country;
};
const getTeam = spareDay => {
  return spareDay?.team?.id || NO_TEAM;
};

const useSyles = makeStyles(theme => ({
  root: { padding: 10 },
  screen: {
    height: '80vh',
  },
  container: {
    padding: theme.spacing(1),
  },
  field: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
}));

const SpareDayEdit = ({ onClose }) => {
  const classes = useSyles();
  const { id } = useParams();
  const notify = useNotify();
  const setLoading = useLoading();
  const intl = useIntl();
  const [loggedUser, loadingLoggedUser] = useLoggedUser();
  const routes = useRoutes();
  const history = useHistory();

  const [spareDay, loading] = useSpareDay(
    { id },
    {
      onError: error => notify.error({ title: error.toString() }),
    },
  );

  const [updateSpareDay, { loading: editLoading }] = useUpdateSpareDay({
    onCompleted: ({ updateSpareDay }) =>
      notify.success({
        title: intl.formatMessage(messages.spareDayEdited, { label: updateSpareDay.label }),
      }),
    onError: error => notify.error({ title: `${intl.formatMessage(messages.cannotEditSpareDay)} : ${error}` }),
  });

  const onSubmit = async values => {
    await updateSpareDay({
      variables: {
        id: id,
        from: convertDateToUTC(startOfDay(values.from)),
        to: convertDateToUTCEndOfDay(values.from),
        label: values.label,
      },
    });
    onClose();
  };

  useEffect(() => {
    setLoading(loading || editLoading || loadingLoggedUser);
  }, [loading, editLoading, setLoading, loadingLoggedUser]);

  useEffect(() => {
    if (!hasSpareDaysRight(loggedUser)) history.replace(routes.getDefaultPath());
  }, []); // eslint-disable-line

  if (loading || !spareDay)
    return (
      <>
        <NavTitle message={intl.formatMessage(messages.editSpareDay)} />;
        <Grid className={classes.screen} container justify="center" alignItems="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      </>
    );

  return (
    <div className={classes.root}>
      <NavTitle message={intl.formatMessage(messages.editSpareDay)} />
      <Form
        onSubmit={onSubmit}
        initialValues={{
          country: getCountry(spareDay),
          team: getTeam(spareDay),
          label: spareDay.label,
          from: spareDay.from,
        }}
        keepDirtyOnReinitialize
        render={({ handleSubmit, pristine, invalid, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Prompt when={!pristine && !submitting} message={intl.formatMessage(messages.prompt)} />

            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
              spacing={1}
              className={classes.container}
              data-testid="spareday.edit.form"
            >
              <Grid item xs={6}>
                <Field
                  className={classes.field}
                  fullWidth
                  component={SelectField}
                  disabled
                  label={<FormattedMessage {...messages.country} />}
                  placeholder={`${intl.formatMessage(messages.country)}`}
                  name="country"
                  defaultValue={getCountry(spareDay)}
                >
                  <MenuItem key={getCountry(spareDay)} value={getCountry(spareDay)}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <ReactCountryFlag
                          style={{
                            width: '1.3em',
                            height: '1.3em',
                          }}
                          countryCode={spareDay?.country ? spareDay.country : spareDay.team.country}
                          svg
                        />
                      </Grid>
                      <Grid item>
                        <Typography>{getName(spareDay?.country ? spareDay.country : spareDay.team.country)}</Typography>
                      </Grid>
                    </Grid>
                  </MenuItem>
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  className={classes.field}
                  fullWidth
                  disabled
                  component={SelectField}
                  label={<FormattedMessage {...messages.team} />}
                  placeholder={`${intl.formatMessage(messages.team)}`}
                  name="team"
                  defaultValue={getTeam(spareDay)}
                >
                  <MenuItem key={getTeam(spareDay)} value={getTeam(spareDay)}>
                    <Typography>
                      {spareDay?.team ? (
                        spareDay.team.name
                      ) : (
                        <em>
                          <FormattedMessage {...messages.noTeam} />{' '}
                        </em>
                      )}
                    </Typography>
                  </MenuItem>
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field
                  className={classes.field}
                  component={TextField}
                  label={<FormattedMessage {...messages.label} />}
                  name="label"
                  defaultValue={spareDay.label}
                  data-testid="spareday.edit.input.label"
                />
              </Grid>
              <Grid item xs={12}>
                <SpareDayDatePicker
                  name="from"
                  defaultValue={spareDay.from}
                  currentCountry={getCountry(spareDay)}
                  currentTeam={getTeam(spareDay)}
                  loading={loading}
                />
              </Grid>

              <Grid container item xs={6}>
                <CancelButton className={classes.field} />
              </Grid>
              <Grid container item xs={6}>
                <ConfirmButton
                  className={classes.field}
                  data-testid="spareday.edit.submit"
                  disabled={pristine || invalid}
                />
              </Grid>
            </Grid>
          </form>
        )}
      />
    </div>
  );
};

SpareDayEdit.propTypes = {
  onClose: PropTypes.func,
};

export default SpareDayEdit;
