import React from 'react';
import { Button } from '@material-ui/core';
import messages from './messages';
import { FormattedMessage } from 'react-intl';
import useRoutes from '../../hooks/routes';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { useHistory } from 'react-router-dom';

const GoBackButton = ({ ...rest }) => {
  const history = useHistory();
  const routes = useRoutes();

  return (
    <Button
      {...rest}
      fullWidth
      color="secondary"
      variant="contained"
      onClick={() => history.push(routes.calendars.path)}
      startIcon={<KeyboardReturnIcon />}
    >
      <FormattedMessage {...messages.goBack} />
    </Button>
  );
};

export default GoBackButton;
