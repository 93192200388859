import { reduce, map, filter, flatten, groupWith, pathEq, equals } from 'ramda';
import {
  getPersonWorkingDaysNumber,
  getMonthWorkingDaysNumber,
  isActiveDay,
  isDayOff as isSpareDayOrWeekend,
} from '../Timeline/utils';
import { eachHourOfInterval, format } from 'date-fns';
import { isVacation, isDayOff, TYPE, isUnpaidLeave, isSickLeave, isWorkingDay } from '../../../lib/models/events';
import { isProject } from '../../../lib/models/teams';

const EMPTY_CELL_FOR_TITLE = {
  title: '',
  style: {
    border: {
      bottom: { style: 'thick', color: { rgb: '83C6A4' } },
    },
    fill: { fgColor: { rgb: 'FFCCEEFF' } },
  },
};
const WHITE_EMPTY_CELL_FOR_TITLE = {
  title: '',
  style: {
    fill: {
      fgColor: { rgb: 'FFFFFFFF' },
    },
  },
};

const WHITE_EMPTY_CELL = { value: '', style: { fill: { fgColor: { rgb: 'FFFFFFFF' } } } };
const DOCUMENT_TITLE = {
  columns: [
    {
      title: " Rapport d'activité mensuelle",
      style: {
        border: {
          bottom: { style: 'thick', color: { rgb: '83C6A4' } },
        },
        font: { sz: '24', bold: true },
        fill: { fgColor: { rgb: 'FFCCEEFF' } },
      },
    },
    EMPTY_CELL_FOR_TITLE,
    EMPTY_CELL_FOR_TITLE,
    EMPTY_CELL_FOR_TITLE,
    EMPTY_CELL_FOR_TITLE,
    EMPTY_CELL_FOR_TITLE,
    EMPTY_CELL_FOR_TITLE,
    EMPTY_CELL_FOR_TITLE,
  ],
  data: [[]],
};
const getTeam = teamName => ({
  ySteps: -1,
  columns: [
    { title: ' ' + teamName, style: { font: { sz: '24', bold: true }, fill: { fgColor: { rgb: 'FFFFFFFF' } } } },
    WHITE_EMPTY_CELL_FOR_TITLE,
    WHITE_EMPTY_CELL_FOR_TITLE,
    WHITE_EMPTY_CELL_FOR_TITLE,
    WHITE_EMPTY_CELL_FOR_TITLE,
    WHITE_EMPTY_CELL_FOR_TITLE,
    WHITE_EMPTY_CELL_FOR_TITLE,
    WHITE_EMPTY_CELL_FOR_TITLE,
  ],
  data: [[]],
});
const getCurrentMonthDetail = (currentMonth, currentYear, numberCP) => {
  const header = {
    ySteps: -1,
    columns: [
      WHITE_EMPTY_CELL_FOR_TITLE,
      WHITE_EMPTY_CELL_FOR_TITLE,
      WHITE_EMPTY_CELL_FOR_TITLE,
      WHITE_EMPTY_CELL_FOR_TITLE,
      WHITE_EMPTY_CELL_FOR_TITLE,
      WHITE_EMPTY_CELL_FOR_TITLE,
      WHITE_EMPTY_CELL_FOR_TITLE,
      WHITE_EMPTY_CELL_FOR_TITLE,
    ],
  };
  const dataSet = [
    [
      {
        value: 'Année',
        style: {
          border: {
            left: { style: 'thin', color: 'black' },
            right: { style: 'thin', color: 'black' },
            top: { style: 'thin', color: 'black' },
          },
        },
      },
      {
        value: currentYear,
        style: {
          border: {
            left: { style: 'thin', color: 'black' },
            right: { style: 'thin', color: 'black' },
            top: { style: 'thin', color: 'black' },
          },
        },
      },
      WHITE_EMPTY_CELL,
      WHITE_EMPTY_CELL,
      WHITE_EMPTY_CELL,
      WHITE_EMPTY_CELL,
      WHITE_EMPTY_CELL,
      WHITE_EMPTY_CELL,
    ],
    [
      {
        value: 'Mois',
        style: { border: { left: { style: 'thin', color: 'black' }, right: { style: 'thin', color: 'black' } } },
      },
      {
        value: currentMonth,
        style: { border: { left: { style: 'thin', color: 'black' }, right: { style: 'thin', color: 'black' } } },
      },
      WHITE_EMPTY_CELL,
      WHITE_EMPTY_CELL,
      WHITE_EMPTY_CELL,
      WHITE_EMPTY_CELL,
      WHITE_EMPTY_CELL,
      WHITE_EMPTY_CELL,
    ],
    [
      {
        value: 'Jours ouvrés',
        style: {
          border: {
            left: { style: 'thin', color: 'black' },
            right: { style: 'thin', color: 'black' },
            bottom: { style: 'thin', color: 'black' },
          },
        },
      },
      {
        value: numberCP,
        style: {
          border: {
            left: { style: 'thin', color: 'black' },
            right: { style: 'thin', color: 'black' },
            bottom: { style: 'thin', color: 'black' },
          },
          alignment: { horizontal: 'left' },
        },
      },
      WHITE_EMPTY_CELL,
      WHITE_EMPTY_CELL,
      WHITE_EMPTY_CELL,
      WHITE_EMPTY_CELL,
      WHITE_EMPTY_CELL,
      WHITE_EMPTY_CELL,
    ],
    [
      WHITE_EMPTY_CELL,
      WHITE_EMPTY_CELL,
      WHITE_EMPTY_CELL,
      WHITE_EMPTY_CELL,
      WHITE_EMPTY_CELL,
      WHITE_EMPTY_CELL,
      WHITE_EMPTY_CELL,
      WHITE_EMPTY_CELL,
    ],
  ];
  return {
    ...header,
    data: dataSet,
  };
};

const WHITE_EMPTY_CELL_FOR_TITLE_MEMBER = {
  title: '',
  style: {
    fill: {
      fgColor: { rgb: 'FFFFFFFF' },
    },
    border: {
      top: { style: 'thin', color: 'black' },
      bottom: { style: 'thin', color: 'black' },
      right: { style: 'thin', color: 'black' },
    },
  },
  width: { wpx: 37 },
};
const MEMBERS_HEADER = {
  columns: [
    {
      title: 'Nom',
      style: {
        font: { bold: true },
        border: {
          left: { style: 'thin', color: 'black' },
          right: { style: 'thin', color: 'black' },
          top: { style: 'thin', color: 'black' },
        },
        alignment: { vertical: 'center', horizontal: 'center' },
      },
      width: { wpx: 110 },
    },
    {
      title: 'Nombre\nde jours\ntravaillés',
      style: {
        font: { bold: true },
        border: {
          left: { style: 'thin', color: 'black' },
          right: { style: 'thin', color: 'black' },
          top: { style: 'thin', color: 'black' },
        },
        alignment: { vertical: 'center', horizontal: 'center' },
      },
      width: { wpx: 64 },
    },
    {
      title: '    Congés payés',
      style: {
        font: { bold: true },
        border: {
          left: { style: 'thin', color: 'black' },
          top: { style: 'thin', color: 'black' },
          bottom: { style: 'thin', color: 'black' },
        },
        alignment: { vertical: 'center', horizontal: 'top' },
      },
      width: { wpx: 60 },
    },
    WHITE_EMPTY_CELL_FOR_TITLE_MEMBER,
    {
      title: 'Congés non payés',
      style: {
        font: { bold: true },
        border: {
          left: { style: 'thin', color: 'black' },
          top: { style: 'thin', color: 'black' },
          bottom: { style: 'thin', color: 'black' },
        },
        alignment: { vertical: 'center', horizontal: 'top' },
      },
      width: { wpx: 60 },
    },
    WHITE_EMPTY_CELL_FOR_TITLE_MEMBER,
    {
      title: '  Congés maladie',
      style: {
        font: { bold: true },
        border: {
          left: { style: 'thin', color: 'black' },
          top: { style: 'thin', color: 'black' },
          bottom: { style: 'thin', color: 'black' },
        },
        alignment: { vertical: 'center', horizontal: 'top' },
      },
      width: { wpx: 60 },
    },
    WHITE_EMPTY_CELL_FOR_TITLE_MEMBER,
  ],
};

const PROJECT_HEADER = {
  columns: [
    {
      title: 'Nom',
      style: {
        font: { bold: true },
        border: {
          left: { style: 'thin', color: 'black' },
          right: { style: 'thin', color: 'black' },
          top: { style: 'thin', color: 'black' },
        },
        alignment: { vertical: 'center', horizontal: 'center' },
      },
      width: { wpx: 110 },
    },
    {
      title: 'Nombre\nde jours\ntravaillés',
      style: {
        font: { bold: true },
        border: {
          left: { style: 'thin', color: 'black' },
          right: { style: 'thin', color: 'black' },
          top: { style: 'thin', color: 'black' },
        },
        alignment: { vertical: 'center', horizontal: 'center' },
      },
      width: { wpx: 64 },
    },
    {
      title: '    Congés',
      style: {
        font: { bold: true },
        border: {
          left: { style: 'thin', color: 'black' },
          top: { style: 'thin', color: 'black' },
          bottom: { style: 'thin', color: 'black' },
        },
        alignment: { vertical: 'center', horizontal: 'top' },
      },
      width: { wpx: 60 },
    },
    WHITE_EMPTY_CELL_FOR_TITLE_MEMBER,
    {
      title: '  Off',
      style: {
        font: { bold: true },
        border: {
          left: { style: 'thin', color: 'black' },
          top: { style: 'thin', color: 'black' },
          bottom: { style: 'thin', color: 'black' },
        },
        alignment: { vertical: 'center', horizontal: 'top' },
      },
      width: { wpx: 60 },
    },
    WHITE_EMPTY_CELL_FOR_TITLE_MEMBER,
  ],
};

const MEMBERS_SUB_HEADER_EMPTY_CELL = {
  value: '',
  style: {
    border: {
      top: { style: 'thin', color: { rgb: 'FFFFFFFF' } },
      bottom: { style: 'thin', color: 'black' },
      left: { style: 'thin', color: 'black' },
      right: { style: 'thin', color: 'black' },
    },
  },
};
const MEMBERS_SUB_HEADER_DAY_CELL = {
  value: 'Dates',
  style: {
    border: {
      bottom: { style: 'thin', color: 'black' },
      left: { style: 'thin', color: 'black' },
      right: { style: 'dotted', color: 'black' },
    },
    alignment: { horizontal: 'center' },
  },
};
const MEMBERS_SUB_HEADER_TOTAL_CELL = {
  value: 'Total',
  style: {
    border: {
      bottom: { style: 'thin', color: 'black' },
      left: { style: 'dotted', color: 'black' },
      right: { style: 'thin', color: 'black' },
    },
    alignment: { horizontal: 'center' },
  },
};

const MEMBERS_SUB_HEADER = [
  MEMBERS_SUB_HEADER_EMPTY_CELL,
  MEMBERS_SUB_HEADER_EMPTY_CELL,
  MEMBERS_SUB_HEADER_DAY_CELL,
  MEMBERS_SUB_HEADER_TOTAL_CELL,
  MEMBERS_SUB_HEADER_DAY_CELL,
  MEMBERS_SUB_HEADER_TOTAL_CELL,
  MEMBERS_SUB_HEADER_DAY_CELL,
  MEMBERS_SUB_HEADER_TOTAL_CELL,
];

const PROJECT_SUB_HEADER_EMPTY_CELL = {
  value: '',
  style: {
    border: {
      top: { style: 'thin', color: { rgb: 'FFFFFFFF' } },
      bottom: { style: 'thin', color: { rgb: 'FFFFFFFF' } },
      right: { style: 'thin', color: { rgb: 'FFFFFFFF' } },
    },
  },
};

const PROJECT_SUB_HEADER = [
  MEMBERS_SUB_HEADER_EMPTY_CELL,
  MEMBERS_SUB_HEADER_EMPTY_CELL,
  MEMBERS_SUB_HEADER_DAY_CELL,
  MEMBERS_SUB_HEADER_TOTAL_CELL,
  MEMBERS_SUB_HEADER_DAY_CELL,
  MEMBERS_SUB_HEADER_TOTAL_CELL,
  PROJECT_SUB_HEADER_EMPTY_CELL,
  PROJECT_SUB_HEADER_EMPTY_CELL,
];

const getUsefulEvents = (events, spareDays, personId, members) => {
  let filtredEvents = reduce(
    (acc, event) => {
      if (!isActiveDay(personId, members, event)) return acc;
      if (isSpareDayOrWeekend(event, spareDays)) return acc;
      return [event, ...acc];
    },
    [],
    events,
  );

  return filtredEvents;
};

const getPersonEventDetailsByType = (personId, events, month, spareDays, members, eventType) => {
  let memberEvents = filter(pathEq(['person', 'id'], personId))(events);

  let memberEventsByType;
  switch (eventType) {
    case TYPE.vacation:
      memberEventsByType = filter(isVacation, memberEvents);
      break;
    case TYPE.unpaidLeave:
      memberEventsByType = filter(isUnpaidLeave, memberEvents);
      break;
    case TYPE.sickLeave:
      memberEventsByType = filter(isSickLeave, memberEvents);
      break;
    case TYPE.dayOff:
      memberEventsByType = filter(isDayOff, memberEvents);
      break;
    default:
      break;
  }

  memberEventsByType = map(event =>
    eachHourOfInterval({ start: new Date(event.from), end: new Date(event.to) }, { step: 12 }),
  )(memberEventsByType);
  memberEventsByType = flatten(memberEventsByType);

  memberEventsByType = getUsefulEvents(memberEventsByType, spareDays, personId, members);

  memberEventsByType = map(event => ({
    d: format(new Date(event), 'd'),
    h: format(new Date(event), 'a'),
  }))(memberEventsByType);

  let memberEventTotalCell = memberEventsByType?.length / 2 || 0;
  memberEventsByType = groupWith((a, b) => equals(a.d, b.d), memberEventsByType);

  let memberEventDaysCell = reduce(
    (accumulator, currentValue) => {
      const day = currentValue[0].d + (currentValue.length === 1 ? ` [${currentValue[0].h}]` : '');
      return accumulator + day + '\n';
    },
    '',
    memberEventsByType,
  );
  memberEventDaysCell = memberEventDaysCell.substring(0, memberEventDaysCell.length - 1);

  return { Days: memberEventDaysCell, Total: memberEventTotalCell };
};

const getCompanyCollaboratorsTable = (members, spareDays, events, month, team) => {
  let membersTable = map(member => {
    const workingDaysNumberExceptDayOffs = getPersonWorkingDaysNumber(
      member.person.id,
      events,
      month,
      spareDays,
      members,
      team,
    );
    const vacation = getPersonEventDetailsByType(member.person.id, events, month, spareDays, members, TYPE.vacation);
    const unpaidLeave = getPersonEventDetailsByType(
      member.person.id,
      events,
      month,
      spareDays,
      members,
      TYPE.unpaidLeave,
    );
    const sickLeave = getPersonEventDetailsByType(member.person.id, events, month, spareDays, members, TYPE.sickLeave);

    return {
      fullName: member.person.fullname,
      workingDaysNumberExceptDayOffs: workingDaysNumberExceptDayOffs,
      vacation: vacation,
      unpaidLeave: unpaidLeave,
      sickLeave: sickLeave,
    };
  }, members);

  membersTable = map(member => {
    return [
      {
        value: member.fullName,
        style: {
          border: {
            bottom: { style: 'thin', color: 'black' },
            left: { style: 'thin', color: 'black' },
            right: { style: 'thin', color: 'black' },
          },
          alignment: { vertical: 'center', horizontal: 'center' },
        },
      },
      {
        value: member.workingDaysNumberExceptDayOffs,
        style: {
          border: {
            bottom: { style: 'thin', color: 'black' },
            left: { style: 'thin', color: 'black' },
            right: { style: 'thin', color: 'black' },
          },
          alignment: { vertical: 'center', horizontal: 'center' },
        },
      },
      {
        value: member.vacation.Days,
        style: {
          border: {
            bottom: { style: 'thin', color: 'black' },
            left: { style: 'thin', color: 'black' },
            right: { style: 'dotted', color: 'black' },
          },
          alignment: { vertical: 'center', horizontal: 'center' },
        },
      },
      {
        value: member.vacation.Total,
        style: {
          border: {
            bottom: { style: 'thin', color: 'black' },
            left: { style: 'dotted', color: 'black' },
            right: { style: 'thin', color: 'black' },
          },
          alignment: { vertical: 'center', horizontal: 'center' },
        },
      },
      {
        value: member.unpaidLeave.Days,
        style: {
          border: {
            bottom: { style: 'thin', color: 'black' },
            left: { style: 'thin', color: 'black' },
            right: { style: 'dotted', color: 'black' },
          },
          alignment: { vertical: 'center', horizontal: 'center' },
        },
      },
      {
        value: member.unpaidLeave.Total,
        style: {
          border: {
            bottom: { style: 'thin', color: 'black' },
            left: { style: 'dotted', color: 'black' },
            right: { style: 'thin', color: 'black' },
          },
          alignment: { vertical: 'center', horizontal: 'center' },
        },
      },
      {
        value: member.sickLeave.Days,
        style: {
          border: {
            bottom: { style: 'thin', color: 'black' },
            left: { style: 'thin', color: 'black' },
            right: { style: 'dotted', color: 'black' },
          },
          alignment: { vertical: 'center', horizontal: 'center' },
        },
      },
      {
        value: member.sickLeave.Total,
        style: {
          border: {
            bottom: { style: 'thin', color: 'black' },
            left: { style: 'dotted', color: 'black' },
            right: { style: 'thin', color: 'black' },
          },
          alignment: { vertical: 'center', horizontal: 'center' },
        },
      },
    ];
  }, membersTable);

  return {
    ...MEMBERS_HEADER,
    data: [MEMBERS_SUB_HEADER, ...membersTable],
  };
};

const getPersonEventsDetailsExceptDayOffsAndWorkingDays = (personId, events, month, spareDays, members) => {
  let memberEvents = filter(pathEq(['person', 'id'], personId))(events);

  let memberEventsByType = filter(event => !isDayOff(event) && !isWorkingDay(event), memberEvents);

  memberEventsByType = map(event =>
    eachHourOfInterval({ start: new Date(event.from), end: new Date(event.to) }, { step: 12 }),
  )(memberEventsByType);
  memberEventsByType = flatten(memberEventsByType);

  memberEventsByType = getUsefulEvents(memberEventsByType, spareDays, personId, members);

  memberEventsByType = map(event => ({
    d: format(new Date(event), 'd'),
    h: format(new Date(event), 'a'),
  }))(memberEventsByType);

  let memberEventTotalCell = memberEventsByType?.length / 2 || 0;
  memberEventsByType = groupWith((a, b) => equals(a.d, b.d), memberEventsByType);

  let memberEventDaysCell = reduce(
    (accumulator, currentValue) => {
      const day = currentValue[0].d + (currentValue.length === 1 ? ` [${currentValue[0].h}]` : '');
      return accumulator + day + '\n';
    },
    '',
    memberEventsByType,
  );
  memberEventDaysCell = memberEventDaysCell.substring(0, memberEventDaysCell.length - 1);

  return { Days: memberEventDaysCell, Total: memberEventTotalCell };
};

const getProjectCollaboratorsTable = (members, spareDays, events, month, team) => {
  let membersTable = map(member => {
    const workingDaysNumberExceptDayOffs = getPersonWorkingDaysNumber(
      member.person.id,
      events,
      month,
      spareDays,
      members,
      team,
    );
    const eventsDetailsExceptDayOffs = getPersonEventsDetailsExceptDayOffsAndWorkingDays(
      member.person.id,
      events,
      month,
      spareDays,
      members,
    );
    const dayOff = getPersonEventDetailsByType(member.person.id, events, month, spareDays, members, TYPE.dayOff);

    return {
      fullName: member.person.fullname,
      workingDaysNumberExceptDayOffs: workingDaysNumberExceptDayOffs,
      vacation: eventsDetailsExceptDayOffs,
      dayOff: dayOff,
    };
  }, members);

  membersTable = map(member => {
    return [
      {
        value: member.fullName,
        style: {
          border: {
            bottom: { style: 'thin', color: 'black' },
            left: { style: 'thin', color: 'black' },
            right: { style: 'thin', color: 'black' },
          },
          alignment: { vertical: 'center', horizontal: 'center' },
        },
      },
      {
        value: member.workingDaysNumberExceptDayOffs,
        style: {
          border: {
            bottom: { style: 'thin', color: 'black' },
            left: { style: 'thin', color: 'black' },
            right: { style: 'thin', color: 'black' },
          },
          alignment: { vertical: 'center', horizontal: 'center' },
        },
      },
      {
        value: member.vacation.Days,
        style: {
          border: {
            bottom: { style: 'thin', color: 'black' },
            left: { style: 'thin', color: 'black' },
            right: { style: 'dotted', color: 'black' },
          },
          alignment: { vertical: 'center', horizontal: 'center' },
        },
      },
      {
        value: member.vacation.Total,
        style: {
          border: {
            bottom: { style: 'thin', color: 'black' },
            left: { style: 'dotted', color: 'black' },
            right: { style: 'thin', color: 'black' },
          },
          alignment: { vertical: 'center', horizontal: 'center' },
        },
      },
      {
        value: member.dayOff.Days,
        style: {
          border: {
            bottom: { style: 'thin', color: 'black' },
            left: { style: 'thin', color: 'black' },
            right: { style: 'dotted', color: 'black' },
          },
          alignment: { vertical: 'center', horizontal: 'center' },
        },
      },
      {
        value: member.dayOff.Total,
        style: {
          border: {
            bottom: { style: 'thin', color: 'black' },
            left: { style: 'dotted', color: 'black' },
            right: { style: 'thin', color: 'black' },
          },
          alignment: { vertical: 'center', horizontal: 'center' },
        },
      },
      PROJECT_SUB_HEADER_EMPTY_CELL,
      PROJECT_SUB_HEADER_EMPTY_CELL,
    ];
  }, membersTable);

  return {
    ...PROJECT_HEADER,
    data: [PROJECT_SUB_HEADER, ...membersTable],
  };
};

export const buildExcelDataSet = (members, currentYear, currentMonth, month, spareDays, events, team) => {
  if (!members || !members.length || !month || !spareDays || !events) return [null, true];

  const numberCP = getMonthWorkingDaysNumber(month, spareDays);
  const teamTitle = getTeam(team.name);
  const currentMonthDetail = getCurrentMonthDetail(currentMonth, currentYear, numberCP);
  const collaboratorDetails = isProject(team)
    ? getProjectCollaboratorsTable(members, spareDays, events, month, team)
    : getCompanyCollaboratorsTable(members, spareDays, events, month, team);

  const dataSet = [DOCUMENT_TITLE, teamTitle, currentMonthDetail, collaboratorDetails];

  return [dataSet, false];
};
