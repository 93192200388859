import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export const GET_STATS = gql`
  query CompanyStats($teamId: ObjectID!, $from: DateTime!, $to: DateTime!) {
    companyWorkingDaysStats(teamId: $teamId, from: $from, to: $to) {
      worker {
        id
        fullname
        email
        firstname
        lastname
      }
      workingDaysInPeriod
      vacationDays
      unpaidLeaveDays
      sickLeaveDays
    }
  }
`;

export const useStats = (params, options) => {
  const res = useQuery(GET_STATS, {
    ...options,
    fetchPolicy: 'cache-and-network',
    variables: params,
  });
  return [res.data?.companyWorkingDaysStats || [], res.loading, res.error];
};
