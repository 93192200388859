import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    width: 250,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  extend: {
    width: 342,
  },
  highlight: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  hover: {
    '&:hover': {
      opacity: 0.8,
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconBox: {
    padding: 2,
  },
  searchIcon: {
    marginTop: 8,
    marginLeft: 3,
    fill: 'black',
  },
  clearBox: {
    padding: 3,
    marginRight: 3,
  },
  clearIcon: {
    fill: '#d13232',
  },
  searchBox: {
    padding: 3,
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
  },
}));
