import React from 'react';

import { Button } from '@material-ui/core';
import messages from './messages';
import { FormattedMessage } from 'react-intl';
import SaveIcon from '@material-ui/icons/Save';

const ConfirmButton = ({ ...rest }) => {
  return (
    <Button {...rest} fullWidth type="submit" color="primary" variant="contained" startIcon={<SaveIcon />}>
      <FormattedMessage {...messages.confirm} />
    </Button>
  );
};

export default ConfirmButton;
