import { defineMessages } from 'react-intl';

export default defineMessages({
  login: {
    id: 'components.Login.login',
    defaultMessage: 'Sign In',
  },
  or: {
    id: 'components.Login.or',
    defaultMessage: '- OR sign in with -',
  },

  password: {
    id: 'components.Login.password',
    defaultMessage: 'Password',
  },
  email: {
    id: 'components.Login.email',
    defaultMessage: 'Email',
  },
  forgot: {
    id: 'components.Login.forgot',
    defaultMessage: 'Forgot your password?',
  },
  forgetPassword: {
    id: 'components.Login.forgetPassword',
    defaultMessage: 'Forgot your password?',
  },
  signInError: {
    id: 'components.Login.signInError',
    defaultMessage: 'Authentication failed, please retry',
  },
  required: {
    id: 'components.Login.required',
    defaultMessage: 'Required',
  },
  invalidEmail: {
    id: 'components.Login.invalidEmail',
    defaultMessage: 'Email is invalid',
  },
  signInWithGoogle: {
    id: 'components.Login.signInWithGoogle',
    defaultMessage: 'Sign in with Google',
  },
  signInWithFacebook: {
    id: 'components.Login.signInWithFacebook',
    defaultMessage: 'Sign in with Facebook',
  },
  signUp: {
    id: 'components.Login.signUp',
    defaultMessage: 'If you do not have an account yet, please signUp',
  },
});
