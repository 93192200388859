import gql from 'graphql-tag';
import { getTime, addMonths, startOfMonth, subMonths } from 'date-fns';

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
    currentMonth: Date!
  }
`;

export const resolvers = {
  Mutation: {
    moveNextMonth: (_root, args, { cache }) => {
      const { currentMonth } = cache.readQuery({
        query: gql`
          {
            currentMonth
          }
        `,
      });
      cache.writeData({ data: { currentMonth: getTime(addMonths(currentMonth, 1)) } });
      return null;
    },
    movePreviousMonth: (_root, args, { cache }) => {
      const { currentMonth } = cache.readQuery({
        query: gql`
          {
            currentMonth
          }
        `,
      });
      cache.writeData({ data: { currentMonth: getTime(subMonths(currentMonth, 1)) } });
      return null;
    },
    moveCurrentMonth: (_root, args, { cache }) => {
      cache.writeData({ data: { currentMonth: getTime(startOfMonth(new Date())) } });
      return null;
    },
  },
};
