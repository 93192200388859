import { defineMessages } from 'react-intl';

export default defineMessages({
  error: {
    id: 'components.ResetPassword.error',
    defaultMessage: 'Something went wrong',
  },
  request: {
    id: 'components.ResetPassword.request',
    defaultMessage: 'New password request',
  },
  requestButton: {
    id: 'components.ResetPassword.requestButton',
    defaultMessage: 'Request new password',
  },
  haveLogin: {
    id: 'components.ResetPassword.haveLogin',
    defaultMessage: 'Already have login and password?',
  },
  email: {
    id: 'components.ResetPassword.email',
    defaultMessage: 'Email',
  },
  signIn: {
    id: 'components.ResetPassword.signIn',
    defaultMessage: 'Sign In',
  },
  required: {
    id: 'components.ResetPassword.required',
    defaultMessage: 'Required',
  },
  invalidEmail: {
    id: 'components.ResetPassword.invalidEmail',
    defaultMessage: 'Email is invalid',
  },
  requestSent: {
    id: 'components.ResetPassword.requestSent',
    defaultMessage: 'Request sent to {email}, please check your email',
  },
});
