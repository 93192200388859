import React from 'react';
import { node, object } from 'prop-types';
import { Redirect } from 'react-router-dom';
import Login from '../Login';
import { useLoggedUser } from '../../hooks/user';
import useRoutes from '../../hooks/routes';

const Auth = ({ route, children }) => {
  const [user, loading] = useLoggedUser();
  const routes = useRoutes();
  if (loading) return null;
  if (!user && !route.isAuthorized(user)) return <Login />;
  else if (!route.isAuthorized(user)) return <Redirect to={routes.getDefaultPath()} />;
  return children;
};

Auth.propTypes = {
  children: node.isRequired,
  route: object,
};

export default Auth;
