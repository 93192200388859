import { createBrowserHistory } from 'history';
import { forEach } from 'ramda';
import React from 'react';
import ReactDOM from 'react-dom';
import { createGenerateClassName, StylesProvider, ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { InMemoryCache, ApolloClient } from '@apollo/client';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { RoutesProvider } from './components/RoutesProvider';
import { AuthProvider } from './components/AuthProvider';
import CurrentMonthProvider from './components/CurrentMonthProvider';
import themeApp from './theme';
import routes from './routes';
import App from './components/App';
import * as serviceWorker from '../serviceWorker';
import api from '../lib/api';
import { TOKEN } from './utils';
import authManager from './authManager';
import LanguageProvider from './components/LanguageProvider';
import NotificationProvider from './components/NotificationProvider';
import LoadingProvider from './components/LoadingProvider';
import ErrorBoundary from './components/ErrorBoundary';
import { resolvers } from './resolvers';
import { loggedUserIdVar, calendarsCurrentMonthVar } from './store';

api.config({
  getBearer: () => localStorage.getItem(TOKEN),
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      allUsers: {
        merge(existing, incoming = []) {
          // why ????
          return incoming;
        },
      },
      fields: {
        // allSpareDays: {
        //   keyArgs: ({ filter }) => {
        //     if (filter?.country) return `country:${filter.country}`;
        //     if (filter?.teamId) return `team:${filter.teamId}`;
        //     return filter;
        //   },
        // },
        calendarsCurrentMonth: {
          read() {
            return calendarsCurrentMonthVar();
          },
        },
        loggedUserId: {
          read() {
            return loggedUserIdVar();
          },
        },
      },
    },
  },
});

const GRAPHQL_END_POINT = '/graphql';

const httpLink = new HttpLink({ uri: GRAPHQL_END_POINT });

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ response, graphQLErrors, networkError }) => {
  if (networkError) console.error(`[Network error]: ${networkError}`);
  if (graphQLErrors) {
    forEach(({ extensions, message, path }) => {
      switch (extensions.code) {
        case 401:
        case 403:
          // if (loggedUserIdVar()) authManager(client).logout();
          loggedUserIdVar(null);
          response.errors = undefined;
          break;
        default:
          console.error(`[GraphQL error]: Message: ${message}, Path: ${path}`);
      }
    }, graphQLErrors);
  }
});

const client = new ApolloClient({
  cache,
  // queryDeduplication: false,
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  resolvers,
});

// cache.writeData({
//   data: {
//     loggedUserId: null,
//     currentMonth: getTime(startOfMonth(new Date())),
//   },
// });

const history = createBrowserHistory();

const theme = themeApp;
const generateClassName = createGenerateClassName();

const render = () => {
  const ROOT = (
    <StylesProvider generateClassName={generateClassName} injectFirst>
      <ApolloProvider client={client}>
        <AuthProvider value={authManager(client)}>
          <RoutesProvider value={routes}>
            <LanguageProvider messages={window.I18N || {}}>
              <NotificationProvider>
                <LoadingProvider>
                  <CurrentMonthProvider>
                  <ThemeProvider theme={theme}>
                      <CssBaseline />
                      <Router history={history}>
                        <ErrorBoundary>
                          <App />
                        </ErrorBoundary>
                      </Router>
                  </ThemeProvider>
                    </CurrentMonthProvider>
                </LoadingProvider>
              </NotificationProvider>
            </LanguageProvider>
          </RoutesProvider>
        </AuthProvider>
      </ApolloProvider>
    </StylesProvider>
  );

  ReactDOM.render(ROOT, document.getElementById('root'));
};

serviceWorker.unregister();

const launchApp = async render => {
  const token = localStorage.getItem(TOKEN);
  if (token) {
    await api.auth
      .getUser()
      .then(user => loggedUserIdVar(user._id))
      .catch(() => loggedUserIdVar(null));
  }
  render();
};

launchApp(render);
