import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

export const AlertDialogTitle = ({ children }) => {
  return children;
};

export const AlertDialogContent = ({ children }) => {
  return children;
};

const AlertDialog = ({ open, onClose, onConfirm, children }) => {
  const alertDialogTitle = () => React.Children.toArray(children).find(child => child.type === AlertDialogTitle);
  const alertDialogContent = () => React.Children.toArray(children).find(child => child.type === AlertDialogContent);

  return (
    <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{alertDialogTitle()}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description"> {alertDialogContent()}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} color="secondary" startIcon={<CancelIcon />}>
          <FormattedMessage {...messages.cancel} />
        </Button>
        <Button variant="contained" onClick={onConfirm} color="primary" startIcon={<SaveIcon />} autoFocus>
          <FormattedMessage {...messages.confirm} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AlertDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  children: PropTypes.node,
};

export default AlertDialog;
