import { defineMessages } from 'react-intl';

export default defineMessages({
  create: {
    id: 'components.CreateEvent.create',
    defaultMessage: 'Create',
  },
  update: {
    id: 'components.CreateEvent.update',
    defaultMessage: 'Update',
  },
  delete: {
    id: 'components.CreateEvent.delete',
    defaultMessage: 'Delete',
  },
  cancel: {
    id: 'components.CreateEvent.cancel',
    defaultMessage: 'Cancel',
  },
  typeLabel: {
    id: 'components.CreateEvent.typeLabel',
    defaultMessage: 'Type',
  },
  workerLabel: {
    id: 'components.CreateEvent.workerLabel',
    defaultMessage: 'Worker',
  },
  descriptionLabel: {
    id: 'components.CreateEvent.descriptionLabel',
    defaultMessage: 'Description',
  },
  newEvent: {
    id: 'components.CreateEvent.newEvent',
    defaultMessage: 'New Event',
  },
  updateEvent: {
    id: 'components.CreateEvent.updateEvent',
    defaultMessage: 'Update Event',
  },
  workingDays: {
    id: 'components.CreateEvent.workingDays',
    defaultMessage: '{ workingDays,plural, one {Working Day} other {Working Days} }',
  },
  descriptionPlaceholder: {
    id: 'components.CreateEvent.descriptionPlaceholder',
    defaultMessage: 'Description ...',
  },
  eventCreated: {
    id: 'components.CreateEvent.eventCreated',
    defaultMessage: 'Event Created Succesfully',
  },
  eventNotCreated: {
    id: 'components.CreateEvent.eventNotCreated',
    defaultMessage: "Couldn't create a new event",
  },
  eventUpdated: {
    id: 'components.CreateEvent.eventUpdated',
    defaultMessage: 'Event Updated Succesfully',
  },
  eventNotUpdated: {
    id: 'components.CreateEvent.eventNotUpdated',
    defaultMessage: "Couldn't update event",
  },
  eventDeleted: {
    id: 'components.CreateEvent.eventDeleted',
    defaultMessage: 'Event Deleted Succesfully',
  },
  eventNotDeleted: {
    id: 'components.CreateEvent.eventNotDeleted',
    defaultMessage: "Couldn't delete event",
  },
  type: {
    vacation: {
      id: 'components.CreateEvent.vacation',
      defaultMessage: 'Vacation',
    },
    dayOff: {
      id: 'components.CreateEvent.dayOff',
      defaultMessage: 'DayOff',
    },
    unpaidLeave: {
      id: 'components.CreateEvent.unpaidLeave',
      defaultMessage: 'Unpaid Leave',
    },
    sickLeave: {
      id: 'components.CreateEvent.sickLeave',
      defaultMessage: 'Sick Leave',
    },
    workingDay: {
      id: 'components.CreateEvent.workingDay',
      defaultMessage: 'Working Day',
    },
  },
  required: {
    id: 'components.CreateEvent.required',
    defaultMessage: 'Required',
  },
});
