import React, { useEffect } from 'react';
import endOfMonth from 'date-fns/endOfMonth';
import startOfMonth from 'date-fns/startOfMonth';
import { sortBy, path, map } from 'ramda';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import RowingIcon from '@material-ui/icons/Rowing';
import { Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import messages from './messages';
import { UserAvatar, SMALL } from '../Avatar';
import useRoutes from '../../hooks/routes';
import useCurrentMonth from '../../hooks/currentMonth';
import { useProjectStats } from './hooks';
import useLoading from '../../hooks/loading';
import { convertDateToUTC, convertDateFromUTC } from '../../utils/date';

const useStyles = makeStyles(theme => ({
  titleIcon: { marginRight: theme.spacing(1) },
  noPadding: {
    padding: 0,
    marginRight: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
}));

const Title = () => {
  const classes = useStyles();
  return (
    <Grid container alignItems="center">
      <Grid item>
        <RowingIcon className={classes.titleIcon} />
      </Grid>
      <Grid item>
        <Typography variant="h6">
          <FormattedMessage {...messages.title}></FormattedMessage>
        </Typography>
      </Grid>
    </Grid>
  );
};

const Amendenments = ({ stats }) => {
  const classes = useStyles();
  const routes = useRoutes();
  const intl = useIntl();
  const formatDate = date => intl.formatDate(convertDateFromUTC(date));
  return (
    <Grid container item justify="center" xl={6} lg={8} sm={12}>
      <TableContainer>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell rowSpan={1}></TableCell>
              <TableCell colSpan={2} align="center">
                Last Amendment
              </TableCell>
              <TableCell colSpan={1} align="center">
                Current Year
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">Workers</TableCell>
              <TableCell align="center">Working Days</TableCell>
              <TableCell align="center">Period</TableCell>
              <TableCell align="center">Working Days</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(stat => {
              return (
                <TableRow key={stat.worker.id}>
                  <TableCell align="left">
                    <UserAvatar
                      className={classes.noPadding}
                      user={stat.worker}
                      showTooltip
                      size={SMALL}
                      to={routes.getPathByName('amendments')}
                    />
                    <span>{stat.worker.fullname}</span>
                  </TableCell>
                  <TableCell align="center">
                    {stat.lastAmendment ? `${stat.amendmentWorkingDays} / ${stat.lastAmendment.days}` : ''}
                  </TableCell>
                  <TableCell align="center">
                    {stat.lastAmendment
                      ? `${formatDate(stat.lastAmendment.from)} - ${formatDate(stat.lastAmendment.to)}`
                      : ''}
                  </TableCell>
                  <TableCell align="center">{stat.currentYearWorkingDays}</TableCell>
                </TableRow>
              );
            }, sortBy(path(['worker', 'fullname']), stats))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

Amendenments.propTypes = {
  stats: PropTypes.array.isRequired,
};

const Stats = ({ team }) => {
  const classes = useStyles();
  const setLoading = useLoading();
  const currentMonth = useCurrentMonth();
  const [stats, statsLoading] = useProjectStats({
    teamId: team.id,
    from: convertDateToUTC(startOfMonth(currentMonth())),
    to: convertDateToUTC(endOfMonth(currentMonth())),
  });

  useEffect(() => {
    setLoading(statsLoading);
  }, [setLoading, statsLoading]);

  if (statsLoading) return null;

  return (
    <Grid container justify="flex-start">
      <Grid container direction="row" justify="space-between" alignItems="center" className={classes.title}>
        <Grid item>
          <Title />
        </Grid>
      </Grid>
      <Amendenments stats={stats} />
    </Grid>
  );
};

Stats.propTypes = {
  team: PropTypes.object.isRequired,
};

export default Stats;
