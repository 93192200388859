import { defineMessages } from 'react-intl';

export default defineMessages({
  timesheet: {
    id: 'components.Timesheet.timesheet',
    defaultMessage: 'Timesheet',
  },
  waitLoadingFile: {
    id: 'components.Timesheet.waitLoadingFile',
    defaultMessage: 'Wait loading xlsx file',
  },
});
