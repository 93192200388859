import gql from 'graphql-tag';

export const GET_TEAM = gql`
  query Teams($filter: TeamFilter) {
    allTeams(filter: $filter) {
      id
      name
      country
    }
  }
`;

export const getTeamByCountry = async (client, countryCode) => {
  const variables = { filter: { country: countryCode } };

  const query = GET_TEAM;
  const { data } = await client.query({ query: query, variables });
  return data?.allTeams;
};

export const GET_MEMBERS_TEAM = gql`
  query Team($id: ID!) {
    Team(id: $id) {
      members {
        person {
          id
          fullname
        }
      }
    }
  }
`;

export const getUsersByTeamId = async (client, teamId) => {
  const variables = { id: teamId };
  const query = GET_MEMBERS_TEAM;
  const { data } = await client.query({ query: query, variables });
  return data?.Team?.members;
};
