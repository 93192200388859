import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { map } from 'ramda';
import { CircularProgress, Typography, IconButton, Popover, AppBar, Grid, Hidden } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { useMenu } from './hooks';
import UserCard from '../UserCard';
import Header, { HeaderLeft, HeaderRight, HeaderCenter } from '../Header';
import useRoutes from '../../hooks/routes';
import { useLoggedUser } from '../../hooks/user';
import Auth from '../Auth';
import { NavImpl } from '../Nav';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Layout from '../Layout';
import { UserAvatar, MEDIUM } from '../Avatar';
import NavigationProvider from '../NavigationProvider';
import useLoading from '../../hooks/loading';
import { useIntl } from 'react-intl';
import { getTitle } from '../../sponsor';
import { hasSpareDaysRight, hasTeamsCreateRightOrManager } from '../../roles';

const makeRoute = route => props => (
  <Auth route={route}>
    <route.component {...props} />
  </Auth>
);

const MakeContent = () => {
  const routes = useRoutes();
  return (
    <Switch>
      {map(
        route => (
          <Route key={route.path} exact={route.exact} path={route.path} render={makeRoute(route)} />
        ),
        routes.all,
      )}
      <Redirect to={routes.getDefaultPath()} />
    </Switch>
  );
};

const useStyles = makeStyles(theme => ({
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: 9,
    left: 9,
    zIndex: 1,
  },
  wrapper: {
    position: 'relative',
  },
  menuIcon: { marginRight: theme.spacing(1) },
  iconButton: {
    marginRight: theme.spacing(1),
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(0deg)',
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(180deg)',
  },
  bar: {
    position: 'sticky',
    top: '0',
    zIndex: 1299,
  },
  logo: {
    marginRight: theme.spacing(1),
  },
  buttonContainer: { width: 'max-content', marginLeft: theme.spacing(1) },

  grow: {
    marginRight: theme.spacing(1),
    fontSize: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(1.5),
    },
  },
}));

const App = () => {
  const { userMenuAnchor, openUserMenu, closeUserMenu } = useMenu();
  const isUserMenuOpen = Boolean(userMenuAnchor);
  const classes = useStyles();
  const [user] = useLoggedUser();
  const loading = useLoading();
  const [miniDrawerOpen, setMiniDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMiniDrawerOpen(!miniDrawerOpen);
  };
  const intl = useIntl();
  const { title, logo: Logo } = getTitle(intl);

  return (
    <>
      <NavigationProvider>
        <AppBar position="static" className={classes.bar}>
          <Header>
            {user && (
              <HeaderLeft>
                <Grid
                  container
                  alignItems="center"
                  justify="flex-start"
                  wrap="nowrap"
                  className={classes.buttonContainer}
                >
                  {hasTeamsCreateRightOrManager(user) || hasSpareDaysRight(user) ? (
                    <IconButton
                      className={classes.iconButton}
                      color="inherit"
                      aria-label="Open drawer"
                      onClick={() => handleDrawerToggle()}
                    >
                      <MenuIcon
                        classes={{
                          root: miniDrawerOpen ? classes.menuButtonIconOpen : classes.menuButtonIconClosed,
                        }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton className={classes.iconButton} color="inherit">
                      <ChevronRightIcon />
                    </IconButton>
                  )}
                  <Grid>
                    <NavImpl />
                  </Grid>
                </Grid>
              </HeaderLeft>
            )}

            <HeaderCenter>
              <Hidden smDown implementation="css">
                <Logo fontSize="large" className={classes.logo} />
              </Hidden>
              <Hidden smDown implementation="css">
                <Typography className={classes.grow}>{title}</Typography>
              </Hidden>
            </HeaderCenter>

            {user && (
              <HeaderRight>
                <div className={classes.wrapper}>
                  <IconButton
                    aria-owns={isUserMenuOpen ? 'menu-appbar' : null}
                    aria-haspopup="true"
                    onClick={openUserMenu}
                    color="inherit"
                    className={classes.menuIcon}
                  >
                    <UserAvatar user={user} size={MEDIUM} />
                  </IconButton>
                  {loading() && <CircularProgress size={46} className={classes.fabProgress} />}
                </div>
                <Popover
                  id="menu-appbar"
                  anchorEl={userMenuAnchor}
                  open={isUserMenuOpen}
                  onClose={closeUserMenu}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <UserCard user={user} handleClose={closeUserMenu} />
                </Popover>
              </HeaderRight>
            )}
          </Header>
        </AppBar>
        <Layout user={user} miniDrawerOpen={miniDrawerOpen} handleDrawerToggle={handleDrawerToggle}>
          <MakeContent />
        </Layout>
      </NavigationProvider>
    </>
  );
};

export default App;
