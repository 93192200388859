import React from 'react';
import { object, element } from 'prop-types';
import { IntlProvider } from 'react-intl';

const LanguageProvider = ({ messages, children }) => {
  const locale = window.navigator.language;
  return (
    <IntlProvider key={locale} defaultLocale={locale} locale={locale} messages={messages[locale]}>
      {React.Children.only(children)}
    </IntlProvider>
  );
};

LanguageProvider.propTypes = {
  messages: object,
  children: element.isRequired,
};

export default LanguageProvider;
