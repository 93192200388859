import React, { useRef, useEffect, useState } from 'react';
import { element } from 'prop-types';

export const LoadingContext = React.createContext();

const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState();
  const timer = useRef();

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const fct = state => {
    if (state === undefined) return loading;
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => setLoading(state), state ? 0 : 500);
  };

  return <LoadingContext.Provider value={fct}>{React.Children.only(children)}</LoadingContext.Provider>;
};

LoadingProvider.propTypes = {
  children: element.isRequired,
};

export default LoadingProvider;
