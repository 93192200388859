import { subMinutes, addMinutes } from 'date-fns';
import { is } from 'ramda';

export const convertDateFromUTC = date => {
  const newDate = is(String, date) ? new Date(date) : date;
  const offset = newDate.getTimezoneOffset();
  return addMinutes(newDate, offset);
};

export const convertDateToUTC = date => {
  const newDate = is(String, date) ? new Date(date) : date;
  const offset = newDate.getTimezoneOffset();
  return subMinutes(newDate, offset);
};


