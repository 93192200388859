import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'components.Users.title',
    defaultMessage: 'Users',
  },
  confirm: {
    id: 'components.Users.save',
    defaultMessage: 'Save',
  },
  createUser: {
    id: 'components.Users.createUser',
    defaultMessage: 'Create User',
  },
  cancel: {
    id: 'components.Users.cancel',
    defaultMessage: 'Cancel',
  },

  firstname: {
    id: 'components.Users.firstname',
    defaultMessage: 'Firstname',
  },
  lastname: {
    id: 'components.Users.lastname',
    defaultMessage: 'Lastname',
  },
  email: {
    id: 'components.Users.email',
    defaultMessage: 'Email',
  },
  roles: {
    id: 'components.Users.roles',
    defaultMessage: 'Roles',
  },
  statusLabel: {
    id: 'components.Users.status',
    defaultMessage: 'Status',
  },
  lastLogin: {
    id: 'components.Users.lastLogin',
    defaultMessage: 'Last Login',
  },
  name: {
    id: 'components.Users.name',
    defaultMessage: 'Name',
  },
  teams: {
    id: 'components.Users.teams',
    defaultMessage: 'Teams',
  },
  noTeam: {
    id: 'components.Users.noTeam',
    defaultMessage: 'No default team selected',
  },
  description: {
    id: 'components.Users.description',
    defaultMessage: 'Description',
  },
  invalidEmail: {
    id: 'components.Users.invalidEmail',
    defaultMessage: 'Invalid Email',
  },
  required: {
    id: 'components.Users.required',
    defaultMessage: 'Required',
  },
  requiredLastname: {
    id: 'components.Users.requiredLastname',
    defaultMessage: 'Lastname Required',
  },
  requiredFirstname: {
    id: 'components.Users.requiredFirstname',
    defaultMessage: 'Firstname Required',
  },
  requiredEmail: {
    id: 'components.Users.requiredEmail',
    defaultMessage: 'Email Required',
  },
  emailTaken: {
    id: 'components.Users.emailTaken',
    defaultMessage: 'Email already exists !',
  },
  requiredRoles: {
    id: 'components.Users.requiredRoles',
    defaultMessage: 'Roles Required',
  },
  role: {
    admin: {
      id: 'components.Users.role.admin',
      defaultMessage: 'Admin',
    },
    user: {
      id: 'components.Users.role.user',
      defaultMessage: 'User',
    },
  },
  right: {
    spareDays: {
      id: 'components.Users.right.spareDays',
      defaultMessage: 'sparedays:all',
    },
    teamsCreate: {
      id: 'components.Users.right.teamsCreate',
      defaultMessage: 'teams:create',
    },
  },
  rights: {
    id: 'components.Users.rights',
    defaultMessage: 'Rights',
  },
  status: {
    pending: {
      id: 'components.Users.pending',
      defaultMessage: 'Pending',
    },
    active: {
      id: 'components.Users.active',
      defaultMessage: 'Active',
    },
    inactive: {
      id: 'components.Users.inactive',
      defaultMessage: 'Inactive',
    },
  },
  user: {
    id: 'components.Users.user',
    defaultMessage: 'User',
  },
  noData: {
    id: 'components.Users.noData',
    defaultMessage: 'No data available',
  },
  userCreated: {
    id: 'components.Users.userCreated',
    defaultMessage: 'User {fullname} created',
  },
  cannotCreateNewUser: {
    id: 'components.Users.cannotCreateNewUser',
    defaultMessage: 'Cannot create the user',
  },
  cannotEditUser: {
    id: 'components.Users.cannotUpdate',
    defaultMessage: 'Cannot edit the user',
  },
  userDeleted: {
    id: 'components.Users.userDeleted',
    defaultMessage: 'User deleted',
  },
  cannotDeleteUser: {
    id: 'components.Users.cannotDeleteUser',
    defaultMessage: 'Failed to delete user!',
  },
  userEdited: {
    id: 'components.Users.userEdited',
    defaultMessage: 'User {fullname} updated',
  },
  editUser: {
    id: 'components.Users.editUser',
    defaultMessage: 'Edit User',
  },
  members: {
    id: 'components.Users.members',
    defaultMessage: 'Members',
  },
  prompt: {
    id: 'components.Users.prompt',
    defaultMessage: 'Are you sure you want to leave?',
  },
  goBack: {
    id: 'components.Users.goBack',
    defaultMessage: 'go back',
  },
  delete: {
    id: 'components.Users.delete',
    defaultMessage: 'Delete',
  },
  userDeleteMessage: {
    id: 'components.Users.userDeleteMessage',
    defaultMessage: 'Are you sure you want to delete this user "{fullname}" ?',
  },
  companyTeam: {
    id: 'components.Users.companyTeam',
    defaultMessage: 'Company Team',
  },
  projectTeam: {
    id: 'components.Users.projectTeam',
    defaultMessage: 'Project Team',
  },
  requiredPassword: {
    id: 'components.Users.requiredPassword',
    defaultMessage: 'Password Required',
  },
  password: {
    id: 'components.Users.password',
    defaultMessage: 'Password',
  },
});
