import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/react-hooks';
import { Form, Field } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { CircularProgress, Grid, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { startOfDay } from 'date-fns';
import TextField from '../../forms/TextField';
import SelectField from '../../forms/Select';
import { required, checkPositiveNumber, composeValidators, checkWorkingDaysMax } from '../../forms/utils';
import useNotify from '../../hooks/notification';
import useLoading from '../../hooks/loading';
import { convertDateToUTC } from '../../utils';
import NavTitle from '../NavTitle';
import messages from './messages';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import CancelButton from '../Buttons/cancel';
import ConfirmButton from '../Buttons/confirm';
import useRoutes from '../../hooks/routes';
import { useLoggedUser } from '../../hooks/user';
import { isAdminOrManagerInTeam } from '../../roles';
import { useAmendment, useUpdateAmendment } from './hooks';
import { convertDateToUTCEndOfDay } from '../SpareDays/utils';
import DatePickerForm from '../../forms/DateTimePicker';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const useSyles = makeStyles(theme => ({
  root: { padding: 10 },
  container: {
    padding: theme.spacing(1),
  },
  screen: {
    height: '80vh',
  },
  field: {
    paddingTop: theme.spacing(2),
    width: '100%',
  },
  textField: {
    paddingTop: theme.spacing(1),
    width: '100%',
  },
  cancelButton: {
    marginTop: theme.spacing(2),
  },
}));

const AmendmentEdit = ({ onClose }) => {
  const classes = useSyles();
  const { id } = useParams();
  const notify = useNotify();
  const setLoading = useLoading();
  const intl = useIntl();
  const apolloClient = useApolloClient();
  const routes = useRoutes();
  const history = useHistory();
  const [loggedUser, loadingLoggedUser] = useLoggedUser();

  const [amendment, amendmentLoading] = useAmendment(
    { id },
    {
      onError: error => notify.error({ title: error.toString() }),
    },
  );

  const [updateAmendment, { loading: editLoading }] = useUpdateAmendment({
    onCompleted: () =>
      notify.success({
        title: intl.formatMessage(messages.amendmentEdited),
      }),
    onError: error => notify.error({ title: `${intl.formatMessage(messages.cannotEditAmendment)} : ${error}` }),
  });

  const onSubmit = async values => {
    await updateAmendment({
      variables: {
        id: id,
        from: convertDateToUTC(startOfDay(values.from)),
        to: convertDateToUTCEndOfDay(values.to),
        days: parseInt(values.days),
        description: values.description,
        teamId: values.teamId,
        personId: values.personId,
      },
    });
    onClose();
  };

  useEffect(() => {
    setLoading(amendmentLoading || editLoading || loadingLoggedUser);
  }, [amendmentLoading, setLoading, loadingLoggedUser, editLoading]);

  useEffect(() => {
    if (!isAdminOrManagerInTeam(loggedUser)) history.replace(routes.getDefaultPath());
  }, [loggedUser]); // eslint-disable-line
  if (amendmentLoading || !amendment)
    return (
      <>
        <NavTitle message={intl.formatMessage(messages.editAmendment)} />
        <Grid className={classes.screen} container justify="center" alignItems="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      </>
    );

  return (
    <Grid className={classes.root}>
      <NavTitle message={intl.formatMessage(messages.editAmendment)} />
      <Form
        onSubmit={onSubmit}
        initialValues={{
          teamId: amendment.team.id,
          days: amendment.days,
          from: amendment.from,
          to: amendment.to,
          description: amendment.description,
          personId: amendment.person.id,
        }}
        keepDirtyOnReinitialize
        render={({ handleSubmit, pristine, invalid, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <Prompt when={!pristine && !submitting} message={intl.formatMessage(messages.prompt)} />
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
              spacing={1}
              className={classes.container}
            >
              <Grid item xs={6}>
                <Field
                  fullWidth
                  required
                  component={SelectField}
                  label={<FormattedMessage {...messages.team} />}
                  placeholder={`${intl.formatMessage(messages.team)}`}
                  name="teamId"
                  disabled
                  validate={required(<FormattedMessage {...messages.required} />)}
                  data-testid="amendment.edit.input.teamId"
                >
                  <MenuItem key={values.teamId} value={values.teamId}>
                    <Typography>{amendment.team.name}</Typography>
                  </MenuItem>
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  fullWidth
                  validate={required(<FormattedMessage {...messages.required} />)}
                  required
                  component={SelectField}
                  label={<FormattedMessage {...messages.user} />}
                  placeholder={`${intl.formatMessage(messages.user)}`}
                  name="personId"
                  disabled
                  data-testid="amendment.edit.input.personId"
                >
                  <MenuItem key={values.personId} value={values.personId}>
                    <Typography> {amendment.person.fullname}</Typography>
                  </MenuItem>
                </Field>
              </Grid>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Field
                    required
                    className={classes.field}
                    name="from"
                    component={DatePickerForm}
                    placeholder={`${intl.formatMessage(messages.fromPlaceholder)}`}
                    maxDate={values.to}
                    data-testid="amendment.edit.input.from"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Field
                    required
                    className={classes.field}
                    name="to"
                    component={DatePickerForm}
                    placeholder={`${intl.formatMessage(messages.toPlaceholder)}`}
                    minDate={values.from}
                    data-testid="amendment.edit.input.to"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12}>
                <Field
                  required
                  validate={composeValidators(
                    required(<FormattedMessage {...messages.required} />),
                    checkPositiveNumber(<FormattedMessage {...messages.positiveNumber} />),
                    checkWorkingDaysMax(apolloClient, values)(<FormattedMessage {...messages.workingDaysMax} />),
                  )}
                  className={classes.textField}
                  component={TextField}
                  keyboard={false}
                  type="number"
                  label={<FormattedMessage {...messages.days} />}
                  name="days"
                  data-testid="amendment.edit.input.days"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  className={classes.textField}
                  component={TextField}
                  keyboard={false}
                  label={<FormattedMessage {...messages.description} />}
                  name="description"
                  multiline
                  rows={3}
                  data-testid="amendment.edit.input.description"
                />
              </Grid>
              <Grid item xs={6}>
                <CancelButton className={classes.cancelButton} />
              </Grid>
              <Grid item xs={6}>
                <ConfirmButton
                  className={classes.cancelButton}
                  data-testid="amendment.edit.submit"
                  disabled={pristine || invalid}
                />
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Grid>
  );
};

AmendmentEdit.propTypes = {
  onClose: PropTypes.func,
};

export default AmendmentEdit;
