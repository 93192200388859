import { defineMessages } from 'react-intl';

export default defineMessages({
  checkYourEmail: {
    id: 'components.ResetPassword.checkYourEmail',
    defaultMessage: 'Please check your emails to reset your password',
  },
  checkSpamFolder: {
    id: 'components.ResetPassword.checkSpamFolder',
    defaultMessage: 'No reset email received? Please check your spam folder',
  },
  resetPassword: {
    id: 'components.ResetPassword.resetPassword',
    defaultMessage: 'Reset password',
  },
  almostThere: {
    id: 'components.ResetPassword.almostThere',
    defaultMessage: 'Almost there ...',
  },
  signIn: {
    id: 'components.ResetPassword.signIn',
    defaultMessage: 'Sign in',
  },
  haveLogin: {
    id: 'components.ResetPassword.haveLogin',
    defaultMessage: 'Already have login and password ? ',
  },
});
