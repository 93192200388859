import React from 'react';
import { Grid } from '@material-ui/core';
import propTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  content: {
    '@media screen and (max-height: 430px) and (orientation: landscape)': {
      padding: theme.spacing(0),
      marginTop: theme.spacing(0),
    },
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
    width: `calc(100% - ${drawerWidth}px)`,
  },
}));
const Content = ({ children }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.content} container direction="column">
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};
Content.propTypes = {
  children: propTypes.node.isRequired,
};
export default Content;
