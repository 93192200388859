import { defineMessages } from 'react-intl';

export default defineMessages({
  register: {
    id: 'components.Register.signUp',
    defaultMessage: 'Register',
  },
  or: {
    id: 'components.Register.or',
    defaultMessage: '- OR sign up with -',
  },

  password: {
    id: 'components.Register.password',
    defaultMessage: 'Password',
  },
  passwordConfirmation: {
    id: 'components.Register.passwordConfirmation',
    defaultMessage: 'Confirm Password',
  },
  email: {
    id: 'components.Register.email',
    defaultMessage: 'Email',
  },
  firstname: {
    id: 'components.Register.firstname',
    defaultMessage: 'Firstname',
  },
  lastname: {
    id: 'components.Register.lastname',
    defaultMessage: 'Lastname',
  },
  emailExistSignUpError: {
    id: 'components.Register.signUpError',
    defaultMessage: 'Register failed, Email : {email} already exists, please retry',
  },
  signUpError: {
    id: 'components.Register.signUpError',
    defaultMessage: 'Register failed, please retry',
  },
  required: {
    id: 'components.Register.required',
    defaultMessage: 'Required',
  },
  invalidEmail: {
    id: 'components.Register.invalidEmail',
    defaultMessage: 'Email is invalid',
  },
  signUpWithGoogle: {
    id: 'components.Register.signUpWithGoogle',
    defaultMessage: 'Sign Up with Google',
  },
  signUpWithFacebook: {
    id: 'components.Register.signUpWithFacebook',
    defaultMessage: 'Sign Up with Facebook',
  },
  signUp: {
    id: 'components.Register.signUp',
    defaultMessage: 'Sign Up',
  },
  emailValidation: {
    id: 'components.Register.emailValidation',
    defaultMessage: 'Email is invalid',
  },
  passwordConfirmationError: {
    id: 'components.Register.passwordConfirmation',
    defaultMessage: 'Password is different from previous one',
  },
  passwordValidation: {
    id: 'components.Register.passwordLenght',
    defaultMessage: 'Minimum length is 8 characters',
  },
  signIn: {
    id: 'components.Register.signIn',
    defaultMessage: 'Sign in',
  },
  haveLogin: {
    id: 'components.Register.haveLogin',
    defaultMessage: 'Already have an account ? ',
  },
  emailTaken: {
    id: 'components.Register.emailTaken',
    defaultMessage: 'Email already exists !',
  },
  signUpSucced: {
    id: 'components.Register.signUpSucced',
    defaultMessage: 'Registration confirmed',
  },
});
