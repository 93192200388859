import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router';
import { useHistory, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { Drawer, Fab, Grid } from '@material-ui/core';
import useRoutes from '../../hooks/routes';
import AmendmentList from './List';
import AmendmentCreate from './Create';
import AmendmentEdit from './Edit';

const useStyles = makeStyles(theme => ({
  drawerContent: {
    width: 350,
  },
  table: {
    minWidth: 650,
  },
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
    zIndex: 5,
  },
}));

const Amendments = () => {
  const classes = useStyles();
  const routes = useRoutes();
  const history = useHistory();

  const handleClose = () => {
    history.push(routes.amendments.path);
  };

  return (
    <Fragment>
      <AmendmentList />

      <Switch>
        <Route exact path={routes.amendmentCreate.path}>
          <Drawer open onClose={handleClose}  anchor="right">
            <Grid className={classes.drawerContent}>
              <AmendmentCreate onClose={handleClose} />
            </Grid>
          </Drawer>
        </Route>

        <Route exact path={routes.amendmentEdit.path}>
          <Drawer open anchor="right" onClose={handleClose}>
            <Grid className={classes.drawerContent}>
              <AmendmentEdit onClose={handleClose} />
            </Grid>
          </Drawer>
        </Route>
      </Switch>

      <Link to={routes.amendmentCreate.path} className={classes.absolute}>
        <Fab color="secondary">
          <AddIcon />
        </Fab>
      </Link>
    </Fragment>
  );
};

export default Amendments;
