import React from 'react';
import propTypes from 'prop-types';
import { Toolbar, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  toolBar: {
    padding: 0,
  },
}));

export const HeaderLeft = ({ children }) => {
  return children;
};
export const HeaderCenter = ({ children }) => {
  return children;
};

export const HeaderRight = ({ children }) => {
  return children;
};

const Header = ({ children }) => {
  const headerLeft = () => React.Children.toArray(children).find(child => child.type === HeaderLeft);
  const headerCenter = () => React.Children.toArray(children).find(child => child.type === HeaderCenter);
  const headerRight = () => React.Children.toArray(children).find(child => child.type === HeaderRight);
  const classes = useStyles();
  return (
    <Toolbar className={classes.toolBar}>
      <Grid container alignItems="center" className={classes.header} wrap="nowrap">
        <Grid sm={3} xs={6} container item justify="flex-start" alignItems="center" className={classes.left}>
          {headerLeft()}
        </Grid>
        <Grid sm={6} container item justify="center" alignItems="center" className={classes.center}>
          {headerCenter()}
        </Grid>
        <Grid sm={3} item container justify="flex-end" alignItems="center" className={classes.right}>
          {headerRight()}
        </Grid>
      </Grid>
    </Toolbar>
  );
};

Header.propTypes = {
  children: propTypes.node,
};
HeaderRight.propTypes = {
  children: propTypes.node,
};
HeaderLeft.propTypes = {
  children: propTypes.node,
};
HeaderCenter.propTypes = {
  children: propTypes.node,
};

export default Header;
