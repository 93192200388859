import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export const GET_ALL_EVENTS = gql`
  query CalendarPage($teamId: ObjectID!, $from: DateTime, $to: DateTime) {
    calendarSpareDays: allCalendarSpareDays(from: $from, to: $to, teamId: $teamId) {
      id
      from
      label
    }
    calendarEvents: allCalendarEvents(from: $from, to: $to, teamId: $teamId) {
      id
      person {
        id
        firstname
        lastname
        fullname
        email
      }
      from
      to
      type
      description
      team {
        id
      }
    }
  }
`;

export const useEvents = (params, options) => {
  const { teamId, from, to } = params;
  const res = useQuery(GET_ALL_EVENTS, {
    ...options,
    fetchPolicy: 'cache-and-network',
    variables: { teamId, from, to },
  });
  return [res.data?.calendarSpareDays || [], res.data?.calendarEvents || [], res.loading, res.error];
};
