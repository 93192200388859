import React from 'react';
import { Button, Grid, Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import useRoutes from '../../hooks/routes';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const useSyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    textAlign: 'center',
  },
  card: {
    width: '100%',
  },
  textError: {
    color: '#ea4554',
  },
  textField: {
    margin: theme.spacing(1),
    width: '100%',
  },
  container: {
    marginTop: theme.spacing(2),
  },
  cardContainer: {
    maxWidth: '450px',
  },
}));

export const ResetAlmostThere = () => {
  const classes = useSyles();
  const routes = useRoutes();
  const history = useHistory();
  return (
    <Grid container className={classes.container} justify="center">
      <Grid container item xs={11} className={classes.cardContainer}>
        <Card>
          <CardContent>
            <Grid container direction="column" justify="space-between" spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Typography align="center" variant="h2" gutterBottom>
                  <FormattedMessage {...messages.almostThere} />
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography display="block" align="center" variant="caption">
                  <FormattedMessage {...messages.checkYourEmail} />
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1" align="center">
                  <FormattedMessage {...messages.checkSpamFolder}></FormattedMessage>
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.button}
                  align="center"
                  onClick={() => history.push(routes.getPathByName('requestNewPassword'))}
                >
                  <FormattedMessage {...messages.resetPassword} />
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Typography display="block" align="center" variant="caption" gutterBottom>
                  <FormattedMessage {...messages.haveLogin} />
                  <Button color="primary" onClick={() => history.push(routes.getDefaultPath())}>
                    <FormattedMessage {...messages.signIn} />
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ResetAlmostThere;
