import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router';
import { useHistory, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { Drawer, Fab, Grid } from '@material-ui/core';
import useRoutes from '../../hooks/routes';
import SpareDayEdit from './Edit';
import SpareDayList from './List';
import SpareDayCreate from './Create';

const useStyles = makeStyles(theme => ({
  drawerContent: {
    width: 350,
  },
  table: {
    minWidth: 650,
  },
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
    zIndex: 5,
  },
}));

const SpareDays = () => {
  const classes = useStyles();
  const routes = useRoutes();
  const history = useHistory();

  const handleClose = () => {
    history.push(routes.spareDays.path);
  };

  return (
    <Fragment>
      <SpareDayList />

      <Switch>
        <Route exact path={routes.spareDayCreate.path}>
          <Drawer open onClose={handleClose} anchor="right">
            <Grid className={classes.drawerContent}>
              <SpareDayCreate onClose={handleClose} />
            </Grid>
          </Drawer>
        </Route>

        <Route exact path={routes.spareDayEdit.path}>
          <Drawer open anchor="right" onClose={handleClose}>
            <Grid className={classes.drawerContent}>
              <SpareDayEdit onClose={handleClose} />
            </Grid>
          </Drawer>
        </Route>
      </Switch>

      <Link to={routes.spareDayCreate.path} className={classes.absolute}>
        <Fab color="secondary">
          <AddIcon />
        </Fab>
      </Link>
    </Fragment>
  );
};

export default SpareDays;
