import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';

export const GET_ALL_USERS = gql`
  query allUsers {
    allUsers {
      id
      firstname
      lastname
      fullname
      email
      status
    }
  }
`;

export const useAllUsers = () => {
  const res = useQuery(GET_ALL_USERS, {
    fetchPolicy: 'cache-and-network',
  });
  return [res.data?.allUsers || [], res.loading, res.error];
};

export const GET_USERS_PAGE = gql`
  query UsersPage($first: Int, $skip: Int, $orderBy: String, $filter: UserFilter) {
    items: allUsers(first: $first, skip: $skip, orderBy: $orderBy, filter: $filter) @connection(key: "UsersPage") {
      id
      firstname
      lastname
      fullname
      email
      status
      roles
      rights
      lastLogin
      teams {
        id
        name
        type
        description
      }
    }
    total: _allUsersMeta(first: $first, skip: $skip, orderBy: $orderBy, filter: $filter)
      @connection(key: "UsersPageInfo") {
      count
    }
  }
`;
export const useUsers = (params, options) => {
  const res = useQuery(GET_USERS_PAGE, {
    ...options,
    fetchPolicy: 'cache-and-network',
    variables: params,
  });
  return [res.data?.items || [], res.data?.total.count, res.loading, res.error];
};

export const GET_USER_BY_ID = gql`
  query User($id: ID!) {
    User(id: $id) {
      id
      firstname
      lastname
      fullname
      email
      status
      roles
      rights
      lastLogin
      teams {
        id
        name
        description
        members {
          person {
            id
            lastname
            firstname
            fullname
            email
          }
        }
      }
    }
  }
`;

export const useUserById = params => {
  const res = useQuery(GET_USER_BY_ID, params);
  return [res.data?.User, res.loading, res.error];
};

export const useUpdateUser = options => {
  const UPDATE_USER = gql`
    mutation UpdateUser(
      $id: ID!
      $firstname: String
      $lastname: String
      $email: String
      $status: UserStatus
      $roles: [UserRole]
      $rights: [UserRight]
    ) {
      updateUser(
        id: $id
        firstname: $firstname
        lastname: $lastname
        email: $email
        status: $status
        roles: $roles
        rights: $rights
      ) {
        id
        firstname
        lastname
        fullname
        email
        status
        roles
        rights
        teams {
          id
          name
          description
        }
      }
    }
  `;

  return useMutation(UPDATE_USER, { ...options, refetchQueries: ['UsersPage'] });
};

export const CREATE_USER = gql`
  mutation CreateUser(
    $firstname: String!
    $lastname: String!
    $email: String!
    $status: UserStatus
    $roles: [UserRole]
    $rights: [UserRight]
    $sendInvitation: Boolean
    $password: String
  ) {
    createUser(
      firstname: $firstname
      lastname: $lastname
      sendInvitation: $sendInvitation
      email: $email
      status: $status
      roles: $roles
      rights: $rights
      password: $password
    ) {
      id
    }
  }
`;
export const useCreateUser = options => {
  return useMutation(CREATE_USER, { ...options, refetchQueries: ['UsersPage', 'allUsers'] });
};

export const useDeleteUser = options => {
  const DELETE_USER = gql`
    mutation DeleteUser($id: ID!) {
      deleteUser(id: $id) {
        id
      }
    }
  `;
  return useMutation(DELETE_USER, { ...options, refetchQueries: ['UsersPage', 'allUsers'] });
};

export const CHECK_EMAIL_UNIQUENESS = gql`
  query CheckEmailUniqueness($email: String!) {
    checkEmailUniqueness(email: $email)
  }
`;
