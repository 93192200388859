import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl, FormattedMessage, FormattedDate } from 'react-intl';
import messages from './messages';
import useNotify from '../../hooks/notification';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  TableSortLabel,
  IconButton,
  Chip,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import useRoutes from '../../hooks/routes';
import useLoading from '../../hooks/loading';
import { useAmendments, useDeleteAmendment } from './hooks';
import NavTitle from '../NavTitle';
import AlertDialog, { AlertDialogContent, AlertDialogTitle } from '../AlertDialog';
import { dissoc, equals, isEmpty, map } from 'ramda';
import FullNameField from '../Users/FullNameField';
import { isAdminOrManagerInTeam } from '../../roles';
import { useLoggedUser } from '../../hooks/user';
import { convertAmendmentsDate } from './utils';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    overflowX: 'auto',
    margin: 'auto',
  },
  row: {
    cursor: 'pointer',
  },
  bold: {
    fontWeight: 'bold',
    paddingLeft: theme.spacing(2.5),
  },
  descriptionStyle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const AmendmentList = () => {
  const classes = useStyles();
  const intl = useIntl();
  const routes = useRoutes();
  const setLoading = useLoading();
  const [loggedUser, loadingLoggedUser] = useLoggedUser();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('from');
  const notify = useNotify();
  const history = useHistory();
  const [indexOfRowTodDelete, setIndexOfRowTodDelete] = useState(-1);
  const [team, setTeam] = useState('');
  const [user, setUser] = useState('');
  const [filter, setFilter] = useState({});

  const handleEditRow = amendmentId => {
    history.push(routes.getPathByName('amendmentEdit', amendmentId));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };

  const handleUserChange = event => {
    if (event.target.value !== '') {
      setFilter({ ...filter, personId: event.target.value });
    } else setFilter(dissoc('personId', filter));
    setUser(event.target.value);
  };

  const handleTeamChange = event => {
    if (event.target.value !== '') {
      setFilter({ ...filter, teamId: event.target.value });
    } else setFilter(dissoc('teamId', { ...filter }));
    setTeam(event.target.value);
  };
  const requestParams = {
    orderBy: orderBy + '_' + order.toUpperCase(),
    filter,
  };
  const [amendments, users, teams, loading] = useAmendments(requestParams, {
    onError: error => notify.error({ title: error.message }),
  });

  const [deleteAmendment, { loading: deleteLoading }] = useDeleteAmendment({
    onCompleted: () => notify.success({ title: intl.formatMessage(messages.amendmentDeleted) }),
    onError: () => notify.error({ title: intl.formatMessage(messages.cannotDeleteAmendment) }),
  });
  const handleDelete = id => {
    deleteAmendment({ variables: { id } });
  };
  useEffect(() => {
    setLoading(loading || loadingLoggedUser || deleteLoading);
  }, [loading, setLoading, loadingLoggedUser, deleteLoading]);

  useEffect(() => {
    if (!isAdminOrManagerInTeam(loggedUser)) history.replace(routes.getDefaultPath());
  }, [loggedUser]); // eslint-disable-line

  return (
    <>
      <NavTitle message={intl.formatMessage(messages.title)} />
      <Grid data-testid="amendment.list" container spacing={2} direction="column" alignItems="center">
        <Grid item container justify="center">
          <Grid item>
            <FormControl className={classes.formControl}>
              <InputLabel shrink>
                <FormattedMessage {...messages.user} />
              </InputLabel>
              {users && (
                <Select value={user} onChange={handleUserChange} displayEmpty>
                  <MenuItem value="">
                    <FormattedMessage {...messages.allUsers} />
                  </MenuItem>
                  {map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.fullname}
                    </MenuItem>
                  ))(users)}
                </Select>
              )}
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl className={classes.formControl}>
              <InputLabel shrink>
                <FormattedMessage {...messages.team} />
              </InputLabel>
              {teams && (
                <Select value={team} onChange={handleTeamChange} displayEmpty>
                  <MenuItem value="">
                    <FormattedMessage {...messages.allTeams} />
                  </MenuItem>
                  {map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))(teams)}
                </Select>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid item className={classes.root}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'personId'}
                      direction={orderBy === 'personId' ? order : 'asc'}
                      onClick={createSortHandler('personId')}
                      className={classes.bold}
                    >
                      <FormattedMessage {...messages.user} />
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'teamId'}
                      direction={orderBy === 'teamId' ? order : 'asc'}
                      onClick={createSortHandler('teamId')}
                      className={classes.bold}
                    >
                      <FormattedMessage {...messages.team} />
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'from'}
                      direction={orderBy === 'from' ? order : 'asc'}
                      onClick={createSortHandler('from')}
                      className={classes.bold}
                    >
                      <FormattedMessage {...messages.from} />
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'to'}
                      direction={orderBy === 'to' ? order : 'asc'}
                      onClick={createSortHandler('to')}
                      className={classes.bold}
                    >
                      <FormattedMessage {...messages.to} />
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'days'}
                      direction={orderBy === 'days' ? order : 'asc'}
                      onClick={createSortHandler('days')}
                      className={classes.bold}
                    >
                      <FormattedMessage {...messages.days} />
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'description'}
                      direction={orderBy === 'description' ? order : 'asc'}
                      onClick={createSortHandler('description')}
                      className={classes.bold}
                    >
                      <FormattedMessage {...messages.description} />
                    </TableSortLabel>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {convertAmendmentsDate(amendments).map((amendment, index) => (
                  <TableRow
                    hover
                    onClick={() => handleEditRow(amendment.id)}
                    className={classes.row}
                    data-testid={`amendment#${amendment.id}`}
                    key={amendment.id}
                  >
                    <TableCell align="left">
                      <FullNameField user={amendment.person} />
                    </TableCell>
                    <TableCell align="center">
                      <Chip
                        label={amendment.team.name}
                        onClick={e => {
                          e.stopPropagation();
                          history.push(routes.getPathByName('teamEdit', amendment.team.id));
                        }}
                        style={{ zIndex: 3 }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <FormattedDate value={amendment.from} />
                    </TableCell>
                    <TableCell align="center">
                      <FormattedDate value={amendment.to} />
                    </TableCell>
                    <TableCell align="center">
                      <Typography>{amendment.days}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography className={classes.descriptionStyle}>{amendment.description}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        wrap="nowrap"
                        onClick={event => event.stopPropagation()}
                      >
                        <Grid item>
                          <IconButton
                            onClick={() => history.push(routes.getPathByName('amendmentEdit', amendment.id))}
                            color="primary"
                          >
                            <EditIcon />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <IconButton
                            onClick={() => {
                              setIndexOfRowTodDelete(index);
                            }}
                            style={{ zIndex: 5 }}
                          >
                            <DeleteIcon style={{ color: 'red' }} />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <AlertDialog
                            open={equals(indexOfRowTodDelete, index)}
                            onClose={() => setIndexOfRowTodDelete(-1)}
                            onConfirm={() => {
                              setIndexOfRowTodDelete(-1);
                              handleDelete(amendment.id);
                            }}
                          >
                            <AlertDialogTitle>
                              <FormattedMessage {...messages.delete} />
                            </AlertDialogTitle>
                            <AlertDialogContent>
                              <FormattedMessage {...messages.amendmentDeleteMessage} />
                            </AlertDialogContent>
                          </AlertDialog>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {isEmpty(amendments) && (
          <Grid item>
            <Typography>
              <FormattedMessage {...messages.noAmendments} />
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default AmendmentList;
