import { find, isNil, propEq } from 'ramda';

export const ROLE = {
  worker: 'worker',
  headWorker: 'headWorker',
  observer: 'observer',
  manager: 'manager',
};

const { worker, observer, manager, headWorker } = ROLE;

const lte = (d1, d2) => !d2 || d1 <= d2;
const gte = (d1, d2) => !d2 || d1 >= d2;

export const rolesForTeamsCreateRight = [worker, observer, headWorker];

export const isWorker = member => !isNil(member) && propEq('role', worker)(member);
export const isObserver = member => !isNil(member) && propEq('role', observer)(member);
export const isManager = member => !isNil(member) && propEq('role', manager)(member);
export const isHeadWorker = member => !isNil(member) && propEq('role', headWorker)(member);

export const isActiveMember = (member, from, to) => {
  if (!member.activityPeriods?.length) return true;
  return find(
    ({ inceptionDate, expirationDate }) => gte(to, inceptionDate) && lte(from, expirationDate),
    member.activityPeriods,
  );
};
