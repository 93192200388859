import { propEq } from 'ramda';

export const TYPE = {
  vacation: 'vacation',
  dayOff: 'dayOff',
  unpaidLeave: 'unpaidLeave',
  sickLeave: 'sickLeave',
  workingDay: 'workingDay',
};

const { vacation, dayOff, unpaidLeave, sickLeave, workingDay } = TYPE;

export const projectTeamEventsTypes = [vacation, dayOff, workingDay];

export const companyTeamEventsTypes = [vacation, unpaidLeave, sickLeave, workingDay];

export const timezone = 'Etc/UTC';

export const isVacation = propEq('type', vacation);
export const isDayOff = propEq('type', dayOff);
export const isUnpaidLeave = propEq('type', unpaidLeave);
export const isSickLeave = propEq('type', sickLeave);
export const isWorkingDay = propEq('type', workingDay);

export const employeeEventsTypes = [vacation, unpaidLeave, sickLeave];

export const nonEmployeeEventsTypes = [dayOff, workingDay];
