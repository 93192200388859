import React from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { Button, Grid, Card, CardContent, Typography, CardHeader } from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import LoginWithGoogle from '../LoginWithGoogle';
import useAuth from '../../hooks/auth';
import TextField from '../../forms/TextField';
import Captcha from '../../forms/Captcha';
import useRoutes from '../../hooks/routes';
import {
  required,
  composeValidators,
  emailFormat,
  minLength,
  passwordConfirmation,
  checkEmailUniqueness,
} from '../../forms/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import useNotify from '../../hooks/notification';
// import LoginWithFacebook from '../LoginWithFacebook';
import { useHistory } from 'react-router-dom';

const useSyles = makeStyles(theme => ({
  container: {
    '@media screen and (max-height: 430px) and (orientation: landscape)': {
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'space-between',
    },
  },
  cardContentContainer: {
    '@media screen and (max-height: 430px) and (orientation: landscape)': {
      flex: 4,
    },
  },
  button: {
    width: '100%',
    marginTop: theme.spacing(2),
    '@media screen and (max-height: 430px) and (orientation: landscape)': {
      maxHeight: '33px',
    },
  },
  loginText: { fontSize: theme.spacing(2.5), textTransform: 'uppercase' },
  forgetButton: {
    marginBottom: theme.spacing(5),
  },
  textField: {
    margin: theme.spacing(1),
    '@media screen and (max-height: 430px) and (orientation: landscape)': {
      margin: theme.spacing(0),
    },
    width: '100%',
  },
  pageContainer: {
    marginTop: theme.spacing(2),

    '@media screen and (max-height: 430px) and (orientation: landscape)': {
      padding: theme.spacing(0),
    },
    width: '100%',
  },
  cardContainer: {
    maxWidth: '450px',
  },
  card: {
    padding: theme.spacing(2),
    width: '100%',
    '@media screen and (max-height: 430px) and (orientation: landscape)': {
      padding: theme.spacing(0),
      maxHeight: '285px',
    },
  },
  cardContent: {
    width: '100%',
  },
  content: {
    flexWrap: 'nowrap',
    '@media screen and (max-height: 430px) and (orientation: landscape)': {
      flexDirection: 'row',
    },
  },
}));
export const Register = () => {
  const classes = useSyles();
  const routes = useRoutes();
  const history = useHistory();
  const { register, registerWithGoogle /*, registerWithFacebook*/ } = useAuth();
  const notify = useNotify();
  const intl = useIntl();
  const apolloClient = useApolloClient();

  const onSubmit = values => {
    register(values)
      .then(() => {
        notify.success({ title: intl.formatMessage(messages.signUpSucced) });
        history.push(routes.registerAlmostThere.path);
      })
      .catch(() =>
        notify.error({
          title: intl.formatMessage(messages.signUpError),
        }),
      );
  };

  const responseGoogle = res => {
    if (!res.error) {
      registerWithGoogle({ token: res.tokenId })
        .then(() => {
          notify.success({ title: intl.formatMessage(messages.signUpSucced) });
          history.push(routes.registerAlmostThere.path);
        })
        .catch(() => {
          notify.error({ title: intl.formatMessage(messages.emailExistSignUpError, { email: res.profileObj.email }) });
        });
    } else {
      notify.error({ title: intl.formatMessage(messages.signUpError) });
    }
  };
  // const responseFacebook = res => {
  //   registerWithFacebook(res).catch(() => {
  //     notify.error({ title: intl.formatMessage(messages.signUpError) });
  //   });
  // };
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, pristine, invalid }) => (
        <form onSubmit={handleSubmit}>
          <Grid container alignItems="center" justify="center" className={classes.pageContainer}>
            <Grid container justify="center" className={classes.cardContainer}>
              <Card className={classes.card}>
                <Grid container direction="column" alignItems="center" justify="center" className={classes.container}>
                  <Grid item container direction="column" alignItems="center" className={classes.logoContainer}>
                    <CardHeader title={<FormattedMessage {...messages.signUp} />} />
                  </Grid>
                  <Grid container item className={classes.cardContentContainer}>
                    <CardContent className={classes.cardContent}>
                      <Grid container direction="column" alignItems="center" className={classes.content}>
                        <Grid item container direction="column" alignItems="center" spacing={2}>
                          <Grid item xs={12} md={10} style={{ width: '100%' }}>
                            <LoginWithGoogle
                              responseGoogle={responseGoogle}
                              buttonText={<FormattedMessage {...messages.signUpWithGoogle} />}
                            />
                          </Grid>
                          {/* <Grid item xs={12} md={10} style={{ width: '100%' }}>
                            <LoginWithFacebook
                              responseFacebook={responseFacebook}
                              buttonText={<FormattedMessage {...messages.signUpWithFacebook} />}
                            />
                          </Grid> */}
                          <Grid item>
                            <Typography>
                              <FormattedMessage {...messages.or} />
                            </Typography>
                          </Grid>
                        </Grid>
                        <Field
                          required
                          validate={required(<FormattedMessage {...messages.required} />)}
                          className={classes.textField}
                          component={TextField}
                          label={<FormattedMessage {...messages.firstname} />}
                          name="firstname"
                          type="text"
                        />
                        <Field
                          required
                          validate={required(<FormattedMessage {...messages.required} />)}
                          className={classes.textField}
                          component={TextField}
                          label={<FormattedMessage {...messages.lastname} />}
                          name="lastname"
                          type="text"
                        />
                        <Field
                          required
                          validate={composeValidators(
                            required(<FormattedMessage {...messages.required} />),
                            emailFormat(<FormattedMessage {...messages.emailValidation} />),
                            checkEmailUniqueness(apolloClient)(<FormattedMessage {...messages.emailTaken} />),
                          )}
                          className={classes.textField}
                          component={TextField}
                          label={<FormattedMessage {...messages.email} />}
                          name="email"
                          type="email"
                        />
                        <Field
                          required
                          validate={composeValidators(
                            required(<FormattedMessage {...messages.required} />),
                            minLength(<FormattedMessage {...messages.passwordValidation} />, 8),
                          )}
                          className={classes.textField}
                          component={TextField}
                          label={<FormattedMessage {...messages.password} />}
                          name="password"
                          type="password"
                        />
                        <Field
                          required
                          validate={composeValidators(
                            required(<FormattedMessage {...messages.required} />),
                            passwordConfirmation(<FormattedMessage {...messages.passwordConfirmationError} />),
                          )}
                          className={classes.textField}
                          component={TextField}
                          label={<FormattedMessage {...messages.passwordConfirmation} />}
                          name="passwordConfirmation"
                          type="password"
                        />
                        <Field
                          name="captcharesponse"
                          component={Captcha}
                          sitekey={'6Lft488ZAAAAAEFc6Hvzrous7GE4jvqxCoP-rkPw'}
                          hl={window.navigator.language}
                          validate={required(<FormattedMessage {...messages.required} />)}
                        />
                        <Button
                          color="primary"
                          type="submit"
                          disabled={pristine || invalid}
                          variant="contained"
                          className={classes.button}
                        >
                          <Typography>
                            <FormattedMessage {...messages.register} />
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography display="block" align="center" variant="caption" gutterBottom>
                          <FormattedMessage {...messages.haveLogin} />
                          <Button color="primary" onClick={() => history.push(routes.getDefaultPath())}>
                            <FormattedMessage {...messages.signIn} />
                          </Button>
                        </Typography>
                      </Grid>
                    </CardContent>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default Register;
