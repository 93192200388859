import { useContext } from 'react';
import { NotificationContext } from '../components/NotificationProvider';

const ERROR = 'error';
const INFO = 'info';
const WARNING = 'warning';
const SUCCESS = 'success';

export default () => {
  const dispatchNotification = useContext(NotificationContext);
  const dispatch = level => ({ title, content }) => {
    dispatchNotification({ title, content, level });
    return null;
  };
  return {
    info: dispatch(INFO),
    warning: dispatch(WARNING),
    error: dispatch(ERROR),
    success: dispatch(SUCCESS),
  };
};
