import React from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import KeyboardIcon from '@material-ui/icons/Keyboard';

const TextFieldForm = ({
  input: { value, onChange, name, type, ...restInput },
  required,
  keyboard = true,
  readOnly,
  meta,
  handleCapitalizeSkill = null,
  ...rest
}) => {
  const showError = meta.touched && required ? !meta.valid : meta.error && meta.touched && meta.dirty;

  return (
    <TextField
      {...rest}
      required={required}
      name={name}
      type={type}
      value={!handleCapitalizeSkill ? value : handleCapitalizeSkill(value)}
      onChange={onChange}
      InputProps={{
        ...restInput,
        readOnly: !!readOnly,
        endAdornment: keyboard && <KeyboardIcon />,
      }}
      helperText={showError ? meta.error || meta.submitError : undefined}
      error={showError}
    />
  );
};
TextFieldForm.propTypes = {
  classes: PropTypes.object,
  input: PropTypes.object,
  meta: PropTypes.object,
  required: PropTypes.bool,
  requiredField: PropTypes.bool,
  readOnly: PropTypes.bool,
  keyboard: PropTypes.bool,
  handleCapitalizeSkill: PropTypes.func,
};

export default TextFieldForm;
