import React, { useEffect } from 'react';
import { object } from 'prop-types';
import {
  makeStyles,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Chip,
  Typography,
  InputLabel,
  IconButton,
} from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import useRoutes from '../../hooks/routes';
import { prop } from 'ramda';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { useHistory } from 'react-router-dom';
import { UserAvatar, SMALL } from '../Avatar';
import useLoading from '../../hooks/loading';
import { useUserTeams } from './hooks';
import { isAdmin } from '../../roles';
import { isManagerOrheadWorker } from '../../utils';
import { isCompany } from '../../../lib/models/teams';

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: 'bold',
  },
  table: { height: '100%', width: '100%', overflowX: 'auto', margin: 'auto' },
  noDataMessage: { textAlign: 'center' },
  teamLabel: { fontSize: '1rem' },
}));

const TeamsList = ({ user, loggedUser }) => {
  const routes = useRoutes();
  const history = useHistory();
  const classes = useStyles();
  const setLoading = useLoading();
  const [teams, loading] = useUserTeams(user);

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <TableContainer>
      <InputLabel className={classes.teamLabel}>
        <FormattedMessage {...messages.teams} />
      </InputLabel>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="center" className={classes.bold}>
              <FormattedMessage {...messages.name} />
            </TableCell>
            <TableCell align="center" className={classes.bold}>
              <FormattedMessage {...messages.description} />
            </TableCell>
            <TableCell align="center" className={classes.bold}>
              <FormattedMessage {...messages.typeLabel} />
            </TableCell>
            <TableCell align="center" className={classes.bold}>
              <FormattedMessage {...messages.members} />
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {teams &&
            teams.map(team => (
              <TableRow key={team.id} data-testid={`user.edit.team#${team.id}`}>
                <TableCell align="center">
                  <Chip
                    disabled={!(isAdmin(loggedUser) || isManagerOrheadWorker(loggedUser, team))}
                    variant={isCompany(team) ? 'outlined' : 'default'}
                    color="primary"
                    label={prop('name', team)}
                    onClick={() => history.push(routes.getPathByName('teamEdit', prop('id', team)))}
                  />
                </TableCell>
                <TableCell align="left">
                  <Typography dangerouslySetInnerHTML={{ __html: prop('description', team) }} />
                </TableCell>
                <TableCell align="center">{prop('type', team)}</TableCell>
                <TableCell align="center">
                  {team.members &&
                    team.members.map(member => (
                      <UserAvatar
                        key={member.person?.id}
                        user={member.person}
                        size={SMALL}
                        to={routes.getPathByName('userEdit', member.person?.id)}
                        showTooltip
                      />
                    ))}
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => history.push(routes.getPathByName('calendar', prop('id', team)))}>
                    <CalendarTodayIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {!teams ? (
        <Typography className={classes.noDataMessage}>
          <FormattedMessage {...messages.noData} />
        </Typography>
      ) : null}
    </TableContainer>
  );
};

TeamsList.propTypes = {
  loggedUser: object.isRequired,
  user: object.isRequired,
};

export default TeamsList;
