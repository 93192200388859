import React, { useEffect } from 'react';
import { useParams, useHistory, Prompt } from 'react-router';
import { Grid, CircularProgress, makeStyles, MenuItem, Chip } from '@material-ui/core';
import { map, prop, equals, values as rvalues, reject } from 'ramda';
import { useUserById, useUpdateUser } from './hooks';
import { useApolloClient } from '@apollo/react-hooks';
import { Form, Field } from 'react-final-form';
import TextField from '../../forms/TextField';
import SelectField from '../../forms/SelectField';
import MultiSelectField from '../../forms/MultiSelectField';
import { ROLE, STATUS } from '../../../lib/models/user';
import useRoutes from '../../hooks/routes';
import useLoading from '../../hooks/loading';
import { required, composeValidators, requiredSelect, emailFormat, checkEmailUniqueness } from '../../forms/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import useNotify from '../../hooks/notification';
import NavTitle from '../NavTitle';
import { isAdmin, isLoggedUser, RIGHT, workTogether } from '../../roles';
import { useLoggedUser } from '../../hooks/user';
import UserTeams from '../Teams/UserTeams';
import CancelButton from '../Buttons/cancel';
import GoBackButton from '../Buttons/goBack';
import ConfirmButton from '../Buttons/confirm';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
  },
  screen: {
    height: '80vh',
  },
  form: {
    width: '100%',
  },
  field: { marginTop: theme.spacing(3) },
  buttonContainer: { marginTop: theme.spacing(1), [theme.breakpoints.down('xs')]: {} },
  button: { marginTop: theme.spacing(2) },
  root: { width: '100%' },
  teamsContainer: { marginTop: theme.spacing(2) },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: 100,
  },
}));

const UserEdit = () => {
  const classes = useStyles();
  const routes = useRoutes();
  const history = useHistory();
  const { id } = useParams();
  const notify = useNotify();
  const intl = useIntl();
  const setLoading = useLoading();
  const [loggedUser, loadingLoggedUser] = useLoggedUser();
  const [user, loading] = useUserById({ variables: { id: id } });

  const [updateUser, { loading: editLoading }] = useUpdateUser({
    onCompleted: ({ updateUser }) =>
      notify.success({ title: intl.formatMessage(messages.userEdited, { fullname: updateUser.fullname }) }),
    onError: error => notify.error({ title: `${intl.formatMessage(messages.cannotEditUser)} : ${error}` }),
  });

  const apolloClient = useApolloClient();

  const onSubmit = async values => {
    await updateUser({
      variables: {
        id: id,
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        roles: values.roles,
        rights: values.rights,
        status: values.status,
      },
    });
    history.push(routes.userList.path);
  };

  useEffect(() => {
    setLoading(loading || editLoading || loadingLoggedUser);
  }, [loading, editLoading, loadingLoggedUser, setLoading]);

  useEffect(() => {
    if (!loading && !(isAdmin(loggedUser) || isLoggedUser(user, loggedUser) || workTogether(loggedUser, user)))
      history.replace(routes.getDefaultPath());
  }, [loadingLoggedUser, loading]); // eslint-disable-line

  if (loading)
    return (
      <>
        <NavTitle message={intl.formatMessage(messages.editUser)} />;
        <Grid className={classes.screen} container justify="center" alignItems="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      </>
    );

  return (
    <>
      <NavTitle message={intl.formatMessage(messages.editUser)} />
      <Grid container className={classes.formControl} justify="center" direction="column" alignItems="center">
        <Grid item xs={12}>
          <Form
            onSubmit={onSubmit}
            initialValues={user}
            render={({ handleSubmit, pristine, invalid, submitting, form, values }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                <Prompt when={!pristine && !submitting} message={intl.formatMessage(messages.prompt)} />
                <Grid container alignItems="center" justify="center" className={classes.root}>
                  <Grid item xs={12} className={classes.field}>
                    <Field
                      readOnly={!isAdmin(loggedUser) && !equals(prop('id', user), prop('id', loggedUser))}
                      fullWidth
                      required
                      validate={required(<FormattedMessage {...messages.requiredFirstname} />)}
                      component={TextField}
                      label={<FormattedMessage {...messages.firstname} />}
                      name="firstname"
                      data-testid="user.edit.input.firstname"
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.field}>
                    <Field
                      readOnly={!isAdmin(loggedUser) && !isLoggedUser(user, loggedUser)}
                      fullWidth
                      required
                      validate={required(<FormattedMessage {...messages.requiredLastname} />)}
                      component={TextField}
                      label={<FormattedMessage {...messages.lastname} />}
                      name="lastname"
                      data-testid="user.edit.input.lastname"
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.field}>
                    <Field
                      readOnly={!isAdmin(loggedUser) && !isLoggedUser(user, loggedUser)}
                      fullWidth
                      required
                      validate={value =>
                        prop('email', user) === value
                          ? undefined
                          : composeValidators(
                              required(<FormattedMessage {...messages.requiredEmail} />),
                              emailFormat(<FormattedMessage {...messages.invalidEmail} />),
                              checkEmailUniqueness(apolloClient)(<FormattedMessage {...messages.emailTaken} />),
                            )(value)
                      }
                      component={TextField}
                      label={<FormattedMessage {...messages.email} />}
                      name="email"
                      data-testid="user.edit.input.email"
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.field}>
                    <Field
                      readOnly={!isAdmin(loggedUser)}
                      fullWidth
                      required
                      component={MultiSelectField}
                      validate={composeValidators(
                        required(<FormattedMessage {...messages.requiredRoles} />),
                        requiredSelect(<FormattedMessage {...messages.requiredRoles} />),
                      )}
                      label={<FormattedMessage {...messages.roles} />}
                      name="roles"
                      data-testid="user.edit.input.roles"
                      renderValue={selected => (
                        <Grid className={classes.chips}>
                          {selected.map(value => (
                            <Chip
                              disabled={!isAdmin(loggedUser)}
                              onMouseDown={event => {
                                event.stopPropagation();
                              }}
                              onDelete={() =>
                                form.change(
                                  'roles',
                                  reject(v => v === value, values.roles),
                                )
                              }
                              key={value}
                              label={<FormattedMessage {...messages.role[value]} />}
                            />
                          ))}
                        </Grid>
                      )}
                    >
                      {map(role => (
                        <MenuItem key={role} value={role}>
                          <FormattedMessage {...messages.role[role]} />
                        </MenuItem>
                      ))(rvalues(ROLE))}
                    </Field>
                  </Grid>
                  <Grid item xs={12} className={classes.field}>
                    <Field
                      readOnly={!isAdmin(loggedUser)}
                      fullWidth
                      component={MultiSelectField}
                      label={<FormattedMessage {...messages.rights} />}
                      name="rights"
                      data-testid="user.edit.input.rights"
                      renderValue={selected => (
                        <Grid className={classes.chips}>
                          {selected.map(value => (
                            <Chip
                              disabled={!isAdmin(loggedUser)}
                              onMouseDown={event => {
                                event.stopPropagation();
                              }}
                              onDelete={() =>
                                form.change(
                                  'rights',
                                  reject(v => v === value, values.rights),
                                )
                              }
                              key={value}
                              label={<FormattedMessage {...messages.right[value]} />}
                            />
                          ))}
                        </Grid>
                      )}
                    >
                      {map(right => (
                        <MenuItem key={right} value={right}>
                          <FormattedMessage {...messages.right[right]} />
                        </MenuItem>
                      ))(rvalues(RIGHT))}
                    </Field>
                  </Grid>
                  <Grid item xs={12} className={classes.field}>
                    <Field
                      readOnly={!isAdmin(loggedUser)}
                      fullWidth
                      component={SelectField}
                      name="status"
                      label={<FormattedMessage {...messages.statusLabel} />}
                      disabled={!isAdmin(loggedUser)}
                      data-testid="user.edit.input.status"
                    >
                      {map(status => (
                        <MenuItem key={status} value={status}>
                          <FormattedMessage {...messages.status[status]} />
                        </MenuItem>
                      ))(rvalues(STATUS))}
                    </Field>
                  </Grid>
                  <Grid item xs={12} className={classes.teamsContainer}>
                    <UserTeams user={user} loggedUser={loggedUser} />
                  </Grid>
                  {isAdmin(loggedUser) || isLoggedUser(user, loggedUser) ? (
                    <Grid
                      item
                      xs={12}
                      container
                      className={classes.buttonContainer}
                      alignItems="center"
                      justify="space-around"
                    >
                      <Grid item xs={12} sm={5}>
                        <CancelButton className={classes.button} />
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <ConfirmButton
                          className={classes.button}
                          data-testid="user.edit.submit"
                          disabled={invalid || pristine}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <GoBackButton className={classes.button} />
                    </Grid>
                  )}
                </Grid>
              </form>
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default UserEdit;
