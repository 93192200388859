import React, { useEffect } from 'react';
import { Button, Grid, Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useHistory } from 'react-router-dom';
import LockIcon from '@material-ui/icons/Lock';
import TextField from '../../forms/TextField';
import { useIntl, FormattedMessage } from 'react-intl';
import { required, composeValidators, minLength, passwordConfirmation } from '../../forms/utils';
import useRoutes from '../../hooks/routes';
import api from '../../../lib/api';
import messages from './messages';
import useNotify from '../../hooks/notification';
import useAuth from '../../hooks/auth';
import LoginWithGoogle from '../LoginWithGoogle';
// import LoginWithFacebook from '../LoginWithFacebook';

const useSyles = makeStyles(theme => ({
  button: {
    textAlign: 'center',
    width: '100%',
  },
  googleButton: {
    width: '100%',
    border: 'none',
    outline: 'none',
  },
  textError: {
    color: '#ea4554',
  },
  textField: {
    width: '100%',
  },
  container: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  loginIcon: {
    marginTop: theme.spacing(2),
    width: '3em',
    height: 'auto',
  },
  socialMediaText: { fontSize: theme.spacing(2) },
}));

export const ConfirmAccount = () => {
  const classes = useSyles();
  const location = useLocation();
  const history = useHistory();
  const routes = useRoutes();
  const notify = useNotify();
  const intl = useIntl();
  const { logout } = useAuth();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('id');

  api.config({ getBearer: () => token });
  useEffect(() => {
    logout();
  }, [logout]);
  const onSubmit = ({ password }) => {
    api.auth
      .confirmAccount(password)
      .then(() => notify.success({ title: intl.formatMessage(messages.accountConfirmed) }))
      .catch(() => notify.error({ title: intl.formatMessage(messages.error) }));
    history.push(routes.getDefaultPath());
  };
  const responseGoogle = res => {
    if (!res.error) {
      api.auth
        .confirmAccountWithGoogle(res.tokenId)
        .then(() => {
          notify.success({ title: intl.formatMessage(messages.accountConfirmed) });
          history.push(routes.getDefaultPath());
        })
        .catch(() => notify.error({ title: intl.formatMessage(messages.error) }));
    } else {
      notify.error({ title: intl.formatMessage(messages.error) });
    }
  };
  // const responseFacebook = res => {
  //   api.auth
  //     .confirmAccountWithFacebook(res)
  //     .then(() => {
  //       notify.success({ title: intl.formatMessage(messages.accountConfirmed) });
  //       history.push(routes.getDefaultPath());
  //     })
  //     .catch(() => {
  //       notify.error({ title: intl.formatMessage(messages.error) });
  //     });
  // };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, pristine, invalid }) => (
        <form onSubmit={handleSubmit}>
          <Grid container className={classes.container} justify="center">
            <Grid item xs={12} md={4}>
              <Card>
                <Grid item container direction="column" alignItems="center">
                  <LockIcon color="secondary" className={classes.loginIcon} />
                  <CardHeader title={<FormattedMessage {...messages.title} />} />
                </Grid>
                <CardContent>
                  <Grid container direction="column" justify="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} md={10} style={{ width: '100%' }}>
                      <LoginWithGoogle
                        responseGoogle={responseGoogle}
                        buttonText={<FormattedMessage {...messages.confirmWithGoogle} />}
                      />
                    </Grid>
                    {/* <Grid item xs={12} md={10} style={{ width: '100%' }}>
                      <LoginWithFacebook
                        responseFacebook={responseFacebook}
                        buttonText={<FormattedMessage {...messages.confirmWithFacebook} />}
                      />
                    </Grid> */}
                    <Grid item xs={12} md={10}>
                      <Typography className={classes.socialMediaText}>
                        <FormattedMessage {...messages.or} />
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={10} style={{ width: '100%' }}>
                      <Field
                        validate={composeValidators(
                          required(<FormattedMessage {...messages.required} />),
                          minLength(<FormattedMessage {...messages.tooShort} />, 8),
                        )}
                        className={classes.textField}
                        component={TextField}
                        label={<FormattedMessage {...messages.password} />}
                        name="password"
                        type="password"
                      />
                    </Grid>
                    <Grid item xs={12} md={10} style={{ width: '100%' }}>
                      <Field
                        validate={composeValidators(
                          required(<FormattedMessage {...messages.required} />),
                          passwordConfirmation(<FormattedMessage {...messages.shouldBeTheSame} />, 8),
                        )}
                        className={classes.textField}
                        component={TextField}
                        label={<FormattedMessage {...messages.confirmPassword} />}
                        name="ConfirmPassword"
                        type="password"
                      />
                    </Grid>
                    <Grid item xs={12} md={10} style={{ width: '100%' }}>
                      <Button
                        type="submit"
                        disabled={pristine || invalid}
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        <FormattedMessage {...messages.set} />
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default ConfirmAccount;
