import { defineMessages } from 'react-intl';

export default defineMessages({
  accountConfirmed: {
    id: 'components.ConfirmAccount.accountConfirmed',
    defaultMessage: 'Account confirmed',
  },
  error: {
    id: 'components.ConfirmAccount.error',
    defaultMessage: 'Something went wrong',
  },
  set: {
    id: 'components.ConfirmAccount.set',
    defaultMessage: 'Set your password',
  },
  confirmWithGoogle: {
    id: 'components.ConfirmAccount.confirmWithGoogle',
    defaultMessage: 'Confirm With Google',
  },
  confirmWithFacebook: {
    id: 'components.ConfirmAccount.confirmWithFacebook',
    defaultMessage: 'Confirm With Facebook',
  },
  title: {
    id: 'components.ConfirmAccount.title',
    defaultMessage: 'Confirm your account',
  },
  or: {
    id: 'components.ConfirmAccount.or',
    defaultMessage: '- OR by setting a password -',
  },
  password: {
    id: 'components.ConfirmAccount.password',
    defaultMessage: 'Password',
  },
  confirmPassword: {
    id: 'components.ConfirmAccount.confirmPassword',
    defaultMessage: 'Confirm Password',
  },
  required: {
    id: 'components.ConfirmAccount.required',
    defaultMessage: 'Required',
  },
  tooShort: {
    id: 'components.ConfirmAccount.tooShort',
    defaultMessage: 'Too Short',
  },
  shouldBeTheSame: {
    id: 'components.ConfirmAccount.shouldBeTheSame',
    defaultMessage: 'Passwords should be the same',
  },
});
