import { path, split, compose, toPairs, forEach } from 'ramda';
import auth from './auth';

let globalConfig = { debug: true };
const endPoint = (path, config = globalConfig) => (config.endpoint ? `${config.endpoint}${path}` : path);
const bearer = () => ({ Authorization: `Bearer ${globalConfig.getBearer()}` });

/* eslint-disable-line no-shadow */
const config = config => (globalConfig = { ...globalConfig, ...config });

const methods = {
  auth,
};

const error = method => () => {
  throw new Error(`Unkown method: ${method}`);
};

const getFn = (main, name) => path(split(':', name), main);
const main = ({ method, ...rest }) => (getFn(main, method) || error(method))(rest);
main.config = config;

compose(
  forEach(([name, fn]) => (main[name] = fn({ bearer, endPoint }))),
  toPairs,
)(methods);

export default main;
