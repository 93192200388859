import React from 'react';
import { bool, string } from 'prop-types';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import Nav from '../Nav';

const NavTitle = ({ message, loading }) => {
  return (
    <Nav>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Typography>{message}</Typography>
        </Grid>
        <Grid item>{loading && <CircularProgress size="2em" color="secondary" />}</Grid>
      </Grid>
    </Nav>
  );
};

NavTitle.propTypes = {
  loading: bool,
  message: string,
};

export default NavTitle;
