import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import messages from './messages';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import useAuth from '../../hooks/auth';
import { Link } from 'react-router-dom';
import useRoutes from '../../hooks/routes';
import { useLoggedUser } from '../../hooks/user';
import { prop } from 'ramda';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(() => ({}));

export const UserCard = ({ handleClose, user }) => {
  const classes = useStyles();
  const routes = useRoutes();
  const [loggedUser] = useLoggedUser();
  const { logout } = useAuth();

  return (
    <Card className={classes.card}>
      <CardHeader title={`${user.firstname} ${user.lastname}`} subheader={user.email} />
      <CardActions>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              component={Link}
              to={routes.getPathByName('userEdit', prop('id', loggedUser))}
              className={classes.button}
              onClick={() => {
                handleClose();
              }}
              size="small"
              color="secondary"
              startIcon={<PersonIcon />}
            >
              <FormattedMessage {...messages.profile} />
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              className={classes.button}
              size="small"
              startIcon={<ExitToAppIcon />}
              onClick={() => {
                handleClose();
                logout();
              }}
            >
              <FormattedMessage {...messages.disconnect} />
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

UserCard.propTypes = {
  user: PropTypes.object,
  setDialog: PropTypes.func,
  handleClose: PropTypes.func,
};

export default UserCard;
