import { allPass, map, toPairs, compose, find, filter, prop } from 'ramda';
import ResetPassword from './components/ResetPassword';
import ResetAlmostThere from './components/ResetAlmostThere';
import ConfirmAccount from './components/ConfirmAccount';
import RequestNewPassword from './components/RequestNewPassword';
import TeamList from './components/Teams/List';
import TeamCreate from './components/Teams/Create';
import TeamEdit from './components/Teams/Edit';
import SpareDayCreate from './components/SpareDays/Create';
import SpareDayEdit from './components/SpareDays/Edit';
import SpareDays from './components/SpareDays';
import CreateEvent from './components/CreateEvent';
import UserList from './components/Users/List';
import UserEdit from './components/Users/Edit';
import UserCreate from './components/Users/Create';
import {
  userRequired,
  hasSpareDaysRight,
  isAdmin,
  hasTeamsCreateRightOrManager,
  isAdminOrManagerInTeam,
} from './roles';
import Calendars from './components/Calendars';
import Forbidden from './components/Forbidden';
import Register from './components/Register';
import RegisterAlmostThere from './components/RegisterAlmostThere';
import Amendments from './components/Amendments';
import AmendmentCreate from './components/Amendments/Create';
import AmendmentEdit from './components/Amendments/Edit';

const routes = {
  resetPassword: {
    path: '/resetPassword',
    component: ResetPassword,
  },
  requestNewPassword: {
    path: '/requestNewPassword',
    component: RequestNewPassword,
  },
  resetAlmostThere: {
    path: '/resetAlmostThere',
    component: ResetAlmostThere,
  },
  registerAlmostThere: {
    path: '/registerAlmostThere',
    component: RegisterAlmostThere,
  },
  confirmAccount: {
    path: '/confirmAccount',
    component: ConfirmAccount,
  },
  signUp: {
    path: '/register',
    component: Register,
  },
  calendar: {
    path: '/calendars/:id',
    component: Calendars,
    isAuthorized: [userRequired],
  },
  calendars: {
    path: '/calendars',
    component: Calendars,
    default: true,
    isAuthorized: [userRequired],
  },
  createEvent: {
    path: '/createEvent',
    component: CreateEvent,
    isAuthorized: [userRequired],
  },
  updateEvent: {
    path: '/updateEvent',
    component: CreateEvent,
    isAuthorized: [userRequired],
  },
  teamList: {
    path: '/teams',
    component: TeamList,
    isAuthorized: [userRequired, hasTeamsCreateRightOrManager],
    exact: true,
  },
  teamCreate: {
    path: '/teams/create',
    component: TeamCreate,
    isAuthorized: [userRequired, hasTeamsCreateRightOrManager],
  },
  teamEdit: {
    path: '/teams/edit/:id',
    component: TeamEdit,
    isAuthorized: [userRequired, hasTeamsCreateRightOrManager],
  },
  spareDays: {
    path: '/sparedays',
    component: SpareDays,
    isAuthorized: [userRequired, hasSpareDaysRight],
  },
  spareDayCreate: {
    path: '/sparedays/create',
    component: SpareDayCreate,
    isAuthorized: [userRequired, hasSpareDaysRight],
  },
  spareDayEdit: {
    path: '/sparedays/:id',
    component: SpareDayEdit,
    isAuthorized: [userRequired, hasSpareDaysRight],
  },
  userList: {
    path: '/users',
    component: UserList,
    exact: true,
    isAuthorized: [userRequired, isAdminOrManagerInTeam],
  },
  userEdit: {
    path: '/users/edit/:id',
    component: UserEdit,
    isAuthorized: [userRequired],
  },
  userCreate: {
    path: '/users/create',
    component: UserCreate,
    isAuthorized: [userRequired, isAdmin],
  },
  amendments: {
    path: '/amendments',
    component: Amendments,
    isAuthorized: [isAdminOrManagerInTeam],
  },
  amendmentEdit: {
    path: '/amendments/edit/:id',
    component: AmendmentEdit,
    isAuthorized: [isAdminOrManagerInTeam],
  },
  amendmentCreate: {
    path: '/amendments/create',
    component: AmendmentCreate,
    isAuthorized: [isAdminOrManagerInTeam],
  },
  forbidden: {
    path: '/forbidden',
    component: Forbidden,
    isAuthorized: [userRequired],
  },
};
const alwaysTrue = () => true;
const exportedRoutes = compose(
  map(([name, route]) => {
    if (!route.isAuthorized) route.isAuthorized = alwaysTrue;
    else route.isAuthorized = allPass(route.isAuthorized);
    route.name = name;
    return route;
  }),
  toPairs,
  filter(prop('path')),
)(routes);

export const getDefaultRoute = () => find(route => route.default, exportedRoutes);
export const getDefaultPath = () => getDefaultRoute()?.path;
export const getRouteByName = name => routes[name];
export const getRouteByPath = path => find(r => r.path === path, exportedRoutes);
export const getPathByName = (name, param) => {
  const path = prop('path', getRouteByName(name));
  return param ? `${path.replace(':id', param)}` : path;
};

const ROUTES = {
  ...routes,
  all: exportedRoutes,
  getDefaultRoute,
  getDefaultPath,
  getRouteByPath,
  getPathByName,
};

export default ROUTES;
