import { propOr, difference, isEmpty, equals, prop, or, any, find } from 'ramda';
import { isManagerOrheadWorker } from '../utils';
export const ROLE = {
  admin: 'admin',
  user: 'user',
};
export const RIGHT = {
  spareDays: 'spareDays',
  teamsCreate: 'teamsCreate',
};

export const plays = (user, roles = []) => isEmpty(difference(roles, propOr([], 'roles', user)));
export const hasRight = (user, rights = []) => isEmpty(difference(rights, propOr([], 'rights', user)));
export const userRequired = user => !!user;
export const isAdmin = user => plays(user, [ROLE.admin]);
export const hasSpareDaysRight = user => isAdmin(user) || hasRight(user, [RIGHT.spareDays]);

export const atLeastManager = user => {
  return user && any(team => isManagerOrheadWorker(user, team), propOr([], 'teams', user));
};

export const hasTeamsCreateRightOrManager = user =>
  isAdmin(user) || hasRight(user, [RIGHT.teamsCreate]) || atLeastManager(user);
export const isLoggedUser = (user, loggedUser) => equals(prop('id', user), prop('id', loggedUser));
export const isAdminOrLoggedUser = (user, loggedUser) => or(isAdmin(loggedUser), isLoggedUser(user, loggedUser));
export const isAdminOrManagerInTeam = user => isAdmin(user) || atLeastManager(user);

export const isManagerOfUser = (loggedUser, user) => {
  return (
    loggedUser &&
    any(
      team =>
        isManagerOrheadWorker(loggedUser, team) && !!find(member => member?.person?.id === user.id, team?.members),
      propOr([], 'teams', user),
    )
  );
};

export const workTogether = (loggedUser, user) =>
  any(team => !!find(member => member?.person?.id === user?.id, team?.members), propOr([], 'teams', loggedUser));

export const isMember = (team, user) => !!find(member => member?.person?.id === user?.id, team?.members);
