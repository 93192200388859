import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Notification = ({ notification, setNotification }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!!notification);
  }, [notification]);

  const handleRequestClose = () => {
    setOpen(false);
    setNotification(null);
  };

  if (!open) return null;

  return (
    <Snackbar
      data-testid={`notification.${notification.level}`}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleRequestClose}
    >
      <Alert onClose={handleRequestClose} severity={notification.level}>
        {notification.title}
      </Alert>
    </Snackbar>
  );
};

export default Notification;

Notification.propTypes = {
  notification: PropTypes.object,
  setNotification: PropTypes.func,
};
