import { defineMessages } from 'react-intl';

export default defineMessages({
  confirm: {
    id: 'components.AlertDialog.confirm',
    defaultMessage: 'Confirm',
  },
  cancel: {
    id: 'components.AlertDialog.cancel',
    defaultMessage: 'Cancel',
  },
});
