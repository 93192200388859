import React from 'react';
import PropTypes from 'prop-types';
import { Drawer, Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import DrawerMenu from '../DrawerMenu';
import Content from '../Content';
import useRoutes from '../../hooks/routes';
import { useLoggedUser } from '../../hooks/user';
import { getDrawerLinksList } from './utils';
import { hasSpareDaysRight, hasTeamsCreateRightOrManager } from '../../roles';

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: theme.spacing(8),
  },

  drawerOpen: {
    width: drawerWidth,
    marginTop: theme.spacing(8),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    marginTop: theme.spacing(8),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(8),
  },
}));

const Layout = ({ user, children, miniDrawerOpen, handleDrawerToggle }) => {
  const classes = useStyles();
  const routes = useRoutes();
  const [loggedUser] = useLoggedUser();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const drawerLinksList = getDrawerLinksList(routes);

  return (
    <Grid className={classes.root}>
      {(hasTeamsCreateRightOrManager(loggedUser) || hasSpareDaysRight(user)) && user && (
        <Drawer
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: miniDrawerOpen,
            [classes.drawerClose]: !miniDrawerOpen,
          })}
          classes={
            isSmallScreen
              ? {
                  paper: classes.drawerPaper,
                }
              : {
                  paper: classNames({
                    [classes.drawerOpen]: miniDrawerOpen,
                    [classes.drawerClose]: !miniDrawerOpen,
                  }),
                }
          }
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          variant={isSmallScreen ? 'temporary' : 'permanent'}
          open={miniDrawerOpen}
          onClose={isSmallScreen ? handleDrawerToggle : null}
        >
          <DrawerMenu user={user} drawerLinksList={drawerLinksList} miniDrawerOpen={miniDrawerOpen} />
        </Drawer>
      )}
      <Content>{children} </Content>
    </Grid>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object,
  miniDrawerOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func,
};

export default Layout;
