import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export const GET_TEAMS = gql`
  query CalendarTeams($orderBy: String) {
    items: allTeams(orderBy: $orderBy) @connection(key: "CalendarsTeams") {
      id
      name
      description
      country
      type
      members {
        person {
          id
          firstname
          lastname
          fullname
          email
        }
        role
        activityPeriods {
          inceptionDate
          expirationDate
        }
      }
    }
  }
`;
export const useTeams = (params, options) => {
  const res = useQuery(GET_TEAMS, {
    ...options,
    fetchPolicy: 'cache-and-network',
    variables: params,
  });
  return [res.data?.items || [], res.loading, res.error];
};
