import React, {  useState } from 'react';
import { element } from 'prop-types';
import {moveCurrentMonth, movePreviousMonth, moveNextMonth} from './utils';

export const CurrentMonthContext = React.createContext();

const CurrentMonthProvider = ({ children }) => {
  const [month, setMonth] = useState(moveCurrentMonth());
  const ret = () => month;
  ret.next = () => setMonth(moveNextMonth(month));
  ret.previous = () => setMonth(movePreviousMonth(month));
  ret.now = () => setMonth(moveCurrentMonth());

  return <CurrentMonthContext.Provider value={ret}>{React.Children.only(children)}</CurrentMonthContext.Provider>;
};

CurrentMonthProvider.propTypes = {
  children: element.isRequired,
};

export default CurrentMonthProvider;
