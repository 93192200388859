import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Button, MenuItem, Typography, IconButton } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import { map, prop, propEq, find, append, filter } from 'ramda';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import {
  format,
  addHours,
  subHours,
  addMilliseconds,
  isEqual,
  isAfter,
  differenceInHours,
  isBefore,
  startOfMonth,
  endOfMonth,
  getHours,
} from 'date-fns';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { required } from '../../forms/utils';
import messages from './messages';
import SelectField from '../../forms/Select';
import { TYPE, timezone, employeeEventsTypes, nonEmployeeEventsTypes } from '../../../lib/models/events';
import Timeline from '../Timeline';
import { UserAvatar, MEDIUM } from '../Avatar';
import HtmlEditor from '../../forms/HtmlEditor';
import useNotify from '../../hooks/notification';
import useLoading from '../../hooks/loading';
import Nav from '../Nav';
import { useCreateEvent, useUpdateEvent, useDeleteEvent } from './hooks';
import { getEventWorkingDaysNumber, isDayOff, PM, AM, isActiveDay, getMaxDate, getMinDate } from '../Timeline/utils';
import { convertEventToUTC } from '../Timeline/utils';
import { htmlToEditorState } from '../Teams/utils';
import { isCompany } from '../../../lib/models/teams';
import { isAdmin, isMember } from '../../roles';
import { isManagerOrheadWorker } from '../../utils';
import { useLoggedUser } from '../../hooks/user';
import useRoutes from '../../hooks/routes';

const useSyles = makeStyles(theme => ({
  button: {
    fontSize: '0.9em',
  },
  textField: {
    width: '100%',
  },
  container: {
    marginTop: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  headerElement: {
    marginRight: theme.spacing(1),
  },
  text: {
    backgroundColor: '#FFF',
    width: '100%',
    border: '1px solid #F1F1F1',
    borderTop: '0px',
    padding: theme.spacing(1),
  },
  wrapper: {
    width: '100%',
    margin: '0',
    '& .rdw-editor-toolbar ': {
      marginBottom: '0px',
    },
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
}));

const CreateEvent = () => {
  const classes = useSyles();
  const location = useLocation();
  const history = useHistory();
  const routes = useRoutes();
  const notify = useNotify();
  const intl = useIntl();
  const setLoading = useLoading();
  const { person, month, events, spareDays, team, update, event, user } = location.state;
  const [loggedUser, loadingLoggedUser] = useLoggedUser();

  const onlySelectedUserTeam = {
    ...team,
    members: filter(member => member.person.id === person.id)(team.members),
  };

  const [addEvent, { loading }] = useCreateEvent({
    onCompleted: () => {
      notify.success({
        title: intl.formatMessage(messages.eventCreated),
      });
    },
    onError: error => notify.error({ title: `${intl.formatMessage(messages.eventNotCreated)} : ${error}` }),
  });

  const [updateEvent, { updateLoading }] = useUpdateEvent({
    onCompleted: () => {
      notify.success({
        title: intl.formatMessage(messages.eventUpdated),
      });
    },
    onError: error => notify.error({ title: `${intl.formatMessage(messages.eventNotUpdated)} : ${error}` }),
  });

  const [deleteEvent, { deleteLoading }] = useDeleteEvent({
    onCompleted: () => {
      notify.success({
        title: intl.formatMessage(messages.eventDeleted),
      });
    },
    onError: error => notify.error({ title: `${intl.formatMessage(messages.eventNotDeleted)} : ${error}` }),
  });

  const newEvents = update ? events : append(event)(events);

  const onSubmit = values => {
    values.description = values.editorState ? draftToHtml(convertToRaw(values.editorState.getCurrentContent())) : null;
    update
      ? updateEvent({
          variables: {
            id: prop('id', event),
            from: convertEventToUTC(prop('from', getEvent(event)), timezone),
            to: convertEventToUTC(prop('to', getEvent(event)), timezone),
            description: values.description,
            type: values.type,
            teamId: prop('id', team),
          },
        })
      : addEvent({
          variables: {
            from: convertEventToUTC(prop('from', getEvent(event)), timezone),
            to: convertEventToUTC(prop('to', getEvent(event)), timezone),
            personId: person.id,
            teamId: prop('id', team),
            description: values.description,
            type: values.type,
          },
        });
    history.goBack();
  };

  const [eventsState, setEventsState] = useState(newEvents);

  const getEvent = event => find(propEq('id', prop('id', event)))(eventsState);

  const getRightNextAvailableDate = (spareDays, events) => {
    const maxDate = getMaxDate(events, event.to) ? getMaxDate(events, event.to) : addMilliseconds(endOfMonth(month), 1);
    const eventHalfDay = subHours(addMilliseconds(prop('to', getEvent(event)), 1), 12);
    let next = addHours(eventHalfDay, 12);
    if (!isDayOff(next, spareDays) && !isEqual(next, maxDate) && isActiveDay(person.id, team.members, next))
      return next;
    while (
      (isDayOff(next, spareDays) || !isActiveDay(person.id, team.members, next)) &&
      !isEqual(next, maxDate) &&
      !isAfter(next, maxDate)
    ) {
      next = addHours(next, 12);
    }
    return isEqual(next, maxDate) || isAfter(next, maxDate) ? undefined : next;
  };

  const getLeftNextAvailableDate = (spareDays, events) => {
    const minDate = getMinDate(events, event.from)
      ? subHours(addMilliseconds(getMinDate(events, event.from), 1), 12)
      : subHours(startOfMonth(month), 12);
    const eventHalfDay = prop('from', getEvent(event));
    let next = subHours(eventHalfDay, 12);
    if (!isDayOff(next, spareDays) && !isEqual(next, minDate) && isActiveDay(person.id, team.members, next))
      return next;
    while (
      (isDayOff(next, spareDays) || !isActiveDay(person.id, team.members, next)) &&
      !isEqual(next, minDate) &&
      !isBefore(next, minDate)
    ) {
      next = subHours(next, 12);
    }
    return isEqual(next, minDate) || isBefore(next, minDate) ? undefined : next;
  };

  const getRightPreviousAvailableDate = spareDays => {
    const eventHalfDay = subHours(addMilliseconds(prop('to', getEvent(event)), 1), 12);
    let previous = subHours(eventHalfDay, 12);
    if (!isDayOff(previous, spareDays)) return previous;
    while (isDayOff(previous, spareDays)) {
      previous = subHours(previous, 12);
    }
    return previous;
  };

  const getLeftPreviousAvailableDate = spareDays => {
    const eventHalfDay = prop('from', getEvent(event));
    let previous = addHours(eventHalfDay, 12);
    if (!isDayOff(previous, spareDays)) return previous;
    while (isDayOff(previous, spareDays)) {
      previous = addHours(previous, 12);
    }
    return previous;
  };

  const workingDays = getEventWorkingDaysNumber(getEvent(event), spareDays, person.id, team.members);

  const descriptionToEditorState = htmlToEditorState(prop('description', event));

  useEffect(() => {
    setLoading(loading || updateLoading || loadingLoggedUser || deleteLoading);
  }, [loading, updateLoading, deleteLoading, loadingLoggedUser, setLoading]);

  useEffect(() => {
    if (!isAdmin(loggedUser) && !isMember(team, loggedUser)) history.replace(routes.getDefaultPath());
  }, []); // eslint-disable-line

  return (
    <>
      <Nav>
        <Typography>
          {update ? <FormattedMessage {...messages.updateEvent} /> : <FormattedMessage {...messages.newEvent} />}
        </Typography>
      </Nav>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, pristine, invalid }) => (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={2}
              className={classes.container}
            >
              <Grid item>
                <Typography>{prop('name', team)}</Typography>
              </Grid>
              <Grid item>
                <Typography>{person.fullname}</Typography>
              </Grid>
              <Grid item style={{ width: '100%' }}>
                <Timeline
                  month={month}
                  spareDays={spareDays}
                  resourceAvatar={user => <UserAvatar user={user} showTooltip size={MEDIUM} />}
                  events={eventsState}
                  disabled
                  team={onlySelectedUserTeam}
                  updateEventId={event.id}
                />
              </Grid>
              <Grid item container alignItems="center" justify="center" spacing={1}>
                <Grid item>
                  <IconButton
                    disabled={!getLeftNextAvailableDate(spareDays, newEvents)}
                    onClick={() => {
                      const updatedEvents = map(stateEvent =>
                        event.id === stateEvent.id
                          ? {
                              ...stateEvent,
                              from: subHours(
                                stateEvent.from,
                                differenceInHours(
                                  prop('from', getEvent(event)),
                                  getLeftNextAvailableDate(spareDays, newEvents),
                                ),
                              ),
                            }
                          : stateEvent,
                      )(eventsState);
                      setEventsState(updatedEvents);
                    }}
                  >
                    <AddCircleIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={workingDays === 0.5}
                    onClick={() => {
                      const updatedEvents = map(stateEvent =>
                        event.id === stateEvent.id
                          ? {
                              ...stateEvent,
                              from: addHours(
                                stateEvent.from,
                                differenceInHours(
                                  getLeftPreviousAvailableDate(spareDays, newEvents),
                                  prop('from', getEvent(event)),
                                ),
                              ),
                            }
                          : stateEvent,
                      )(eventsState);
                      setEventsState(updatedEvents);
                    }}
                  >
                    <RemoveCircleIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography variant="h5">{`${format(prop('from', getEvent(event)), "EEEE do 'of' MMMM")} ${
                    getHours(prop('from', getEvent(event))) === 12 ? PM : AM
                  } -> ${format(prop('to', getEvent(event)), "EEEE do 'of' MMMM")} ${
                    getHours(subHours(addMilliseconds(prop('to', getEvent(event)), 1), 12)) === 12 ? PM : AM
                  }`}</Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={workingDays === 0.5}
                    onClick={() => {
                      const updatedEvents = map(stateEvent =>
                        event.id === stateEvent.id
                          ? {
                              ...stateEvent,
                              to: subHours(
                                stateEvent.to,
                                differenceInHours(
                                  subHours(addMilliseconds(prop('to', getEvent(event)), 1), 12),
                                  getRightPreviousAvailableDate(spareDays),
                                ),
                              ),
                            }
                          : stateEvent,
                      )(eventsState);
                      setEventsState(updatedEvents);
                    }}
                  >
                    <RemoveCircleIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={!getRightNextAvailableDate(spareDays, newEvents)}
                    onClick={() => {
                      const updatedEvents = map(stateEvent =>
                        event.id === stateEvent.id
                          ? {
                              ...stateEvent,
                              to: addHours(
                                stateEvent.to,
                                differenceInHours(
                                  getRightNextAvailableDate(spareDays, newEvents),
                                  subHours(addMilliseconds(prop('to', getEvent(event)), 1), 12),
                                ),
                              ),
                            }
                          : stateEvent,
                      )(eventsState);
                      setEventsState(updatedEvents);
                    }}
                  >
                    <AddCircleIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="h4">
                  {workingDays}{' '}
                  <FormattedMessage
                    values={{ workingDays: workingDays === 0.5 ? 1 : workingDays }}
                    {...messages.workingDays}
                  ></FormattedMessage>
                </Typography>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Field
                    validate={required(<FormattedMessage {...messages.required}></FormattedMessage>)}
                    name="type"
                    className={classes.textField}
                    component={SelectField}
                    label={<FormattedMessage {...messages.typeLabel}></FormattedMessage>}
                    defaultValue={
                      !propEq('id', 'CreateId')(event)
                        ? prop('type', event)
                        : isCompany(team)
                        ? TYPE.vacation
                        : TYPE.workingDay
                    }
                  >
                    {map(type => (
                      <MenuItem key={type} value={type}>
                        <Typography>
                          <FormattedMessage {...messages.type[type]}></FormattedMessage>
                        </Typography>
                      </MenuItem>
                    ))(isCompany(team) ? employeeEventsTypes : nonEmployeeEventsTypes)}
                  </Field>
                </Grid>
                <Grid item container direction="column" md={6} xs={12} wrap="nowrap">
                  <Grid item>
                    <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.38)', paddingLeft: '2px' }}>
                      <FormattedMessage {...messages.descriptionLabel}></FormattedMessage>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Field
                      name="editorState"
                      component={HtmlEditor}
                      initialValue={descriptionToEditorState}
                      wrapperClassName={classes.wrapper}
                      editorClassName={classes.text}
                      toolbarClassName={classes.toolbar}
                      placeholder={<FormattedMessage {...messages.descriptionPlaceholder}></FormattedMessage>}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={update ? 3 : 6}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.button}
                    color="secondary"
                    onClick={() => history.goBack()}
                    startIcon={<CancelIcon />}
                  >
                    <FormattedMessage {...messages.cancel} />
                  </Button>
                </Grid>
                {update && (
                  <Grid item xs={12} sm={3}>
                    <Button
                      disabled={
                        !(
                          isAdmin(user) ||
                          event.person.id === user.id ||
                          (event.team && isManagerOrheadWorker(user, team))
                        )
                      }
                      fullWidth
                      variant="contained"
                      className={classes.button}
                      startIcon={<DeleteIcon />}
                      onClick={() => {
                        deleteEvent({
                          variables: {
                            id: prop('id', event),
                          },
                        });
                        history.goBack();
                      }}
                    >
                      <FormattedMessage {...messages.delete} />
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    type="submit"
                    disabled={pristine || invalid}
                    variant="contained"
                    className={classes.button}
                    color="primary"
                    startIcon={<SaveIcon />}
                  >
                    {update ? <FormattedMessage {...messages.update} /> : <FormattedMessage {...messages.create} />}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </>
  );
};

export default CreateEvent;
