import React from 'react';
import { Button, Grid, Card, CardContent, Typography, CardHeader } from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import LoginWithGoogle from '../LoginWithGoogle';
import LockIcon from '@material-ui/icons/Lock';
import useAuth from '../../hooks/auth';
import TextField from '../../forms/TextField';
import useRoutes from '../../hooks/routes';
import { required, composeValidators, emailFormat } from '../../forms/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import useNotify from '../../hooks/notification';
// import LoginWithFacebook from '../LoginWithFacebook';

const useSyles = makeStyles(theme => ({
  container: {
    '@media screen and (max-height: 430px) and (orientation: landscape)': {
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'space-between',
    },
  },
  cardContentContainer: {
    '@media screen and (max-height: 430px) and (orientation: landscape)': {
      flex: 4,
    },
  },
  button: {
    width: '100%',
    '@media screen and (max-height: 430px) and (orientation: landscape)': {
      maxHeight: '33px',
    },
  },
  loginText: { fontSize: theme.spacing(2.5), textTransform: 'uppercase' },
  forgetButton: {
    marginBottom: theme.spacing(5),
  },
  text: { fontSize: theme.spacing(1.5), '&:hover': { textDecoration: 'underline' } },
  socialMediaText: { fontSize: theme.spacing(2), marginBottom: theme.spacing(2), marginTop: theme.spacing(3) },
  textField: {
    margin: theme.spacing(1),
    '@media screen and (max-height: 430px) and (orientation: landscape)': {
      margin: theme.spacing(0),
    },
    width: '100%',
  },
  loginIcon: {
    width: '3em',
    height: 'auto',
    '@media screen and (max-height: 430px) and (orientation: landscape)': {
      width: '2em',
      height: 'auto',
    },
  },
  logoContainer: {
    '@media screen and (max-height: 430px) and (orientation: landscape)': {
      flex: 1,
    },
  },
  pageContainer: {
    marginTop: theme.spacing(2),

    '@media screen and (max-height: 430px) and (orientation: landscape)': {
      marginTop: theme.spacing(0),
      padding: theme.spacing(0),
    },
    width: '100%',
  },
  cardContainer: {
    maxWidth: '450px',
  },
  card: {
    padding: theme.spacing(2),
    width: '100%',
    '@media screen and (max-height: 430px) and (orientation: landscape)': {
      padding: theme.spacing(0),
      maxHeight: '285px',
    },
  },
  cardContent: {
    width: '100%',
  },
  content: {
    '@media screen and (max-height: 430px) and (orientation: landscape)': {
      flexDirection: 'row',
    },
  },
}));

export const SignInForm = () => {
  const classes = useSyles();
  const routes = useRoutes();
  const { login, loginWithGoogle /*, loginWithFacebook*/ } = useAuth();
  const notify = useNotify();
  const intl = useIntl();
  const onSubmit = values => {
    login(values).catch(() =>
      notify.error({
        title: intl.formatMessage(messages.signInError),
      }),
    );
  };

  const responseGoogle = res => {
    if (!res.error) {
      loginWithGoogle({ token: res.tokenId }).catch(() => {
        notify.error({ title: intl.formatMessage(messages.signInError) });
      });
    } else {
      notify.error({ title: intl.formatMessage(messages.signInError) });
    }
  };
  // const responseFacebook = res => {
  //   loginWithFacebook(res).catch(() => {
  //     notify.error({ title: intl.formatMessage(messages.signInError) });
  //   });
  // };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, pristine, invalid }) => (
        <form onSubmit={handleSubmit}>
          <Grid container alignItems="center" justify="center" className={classes.pageContainer}>
            <Grid container justify="center" className={classes.cardContainer}>
              <Card className={classes.card}>
                <Grid container direction="column" alignItems="center" justify="center" className={classes.container}>
                  <Grid item container direction="column" alignItems="center" className={classes.logoContainer}>
                    <LockIcon color="secondary" className={classes.loginIcon} />
                    <CardHeader title={<FormattedMessage {...messages.login} />} />
                  </Grid>
                  <Grid container item className={classes.cardContentContainer}>
                    <CardContent className={classes.cardContent}>
                      <Grid container direction="column" alignItems="center" className={classes.content}>
                        <Grid item container direction="column" alignItems="center" spacing={2}>
                          <Grid item xs={12} md={10} style={{ width: '100%' }}>
                            <LoginWithGoogle
                              responseGoogle={responseGoogle}
                              buttonText={<FormattedMessage {...messages.signInWithGoogle} />}
                            />
                          </Grid>
                          {/* <Grid item xs={12} md={10} style={{ width: '100%' }}>
                            <LoginWithFacebook
                              responseFacebook={responseFacebook}
                              buttonText={<FormattedMessage {...messages.signInWithFacebook} />}
                            />
                          </Grid> */}
                          <Grid item>
                            <Typography>
                              <FormattedMessage {...messages.or} />
                            </Typography>
                          </Grid>
                        </Grid>
                        <Field
                          validate={composeValidators(
                            required(intl.formatMessage(messages.required)),
                            emailFormat(intl.formatMessage(messages.invalidEmail)),
                          )}
                          className={classes.textField}
                          component={TextField}
                          label={<FormattedMessage {...messages.email} />}
                          name="email"
                          type="email"
                        />

                        <Field
                          validate={composeValidators(required(intl.formatMessage(messages.required)))}
                          className={classes.textField}
                          component={TextField}
                          label={<FormattedMessage {...messages.password} />}
                          name="password"
                          type="password"
                        />
                        <Button
                          color="primary"
                          type="submit"
                          disabled={pristine || invalid}
                          variant="contained"
                          className={classes.button}
                        >
                          <Typography>
                            <FormattedMessage {...messages.login} />
                          </Typography>
                        </Button>

                        <Button component={Link} color="primary" to={routes.getPathByName('requestNewPassword')}>
                          <Typography className={classes.text}>
                            <FormattedMessage {...messages.forgetPassword} />
                          </Typography>
                        </Button>
                        <Button component={Link} color="primary" to={routes.getPathByName('signUp')}>
                          <Typography className={classes.text}>
                            <FormattedMessage {...messages.signUp} />
                          </Typography>
                        </Button>
                      </Grid>
                    </CardContent>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default SignInForm;
