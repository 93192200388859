import { defineMessages } from 'react-intl';

export default defineMessages({
  checkYourEmail: {
    id: 'components.RegisterAlmostThere.checkYourEmail',
    defaultMessage: 'Please check your emails to confirm your account',
  },
  checkSpamFolder: {
    id: 'components.RegisterAlmostThere.checkSpamFolder',
    defaultMessage: 'No confirmation email received? Please check your spam folder',
  },
  almostThere: {
    id: 'components.RegisterAlmostThere.almostThere',
    defaultMessage: 'Almost there ...',
  },
  signIn: {
    id: 'components.RegisterAlmostThere.signIn',
    defaultMessage: 'Sign in',
  },
  haveLogin: {
    id: 'components.RegisterAlmostThere.haveLogin',
    defaultMessage: 'Already have an account ? ',
  },
});
