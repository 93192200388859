import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import HttpsTwoToneIcon from '@material-ui/icons/HttpsTwoTone';
import History from '@material-ui/icons/History';
import { Typography, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import Nav from '../Nav';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      height: '100vh',
      marginTop: '-3em',
    },
  },
  icon: {
    width: '9em',
    height: '9em',
  },
  message: {
    textAlign: 'center',
    fontFamily: 'Roboto, sans-serif',
    opacity: 0.5,
    margin: '0 1em',
  },
  toolbar: {
    textAlign: 'center',
    marginTop: '2em',
  },
}));

const Forbidden = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid item container alignItems="center" justify="center">
      <Grid item>
        <Nav>
          <Typography>
            <FormattedMessage {...messages.nav} />
          </Typography>
        </Nav>
      </Grid>
      <Grid item className={classes.message}>
        <HttpsTwoToneIcon className={classes.icon} />
        <Typography variant="h4">
          <FormattedMessage {...messages.title} />
        </Typography>
        <Typography>
          <FormattedMessage {...messages.detail} />
        </Typography>
      </Grid>
      <Grid item className={classes.toolbar}>
        <Button variant="contained" icon={<History />} onClick={() => history.goBack()}>
          <FormattedMessage {...messages.goBack} />
        </Button>
      </Grid>
    </Grid>
  );
};

Forbidden.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  title: PropTypes.string,
  location: PropTypes.object,
};

export default Forbidden;
