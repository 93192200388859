import React from 'react';
import { GoogleLogin } from 'react-google-login';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, CardMedia } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  button: {
    width: '100%',
    border: 'none',
    outline: 'none',
  },
  logoGoogle: {
    marginRight: theme.spacing(1),
    marginLeft: '-24px',
    width: theme.spacing(4),
    height: 'auto',
  },
  googleBtn: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    height: '50px',
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.spacing(1),
    justifyContent: 'center',
    cursor: 'pointer',
    background: '#FFF',
    color: theme.palette.grey[800],
    '&:hover': {
      background: theme.palette.grey[200],
    },
    '&:focus': {
      outline: 'none',
    },
  },
}));

const LoginWithGoogle = ({ responseGoogle, buttonText }) => {
  const classes = useStyles();
  return (
    <GoogleLogin
      className={classes.button}
      clientId="223226395678-737dmg2e71b52hqr90nk7c9vtg7b40o5.apps.googleusercontent.com"
      render={renderProps => (
        <button
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
          variant="contained"
          color="primary"
          className={classes.googleBtn}
        >
          <CardMedia
            component="img"
            src="https://img.icons8.com/color/48/000000/google-logo.png"
            className={classes.logoGoogle}
          />
          <Typography className={classes.login}>{buttonText}</Typography>
        </button>
      )}
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
    />
  );
};
LoginWithGoogle.propTypes = {
  responseGoogle: PropTypes.func,
  buttonText: PropTypes.object,
};

export default LoginWithGoogle;
