import { useState } from 'react';

export const useMenu = () => {
  const [userMenuAnchor, useUserMenuAnchor] = useState(null);
  const openUserMenu = event => useUserMenuAnchor(event.currentTarget); // eslint-disable-line
  const closeUserMenu = () => useUserMenuAnchor(null); // eslint-disable-line

  return {
    userMenuAnchor,
    openUserMenu,
    closeUserMenu,
  };
};
