import React from 'react';
import { Button, Grid, Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useHistory } from 'react-router-dom';
import TextField from '../../forms/TextField';
import { required, composeValidators, minLength, passwordConfirmation } from '../../forms/utils';
import useRoutes from '../../hooks/routes';
import api from '../../../lib/api';
import { useIntl, FormattedMessage } from 'react-intl';
import messages from './messages';
import useNotify from '../../hooks/notification';

const useSyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    textAlign: 'center',
  },
  textError: {
    color: '#ea4554',
  },
  textField: {
    margin: theme.spacing(1),
    width: '100%',
  },
  container: {
    marginTop: theme.spacing(2),
  },
  card: {
    width: '100%',
  },
  cardContainer: {
    maxWidth: '450px',
  },
}));

export const ResetPassword = () => {
  const classes = useSyles();
  const location = useLocation();
  const history = useHistory();
  const routes = useRoutes();
  const notify = useNotify();
  const intl = useIntl();

  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('id');

  api.config({
    getBearer: () => token,
  });

  const onSubmit = ({ password }) => {
    api.auth
      .resetPassword(password)
      .then(
        notify.success({
          title: intl.formatMessage(messages.passwordUpdated),
        }),
      )
      .catch(() =>
        notify.error({
          title: intl.formatMessage(messages.error),
        }),
      );
    history.push(routes.getDefaultPath());
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, pristine, invalid }) => (
        <form onSubmit={handleSubmit}>
          <Grid container className={classes.container} justify="center">
            <Grid item xs={11} className={classes.cardContainer}>
              <Card className={classes.card}>
                <CardHeader title={intl.formatMessage(messages.resetPassword)} style={{ textAlign: 'center' }} />
                <CardContent>
                  <Grid container direction="column" justify="space-between" alignItems="center">
                    <Grid item xs={6}>
                      <Field
                        validate={composeValidators(required('Required'), minLength('Too Short', 8))}
                        className={classes.textField}
                        component={TextField}
                        label={intl.formatMessage(messages.password)}
                        name="password"
                        type="password"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        validate={composeValidators(
                          required('Required'),
                          passwordConfirmation('Passwords should be the same', 8),
                        )}
                        className={classes.textField}
                        component={TextField}
                        label={intl.formatMessage(messages.confirmPassword)}
                        name="confirmPassword"
                        type="password"
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        disabled={pristine || invalid}
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        <Typography>
                          <FormattedMessage {...messages.changePassword}></FormattedMessage>
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default ResetPassword;
