import { test, reduce } from 'ramda';
import { WORKING_DAYS_OF_PERIOD } from '../components/Amendments/hooks';
import { CHECK_TEAM_UNIQUENESS } from '../components/Teams/hooks';
import { CHECK_EMAIL_UNIQUENESS } from '../components/Users/hooks';

export const required = (message = 'required') => value => !value && message;
export const emailFormat = message => value => value && !test(/\S+@\S+\.\S+/, value) && message;
export const passwordConfirmation = message => (password, values) => password !== values.password && message;
export const minLength = (message, size) => value => value && value.length < size && message;
export const requiredSelect = message => value => value && !value[0] && message;
export const composeValidators = (...validators) => (value, values) =>
  reduce((error, validator) => error || validator(value, values), undefined)(validators);

export const checkEmailUniqueness = client => message => async (value = '') => {
  const variables = { email: value };
  const res = await client.query({ query: CHECK_EMAIL_UNIQUENESS, variables });
  return !res.data?.checkEmailUniqueness && message;
};

export const checkTeamNameUniqueness = client => message => async (value = '') => {
  const variables = { name: value };
  const res = await client.query({ query: CHECK_TEAM_UNIQUENESS, variables });
  return !res.data?.checkTeamUniqueness && message;
};

export const checkExpirationDate = activityPeriods => message => value =>
  activityPeriods && new Date(activityPeriods[activityPeriods.length - 1].expirationDate) > new Date(value) && message;

export const expirationSupInceptionDate = activityPeriods => message => value => {
  return (
    activityPeriods && new Date(activityPeriods[activityPeriods.length - 1].inceptionDate) > new Date(value) && message
  );
};

export const checkWorkingDaysMax = (client, values) => message => async value => {
  const { from, teamId, to } = values;

  const variables = { from, to, teamId };
  if (from && teamId && to) {
    const res = await client.query({ query: WORKING_DAYS_OF_PERIOD, variables });
    const workingDaysMax = res.data?.workingDaysOfPeriod.workingDays;
    return value && value > workingDaysMax && message.props.defaultMessage + workingDaysMax + ' days';
  }
  return false;
};

export const checkPositiveNumber = message => value => value < 0 && message;
