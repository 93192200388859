import { defineMessages } from 'react-intl';

export default defineMessages({
  cancel: {
    id: 'components.Buttons.cancel',
    defaultMessage: 'cancel',
  },
  goBack: {
    id: 'components.Buttons.goBack',
    defaultMessage: 'go back',
  },
  confirm: {
    id: 'components.Buttons.confirm',
    defaultMessage: 'save ',
  },
});
