import React, { useState, useEffect } from 'react';
import { both } from 'ramda';
import PropTypes from 'prop-types';
import { Badge, Paper } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { Field } from 'react-final-form';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';
import enLocale from 'date-fns/locale/en-US';
import { getSpareDay, getLocale } from '../../utils';
import { usePeriodSpareDays } from '../SpareDays/hooks';
import { mdy } from '../../utils';
import { makeStyles } from '@material-ui/core/styles';
import { convertSpareDays } from '../SpareDays/utils';
import { addMonths } from 'date-fns';

const useStyles = makeStyles(() => ({
  DatePickerPaper: {
    width: '100%',
  },
}));

const localeMap = {
  en: enLocale,
  fr: frLocale,
};

const DatePickerAdapter = ({ input: { onChange, value }, currentValue, loading, spareDays = [], ...rest }) => {
  const isSpareDay = getSpareDay(spareDays);
  const locale = getLocale();
  const isNotCurrent = current => x => {
    if (!current) return true;
    return mdy(x) !== mdy(new Date(current));
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
      <DatePicker
        disabled={loading}
        value={value}
        shouldDisableDate={both(isNotCurrent(currentValue), isSpareDay)}
        onChange={onChange}
        {...rest}
        variant="static"
        renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
          if (loading) return null;
          const spareDay = isSpareDay(day);
          return spareDay ? <Badge title={spareDay.label}>{dayComponent}</Badge> : dayComponent;
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

DatePickerAdapter.propTypes = {
  input: PropTypes.object,
  spareDays: PropTypes.array,
  loading: PropTypes.bool,
  currentValue: PropTypes.any,
};

const SpareDayDatePicker = ({ validate, currentCountry, currentTeam, loading, ...props }) => {
  const classes = useStyles();

  const [currentDate, setCurrentDate] = useState(new Date());
  const [spareDays, loadingCurrentMonth] = usePeriodSpareDays({
    from: currentDate,
    to: addMonths(new Date(currentDate), 1),
    country: currentCountry,
    teamId: currentTeam,
  });

  useEffect(() => {
    setCurrentDate(new Date());
  }, [currentCountry, currentTeam]);

  return (
    <span data-testid="spareday.datepicker">
      <Paper elevation={3} className={classes.DatePickerPaper}>
        <Field name="from" validate={validate} {...props}>
          {fprops => {
            return (
              <DatePickerAdapter
                {...fprops}
                currentValue={props.defaultValue}
                loading={loading || loadingCurrentMonth}
                spareDays={convertSpareDays(spareDays)}
                onMonthChange={setCurrentDate}
              />
            );
          }}
        </Field>
      </Paper>
    </span>
  );
};

SpareDayDatePicker.propTypes = {
  validate: PropTypes.func,
  currentTeam: PropTypes.any,
  loading: PropTypes.bool,
  defaultValue: PropTypes.any,
  currentCountry: PropTypes.string,
};

export default SpareDayDatePicker;
