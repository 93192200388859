import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { UserAvatar, SMALL } from '../Avatar';
import { Grid, Typography } from '@material-ui/core';
import { prop } from 'ramda';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  name: {
    margin: theme.spacing(1),
  },
}));

const FullNameField = ({ user }) => {
  const classes = useStyles();
  return user ? (
    <Grid className={classes.root}>
      <UserAvatar user={user} size={SMALL} showTooltip />
      <Typography variant="subtitle2" className={classes.name}>
        {prop('fullname', user)}
      </Typography>
    </Grid>
  ) : null;
};

FullNameField.propTypes = {
  user: PropTypes.object,
};

export default FullNameField;
