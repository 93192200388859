import React, { useState, useEffect } from 'react';
import { equals } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory } from 'react-router-dom';
import useRoutes from '../../hooks/routes';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Grid, TablePagination, TableSortLabel, IconButton, Typography, Fab, Chip, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useUsers, useDeleteUser } from './hooks';
import useLoading from '../../hooks/loading';
import FullNameField from './FullNameField';
import { useIntl, FormattedMessage, FormattedRelativeTime } from 'react-intl';
import messages from './messages';
import SearchBar from '../SearchBar';
import { prop, isEmpty } from 'ramda';
import AddIcon from '@material-ui/icons/Add';
import useNotify from '../../hooks/notification';
import NavTitle from '../NavTitle';
import { useLoggedUser } from '../../hooks/user';
import AlertDialog, { AlertDialogContent, AlertDialogTitle } from '../AlertDialog';
import { isCompany } from '../../../lib/models/teams';
import { differenceInSeconds } from 'date-fns';
import { isAdmin, isAdminOrManagerInTeam } from '../../roles';

const useStyles = makeStyles(theme => ({
  title: { color: theme.palette.white },
  root: {
    height: '100%',
    width: '100%',
    overflowX: 'auto',
    margin: 'auto',
  },
  row: {
    cursor: 'pointer',
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  columnSort: {
    fontWeight: 'bold',
    paddingLeft: theme.spacing(3.5),
  },
  absolute: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
    zIndex: 5,
  },
  chip: { margin: theme.spacing(0.2) },
  noDataMessage: { textAlign: 'center' },
}));

const UserList = () => {
  const [loggedUser, loadingLoggedUser] = useLoggedUser();
  const classes = useStyles();
  const routes = useRoutes();
  const history = useHistory();
  const setLoading = useLoading();
  const intl = useIntl();
  const notify = useNotify();
  const [page, setPage] = useState(0);
  const rowsPerPageOptions = [20, 50, 100];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('firstname');
  const [searchValue, setSearchValue] = useState('');
  const [indexOfRowTodDelete, setIndexOfRowTodDelete] = useState(-1);

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleEditRow = id => {
    history.push(routes.getPathByName('userEdit', id));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };

  const requestParams = {
    first: rowsPerPage,
    skip: page * rowsPerPage,
    orderBy: orderBy + '_' + order.toUpperCase(),
  };
  if (searchValue) requestParams.filter = { q: searchValue };

  const [users, count, loading] = useUsers(requestParams, {
    onError: error => notify.error({ title: error.message }),
  });

  const [deleteUser, { loading: deleteLoading }] = useDeleteUser({
    onCompleted: () => notify.success({ title: intl.formatMessage(messages.userDeleted) }),
    onError: () => notify.error({ title: intl.formatMessage(messages.cannotDeleteUser) }),
  });

  const handleDelete = id => {
    deleteUser({ variables: { id } });
  };
  useEffect(() => {
    setLoading(loading || deleteLoading || loadingLoggedUser);
  }, [loading, deleteLoading, loadingLoggedUser, setLoading]);

  useEffect(() => {
    if (!isAdminOrManagerInTeam(loggedUser)) history.replace(routes.getDefaultPath());
  }, []); // eslint-disable-line

  return (
    <>
      <NavTitle message={intl.formatMessage(messages.title)} />

      <Grid data-testid="user.list" container spacing={2} direction="column">
        <Grid item>
          <SearchBar value={searchValue} onChange={setSearchValue} />
        </Grid>
        <Grid item className={classes.root}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'firstname'}
                      direction={orderBy === 'firstname' ? order : 'asc'}
                      onClick={createSortHandler('firstname')}
                      className={classes.columnSort}
                    >
                      <FormattedMessage {...messages.user} />
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'email'}
                      direction={orderBy === 'email' ? order : 'asc'}
                      onClick={createSortHandler('email')}
                      className={classes.columnSort}
                    >
                      <FormattedMessage {...messages.email} />
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'status'}
                      direction={orderBy === 'status' ? order : 'asc'}
                      onClick={createSortHandler('status')}
                      className={classes.columnSort}
                    >
                      <FormattedMessage {...messages.statusLabel} />
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={orderBy === 'lastLogin'}
                      direction={orderBy === 'lastLogin' ? order : 'asc'}
                      onClick={createSortHandler('lastLogin')}
                      className={classes.columnSort}
                    >
                      <FormattedMessage {...messages.lastLogin} />
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" className={classes.bold}>
                    <FormattedMessage {...messages.roles} />
                  </TableCell>
                  <TableCell align="center" className={classes.bold}>
                    <FormattedMessage {...messages.teams} />
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user, index) => (
                  <TableRow
                    hover
                    onClick={() => handleEditRow(user.id)}
                    className={classes.row}
                    key={prop('id', user)}
                    data-testid={`user#${user.id}`}
                  >
                    <TableCell component="th" scope="row">
                      <FullNameField user={user} />
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="subtitle2">{prop('email', user)}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="subtitle2">{prop('status', user)}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="subtitle2">
                        {prop('lastLogin', user) && (
                          <FormattedRelativeTime
                            numeric="auto"
                            updateIntervalInSeconds={10}
                            value={differenceInSeconds(new Date(prop('lastLogin', user)), new Date())}
                            unit="second"
                          />
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {user.roles &&
                        user.roles.map(role => (
                          <Chip
                            key={role}
                            color="secondary"
                            label={<FormattedMessage {...messages.role[role]}></FormattedMessage>}
                            className={classes.chip}
                          />
                        ))}
                    </TableCell>
                    <TableCell align="center">
                      <Grid onClick={event => event.stopPropagation()}>
                        {user.teams &&
                          user.teams.map(team => (
                            <Tooltip
                              key={team.id}
                              title={
                                isCompany(team) ? (
                                  <FormattedMessage {...messages.companyTeam} />
                                ) : (
                                  <FormattedMessage {...messages.projectTeam} />
                                )
                              }
                            >
                              <Chip
                                color="primary"
                                variant={isCompany(team) ? 'outlined' : 'default'}
                                className={classes.chip}
                                label={team.name}
                                onClick={() => history.push(routes.getPathByName('teamEdit', prop('id', team)))}
                              />
                            </Tooltip>
                          ))}
                      </Grid>
                    </TableCell>
                    <TableCell align="center">
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        wrap="nowrap"
                        spacing={0}
                        onClick={event => event.stopPropagation()}
                      >
                        <Grid item>
                          <IconButton
                            onClick={() => history.push(routes.getPathByName('userEdit', prop('id', user)))}
                            color="primary"
                          >
                            <EditIcon />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          {user.email === loggedUser?.email || !isAdmin(loggedUser) ? null : (
                            <IconButton
                              onClick={() => {
                                setIndexOfRowTodDelete(index);
                              }}
                              style={{ zIndex: 5 }}
                            >
                              <DeleteIcon style={{ color: 'red' }} />
                            </IconButton>
                          )}
                        </Grid>
                        <Grid item>
                          <AlertDialog
                            open={equals(indexOfRowTodDelete, index)}
                            onClose={() => setIndexOfRowTodDelete(-1)}
                            onConfirm={() => {
                              setIndexOfRowTodDelete(-1);
                              handleDelete(user.id);
                            }}
                          >
                            <AlertDialogTitle>
                              <FormattedMessage {...messages.delete} />
                            </AlertDialogTitle>
                            <AlertDialogContent>
                              <FormattedMessage {...messages.userDeleteMessage} values={{ fullname: user.fullname }} />
                            </AlertDialogContent>
                          </AlertDialog>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {isEmpty(users) ? (
              <Typography className={classes.noDataMessage}>
                <FormattedMessage {...messages.noData} />
              </Typography>
            ) : null}
            <TablePagination
              className={classes.tablePagination}
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={count || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
        {isAdmin(loggedUser) && (
          <Grid item>
            <Link to={routes.userCreate.path} className={classes.absolute}>
              <Fab color="secondary">
                <AddIcon />
              </Fab>
            </Link>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default UserList;
