import { defineMessages } from 'react-intl';

export default defineMessages({
  calendars: {
    id: 'components.Calendars.calendars',
    defaultMessage: 'Calendars',
  },
  noTeams: {
    id: 'components.Calendars.noTeams',
    defaultMessage: "You don't belong to any Team , please contact your administrator to join one",
  },
});
