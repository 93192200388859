import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/react-hooks';
import { Form, Field, FormSpy } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, Typography, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { addMonths, subMonths, startOfDay, isSameDay } from 'date-fns';
import TextField from '../../forms/TextField';
import SelectField from '../../forms/Select';
import { required } from '../../forms/utils';
import SpareDayDatePicker from '../SpareDayDatePicker';
import { usePeriodSpareDays, useCreateSpareDay } from './hooks';
import useNotify from '../../hooks/notification';
import useLoading from '../../hooks/loading';
import { getNextWorkingDay, convertDateToUTC } from '../../utils';
import NavTitle from '../NavTitle';
import messages from './messages';
import { COUNTRIES_CODE, FRANCE_CODE, NO_TEAM } from '../../../lib/models/teams';
import { map, equals, path } from 'ramda';
import { getTeamByCountry } from '../../../lib/api/team';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import CancelButton from '../Buttons/cancel';
import ConfirmButton from '../Buttons/confirm';
import { convertSpareDays, convertDateToUTCEndOfDay } from './utils';
import ReactCountryFlag from 'react-country-flag';
import { getName } from 'country-list';
import useRoutes from '../../hooks/routes';
import { useLoggedUser } from '../../hooks/user';
import { hasSpareDaysRight } from '../../roles';

const useSyles = makeStyles(theme => ({
  root: { padding: 10 },
  container: {
    padding: theme.spacing(1),
  },
  screen: {
    height: '80vh',
  },
  field: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
}));

const SpareDayCreate = ({ onClose }) => {
  const classes = useSyles();
  const notify = useNotify();
  const setLoading = useLoading();
  const location = useLocation();
  const intl = useIntl();
  const myRef = useRef();
  const apolloClient = useApolloClient();
  const routes = useRoutes();
  const history = useHistory();
  const [loggedUser, loadingLoggedUser] = useLoggedUser();

  const [selectedCountry, setSelectedCountry] = useState(
    location.state ? path(['state', 'country'])(location) : FRANCE_CODE,
  );
  const [selectedTeam, setSelectedTeam] = useState(location.state ? path(['state', 'id'])(location) : NO_TEAM);
  const [nextWorkingDay, setNextWorkingDay] = useState(null);

  const [teamList, setTeamList] = useState([]);
  useEffect(() => {
    getTeamByCountry(apolloClient, selectedCountry).then(data => setTeamList(data));
  }, [apolloClient, selectedCountry]);

  const [spareDays, loading] = usePeriodSpareDays(
    {
      from: subMonths(new Date(), 1),
      to: addMonths(new Date(), 1),
      country: selectedCountry,
      teamId: selectedTeam,
    },
    {
      onError: error => notify.error({ title: error.message }),
    },
  );

  const [addSpareDay, { loading: createLoading }] = useCreateSpareDay({
    onCompleted: ({ createSpareDay }) =>
      notify.success({
        title: intl.formatMessage(messages.spareDayCreated, { label: createSpareDay.label }),
      }),
    onError: error => notify.error({ title: `${intl.formatMessage(messages.cannotCreateNewSpareDay)} : ${error}` }),
  });

  const onSubmit = async values => {
    equals(values.team, NO_TEAM)
      ? await addSpareDay({
          variables: {
            country: values.country,
            label: values.label,
            from: convertDateToUTC(startOfDay(values.from)),
            to: convertDateToUTCEndOfDay(values.from),
          },
        })
      : await addSpareDay({
          variables: {
            teamId: values.team,
            label: values.label,
            from: convertDateToUTC(startOfDay(values.from)),
            to: convertDateToUTCEndOfDay(values.from),
          },
        });
    onClose();
  };

  useEffect(() => {
    setLoading(loading || createLoading || loadingLoggedUser);
  }, [loading, createLoading, setLoading, loadingLoggedUser]);
  useEffect(() => {
    setNextWorkingDay(getNextWorkingDay(convertSpareDays(spareDays), new Date()));
  }, [spareDays]);

  useEffect(() => {
    if (!hasSpareDaysRight(loggedUser)) history.replace(routes.getDefaultPath());
  }, []); // eslint-disable-line

  return (
    <div className={classes.root}>
      <NavTitle message={intl.formatMessage(messages.createSpareDay)} />
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, pristine, invalid, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <Prompt
              when={!pristine && !submitting && !isSameDay(new Date(nextWorkingDay), new Date(values.from))}
              message={intl.formatMessage(messages.prompt)}
            />
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
              spacing={1}
              className={classes.container}
            >
              <Grid item xs={6}>
                <Field
                  className={classes.field}
                  fullWidth
                  component={SelectField}
                  label={<FormattedMessage {...messages.country} />}
                  placeholder={`${intl.formatMessage(messages.country)}`}
                  name="country"
                  initialValue={selectedCountry}
                  validate={required(<FormattedMessage {...messages.requiredCountry} />)}
                >
                  {map(code => (
                    <MenuItem key={code} value={code}>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <ReactCountryFlag
                            style={{
                              width: '1.3em',
                              height: '1.3em',
                            }}
                            countryCode={code}
                            svg
                          />
                        </Grid>
                        <Grid item>
                          <Typography>{getName(code)}</Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  ))(COUNTRIES_CODE)}
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  className={classes.field}
                  fullWidth
                  component={SelectField}
                  label={<FormattedMessage {...messages.team} />}
                  placeholder={`${intl.formatMessage(messages.team)}`}
                  name="team"
                  initialValue={selectedTeam}
                >
                  <MenuItem key={NO_TEAM} value={NO_TEAM}>
                    <Typography>
                      <em>
                        <FormattedMessage {...messages.noTeam} />
                      </em>
                    </Typography>
                  </MenuItem>
                  {map(value => (
                    <MenuItem key={value.id} value={value.id}>
                      <Typography>{value.name}</Typography>
                    </MenuItem>
                  ))(teamList)}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field
                  required
                  validate={required('Required')}
                  className={classes.field}
                  component={TextField}
                  label={<FormattedMessage {...messages.label} />}
                  name="label"
                  data-testid="spareday.create.input.label"
                  inputRef={myRef}
                />
              </Grid>
              <Grid item xs={12}>
                <SpareDayDatePicker
                  name="from"
                  defaultValue={nextWorkingDay}
                  currentCountry={selectedCountry}
                  currentTeam={selectedTeam}
                  loading={loading || createLoading}
                />
              </Grid>
              <Grid item xs={6}>
                <CancelButton className={classes.field} />
              </Grid>
              <Grid item xs={6}>
                <ConfirmButton
                  data-testid="spareday.create.submit"
                  disabled={pristine || invalid}
                  className={classes.field}
                />
              </Grid>
            </Grid>
            <FormSpy
              subscription={{
                values: true,
              }}
              onChange={async ({ values }) => {
                let teamsOfCurrentCountry;

                if (selectedCountry !== values.country) {
                  values.team = NO_TEAM;
                  myRef.current.focus();

                  teamsOfCurrentCountry = await getTeamByCountry(apolloClient, values.country);

                  setTeamList(teamsOfCurrentCountry);
                  setSelectedCountry(values.country);
                }
                if (selectedTeam !== values.team) {
                  myRef.current.focus();
                  setSelectedTeam(values.team);
                }
              }}
            />
          </form>
        )}
      />
    </div>
  );
};

SpareDayCreate.propTypes = {
  onClose: PropTypes.func,
};

export default SpareDayCreate;
