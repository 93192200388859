import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/react-hooks';
import { Form, Field, FormSpy } from 'react-final-form';
import { getUsersByTeamId } from '../../../lib/api/team';
import { isProject, hasMembers } from '../../../lib/models/teams';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { startOfDay } from 'date-fns';
import TextField from '../../forms/TextField';
import SelectField from '../../forms/Select';
import { required, checkPositiveNumber, composeValidators, checkWorkingDaysMax } from '../../forms/utils';
import useNotify from '../../hooks/notification';
import useLoading from '../../hooks/loading';
import { convertDateToUTC, isManagerOrheadWorker } from '../../utils';
import NavTitle from '../NavTitle';
import messages from './messages';
import { path, map, sortBy, prop, compose, filter } from 'ramda';
import { Prompt, useHistory } from 'react-router-dom';
import CancelButton from '../Buttons/cancel';
import ConfirmButton from '../Buttons/confirm';
import useRoutes from '../../hooks/routes';
import { useLoggedUser } from '../../hooks/user';
import { isAdmin, isAdminOrManagerInTeam } from '../../roles';
import { useCreateAmendment } from './hooks';
import { convertDateToUTCEndOfDay } from '../SpareDays/utils';
import { useTeams } from '../Teams/hooks';
import DatePickerForm from '../../forms/DateTimePicker';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const useSyles = makeStyles(theme => ({
  root: { padding: 10 },
  container: {
    padding: theme.spacing(1),
  },
  screen: {
    height: '80vh',
  },
  field: {
    paddingTop: theme.spacing(2),
    width: '100%',
  },
  textField: {
    paddingTop: theme.spacing(1),
    width: '100%',
  },
  cancelButton: {
    marginTop: theme.spacing(2),
  },
}));

const AmendmentCreate = ({ onClose }) => {
  const classes = useSyles();
  const notify = useNotify();
  const setLoading = useLoading();
  const intl = useIntl();
  const myRef = useRef();
  const apolloClient = useApolloClient();
  const routes = useRoutes();
  const history = useHistory();
  const [loggedUser, loadingLoggedUser] = useLoggedUser();

  const [teams, , loading] = useTeams(
    {},
    {
      onError: error => notify.error({ title: error.message }),
    },
  );

  const [selectedTeamId, setSelectedTeamId] = useState();
  const [selectedUserId, setSelectedUserId] = useState();

  const [usersList, setUsersList] = useState([]);
  const [addAmendment, { loading: createLoading }] = useCreateAmendment({
    onCompleted: () =>
      notify.success({
        title: intl.formatMessage(messages.amendmentCreated),
      }),
    onError: error => notify.error({ title: `${intl.formatMessage(messages.cannotCreateNewAmendment)} : ${error}` }),
  });

  const onSubmit = async values => {
    await addAmendment({
      variables: {
        from: convertDateToUTC(startOfDay(values.from)),
        to: convertDateToUTCEndOfDay(values.to),
        personId: values.personId,
        teamId: values.teamId,
        days: parseInt(values.days),
        description: values.description,
      },
    });
    onClose();
  };

  useEffect(() => {
    setLoading(loading || createLoading || loadingLoggedUser);
  }, [createLoading, setLoading, loadingLoggedUser, loading]);

  useEffect(() => {
    if (!isAdminOrManagerInTeam(loggedUser)) history.replace(routes.getDefaultPath());
  }, [loggedUser]); // eslint-disable-line

  const selectedTeams = compose(filter(hasMembers), filter(isProject), sortBy(prop('name')))(teams);
  return (
    <Grid className={classes.root}>
      <NavTitle message={intl.formatMessage(messages.createAmendment)} />
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, pristine, invalid, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <Prompt when={!pristine && !submitting} message={intl.formatMessage(messages.prompt)} />
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
              spacing={1}
              className={classes.container}
            >
              <Grid item xs={6}>
                <Field
                  fullWidth
                  required
                  component={SelectField}
                  label={<FormattedMessage {...messages.team} />}
                  placeholder={`${intl.formatMessage(messages.team)}`}
                  name="teamId"
                  initialValue={selectedTeamId}
                  validate={required(<FormattedMessage {...messages.required} />)}
                  data-testid="amendment.create.input.teamId"
                >
                  {map(
                    team =>
                      (isAdmin(loggedUser) || isManagerOrheadWorker(loggedUser, team)) && (
                        <MenuItem key={team.id} value={team.id}>
                          <Typography>{team.name}</Typography>
                        </MenuItem>
                      ),
                    selectedTeams,
                  )}
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  initialValue={selectedUserId}
                  fullWidth
                  validate={required(<FormattedMessage {...messages.required} />)}
                  required
                  component={SelectField}
                  label={<FormattedMessage {...messages.user} />}
                  placeholder={`${intl.formatMessage(messages.user)}`}
                  name="personId"
                  data-testid="amendment.create.input.personId"
                >
                  {map(user => (
                    <MenuItem key={user.person.id} value={user.person.id}>
                      <Typography> {user.person.fullname}</Typography>
                    </MenuItem>
                  ))(sortBy(path(['person', 'fullname']), usersList))}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Field
                    required
                    className={classes.field}
                    name="from"
                    inputRef={myRef}
                    component={DatePickerForm}
                    placeholder={`${intl.formatMessage(messages.fromPlaceholder)}`}
                    maxDate={values.to}
                    data-testid="amendment.create.input.from"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Field
                    required
                    className={classes.field}
                    name="to"
                    component={DatePickerForm}
                    placeholder={`${intl.formatMessage(messages.toPlaceholder)}`}
                    minDate={values.from}
                    data-testid="amendment.create.input.to"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12}>
                <Field
                  required
                  validate={composeValidators(
                    required(<FormattedMessage {...messages.required} />),
                    checkPositiveNumber(<FormattedMessage {...messages.positiveNumber} />),
                    checkWorkingDaysMax(apolloClient, values)(<FormattedMessage {...messages.workingDaysMax} />),
                  )}
                  className={classes.textField}
                  component={TextField}
                  keyboard={false}
                  type="number"
                  label={<FormattedMessage {...messages.days} />}
                  name="days"
                  data-testid="amendment.create.input.days"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  className={classes.textField}
                  component={TextField}
                  keyboard={false}
                  label={<FormattedMessage {...messages.description} />}
                  name="description"
                  multiline
                  rows={3}
                  data-testid="amendment.create.input.description"
                />
              </Grid>
              <Grid item xs={6}>
                <CancelButton className={classes.cancelButton} />
              </Grid>
              <Grid item xs={6}>
                <ConfirmButton
                  className={classes.cancelButton}
                  data-testid="amendment.create.submit"
                  disabled={pristine || invalid}
                />
              </Grid>
            </Grid>
            <FormSpy
              subscription={{
                values: true,
              }}
              onChange={async ({ values }) => {
                let usersOfCurrentTeam;

                if (selectedTeamId !== values.teamId) {
                  values.personId = null;
                  myRef.current.focus();
                  usersOfCurrentTeam = await getUsersByTeamId(apolloClient, values.teamId);
                  setUsersList(usersOfCurrentTeam);
                  setSelectedTeamId(values.teamId);
                }
                if (selectedUserId !== values.personId) {
                  myRef.current.focus();
                  setSelectedUserId(values.personId);
                }
              }}
            />
          </form>
        )}
      />
    </Grid>
  );
};

AmendmentCreate.propTypes = {
  onClose: PropTypes.func,
};

export default AmendmentCreate;
