import React, { useState, useEffect } from 'react';
import { Typography, Tabs, Tab, Grid, makeStyles, CircularProgress } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import WarningIcon from '@material-ui/icons/Warning';
import Paper from '@material-ui/core/Paper';
import useRoutes from '../../hooks/routes';
import Nav from '../Nav';
import messages from './messages';
import { find, map, prop, contains } from 'ramda';
import { useLoggedUser } from '../../hooks/user';
import useLoading from '../../hooks/loading';
import Calendar from '../Calendar';
import ProjectStats from '../ProjectStats';
import CompanyStats from '../CompanyStats';
import { useParams, useHistory } from 'react-router-dom';
import { convertTeam } from '../Teams/utils';
import { isProject } from '../../../lib/models/teams';
import { useTeams } from './hooks';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#fafafa',
  },
  agendaIcon: { marginRight: theme.spacing(1) },
  noPadding: {
    padding: 0,
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  icon: {
    fontSize: '12em',
    color: '#F0F0F0',
  },
  message: {
    color: theme.palette.grey[700],
    padding: theme.spacing(2),
  },
}));

const Calendars = () => {
  const classes = useStyles();
  const [user, userLoading] = useLoggedUser();
  const [teams, teamsLoading] = useTeams({ orderBy: 'name_ASC' });
  const setLoading = useLoading();
  const history = useHistory();
  const routes = useRoutes();
  const { id } = useParams();

  const [currentTeamId, setCurrentTeamId] = useState();
  const handleChange = (event, id) => {
    setCurrentTeamId(id);
  };

  const getCurrentTeam = id => find(team => team.id === id, teams);

  useEffect(() => {
    if (currentTeamId) history.replace(`${routes.calendars.path}/${currentTeamId}`);
  }, [currentTeamId]); //eslint-disable-line

  useEffect(() => {
    setLoading(userLoading || teamsLoading);
  }, [setLoading, userLoading, teamsLoading]);

  useEffect(() => {
    if (!teamsLoading && !userLoading) {
      if (id && teams.length && contains(id, map(prop('id'), teams))) {
        setCurrentTeamId(id);
      } else {
        setCurrentTeamId(teams?.[0]?.id);
      }
    }
  }, [userLoading, teamsLoading, id, teams]);

  if (!currentTeamId || teamsLoading || userLoading)
    return (
      <>
        <Nav>
          <Typography>
            <FormattedMessage {...messages.calendars} />
          </Typography>
        </Nav>
        <Grid container justify="center" alignItems="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      </>
    );

  const currentTeam = getCurrentTeam(currentTeamId);

  if (!currentTeam)
    return (
      <>
        <Nav>
          <Typography>
            <FormattedMessage {...messages.calendars} />
          </Typography>
        </Nav>
        <Grid container item direction="column" justify="center" alignItems="center">
          <Grid item>
            <WarningIcon className={classes.icon} />
          </Grid>
          <Grid item>
            <Typography variant="h6" className={classes.message} align="center">
              <FormattedMessage {...messages.noTeams}></FormattedMessage>
            </Typography>
          </Grid>
        </Grid>
      </>
    );

  return (
    <>
      <Nav>
        <Typography>
          <FormattedMessage {...messages.calendars} />
        </Typography>
      </Nav>
      <Paper elevation={0} className={classes.root} square={true}>
        <Tabs
          indicatorColor="primary"
          value={currentTeamId}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {map(team => <Tab key={team.id} value={team.id} label={team.name} />)(teams)}
        </Tabs>
        <Calendar team={convertTeam(currentTeam)} user={user} />
        {isProject(currentTeam) ? (
          <ProjectStats team={convertTeam(currentTeam)} />
        ) : (
          <CompanyStats team={convertTeam(currentTeam)} />
        )}
      </Paper>
    </>
  );
};

export default Calendars;
