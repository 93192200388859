import { defineMessages } from 'react-intl';

export default defineMessages({
  passwordUpdated: {
    id: 'components.ResetPassword.passwordUpdated',
    defaultMessage: 'Password updated',
  },
  error: {
    id: 'components.ResetPassword.error',
    defaultMessage: 'Something went wrong',
  },
  changePassword: {
    id: 'components.ResetPassword.changePassword',
    defaultMessage: 'Change Your Password',
  },
  resetPassword: {
    id: 'components.ResetPassword.resetPassword',
    defaultMessage: 'Reset Password',
  },
  password: {
    id: 'components.ResetPassword.password',
    defaultMessage: 'Password',
  },
  confirmPassword: {
    id: 'components.ResetPassword.confirmPassword',
    defaultMessage: 'Confirm Password',
  },
});
