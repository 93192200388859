import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  recaptcha: {
    marginTop: theme.spacing(2),
  },
}));
const Captcha = ({ input: { onChange, name }, sitekey, hl }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.recaptcha}>
      <ReCAPTCHA hl={hl} sitekey={sitekey} onChange={onChange} name={name} />
    </Grid>
  );
};
Captcha.propTypes = {
  input: PropTypes.object.isRequired,
  sitekey: PropTypes.string.isRequired,
  hl: PropTypes.string,
};

export default Captcha;
