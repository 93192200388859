import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'components.Amendments.title',
    defaultMessage: 'Amendments',
  },
  confirm: {
    id: 'components.Amendments.confirm',
    defaultMessage: 'Save',
  },
  createAmendment: {
    id: 'components.Amendments.createAmendment',
    defaultMessage: 'Create Amendment',
  },
  cancel: {
    id: 'components.Amendments.cancel',
    defaultMessage: 'Cancel',
  },
  updateAmendment: {
    id: 'components.Amendments.updateAmendment',
    defaultMessage: 'Update Amendment',
  },
  days: {
    id: 'components.Amendments.days',
    defaultMessage: 'Number of working days',
  },
  from: {
    id: 'components.Amendments.from',
    defaultMessage: 'From',
  },
  description: {
    id: 'components.Amendments.description',
    defaultMessage: 'Description',
  },
  user: {
    id: 'components.Amendments.user',
    defaultMessage: 'User',
  },
  action: {
    id: 'components.Amendments.action',
    defaultMessage: 'Action',
  },
  edit: {
    id: 'components.Amendments.edit',
    defaultMessage: 'Edit',
  },
  delete: {
    id: 'components.Amendments.delete',
    defaultMessage: 'Delete',
  },
  amendmentCreated: {
    id: 'components.Amendments.createNewAmendment',
    defaultMessage: 'Amendment created',
  },
  cannotCreateNewAmendment: {
    id: 'components.Amendments.cannotCreateNewAmendment',
    defaultMessage: 'Cannot create Amendment',
  },
  editAmendment: {
    id: 'components.Amendments.editAmendment',
    defaultMessage: 'Edit Amendment',
  },
  amendmentEdited: {
    id: 'components.Amendments.amendmentEdited',
    defaultMessage: 'Amendment updated',
  },
  amendmentDeleted: {
    id: 'components.Amendments.amendmentDeleted',
    defaultMessage: 'Amendment deleted',
  },
  cannotDeleteAmendment: {
    id: 'components.Amendments.cannotDeleteAmendment',
    defaultMessage: 'Failed to delete Amendment!',
  },
  cannotEditAmendment: {
    id: 'components.Amendments.cannotEditAmendment',
    defaultMessage: 'Cannot edit Amendment',
  },
  country: {
    id: 'components.Amendments.country',
    defaultMessage: 'Country',
  },
  team: {
    id: 'components.Amendments.project',
    defaultMessage: 'Project',
  },
  to: {
    id: 'components.Amendments.to',
    defaultMessage: 'To',
  },
  noTeam: {
    id: 'components.Amendments.noTeam',
    defaultMessage: 'No team',
  },
  prompt: {
    id: 'components.Amendments.prompt',
    defaultMessage: 'Are you sure you want to leave?',
  },
  amendmentDeleteMessage: {
    id: 'components.Amendments.amendmentDeleteMessage',
    defaultMessage: 'Are you sure you want to delete this Amendment ?',
  },
  noAmendments: {
    id: 'components.Amendments.noAmendments',
    defaultMessage: 'No Amendments available',
  },
  allUsers: {
    id: 'components.Amendments.allUsers',
    defaultMessage: 'All Users',
  },
  allTeams: {
    id: 'components.Amendments.allTeams',
    defaultMessage: 'All Teams',
  },
  fromPlaceholder: {
    id: 'components.Amendments.fromPlaceholder',
    defaultMessage: 'From *',
  },
  toPlaceholder: {
    id: 'components.Amendments.toPlaceholder',
    defaultMessage: 'To *',
  },
  required: {
    id: 'components.Amendments.required',
    defaultMessage: 'Required',
  },
  positiveNumber: {
    id: 'components.Amendments.positiveNumber',
    defaultMessage: 'Days should be positive',
  },
  workingDaysMax: {
    id: 'components.Amendments.workingDaysMax',
    defaultMessage: 'Working days max : ',
  },
});
