import React from 'react';

import { Button } from '@material-ui/core';
import messages from './messages';
import { FormattedMessage } from 'react-intl';
import CancelIcon from '@material-ui/icons/Cancel';
import { useHistory } from 'react-router-dom';

const CancelButton = ({ ...rest }) => {
  const history = useHistory();
  return (
    <Button
      {...rest}
      fullWidth
      color="secondary"
      variant="contained"
      onClick={() => history.goBack()}
      startIcon={<CancelIcon />}
    >
      <FormattedMessage {...messages.cancel} />
    </Button>
  );
};

export default CancelButton;
