export const STATUS = {
  pending: 'pending',
  active: 'active',
  inactive: 'inactive',
};

export const ROLE = {
  admin: 'admin',
  user: 'user',
};
