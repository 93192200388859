import React, { useState } from 'react';
import { element } from 'prop-types';
import Notification from '../Notification';

export const NotificationContext = React.createContext();

const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState();
  return (
    <NotificationContext.Provider value={setNotification}>
      <Notification notification={notification} setNotification={setNotification} />
      {React.Children.only(children)}
    </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  children: element.isRequired,
};

export default NotificationProvider;
