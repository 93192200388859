import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';

export const GET_LOGGED_USER_ID = gql`
  query LoggedUserId {
    loggedUserId @client
  }
`;

export const GET_USER = gql`
  query User($loggedUserId: ID!) {
    User(id: $loggedUserId) {
      id
      firstname
      lastname
      fullname
      email
      roles
      rights
      status
      teams {
        id
        name
        description
        country
        type
        members {
          person {
            id
            firstname
            lastname
            fullname
            email
          }
          role
          activityPeriods {
            inceptionDate
            expirationDate
          }
        }
      }
    }
  }
`;
export const useLoggedUser = () => {
  const logged = useQuery(GET_LOGGED_USER_ID);
  const loggedUserId = logged?.data?.loggedUserId;
  const res = useQuery(GET_USER, {
    skip: !loggedUserId,
    variables: { loggedUserId },
  });
  if (!loggedUserId) return [null, false];
  return [res.data?.User, res.loading, res.error];
};

export const useUpdateLoggedUser = options => {
  const UPDATE_USER = gql`
    mutation UpdateUser(
      $id: ID!
      $firstname: String
      $lastname: String
      $email: String
      $status: UserStatus
      $roles: [UserRole]
    ) {
      updateUser(id: $id, firstname: $firstname, lastname: $lastname, email: $email, status: $status, roles: $roles) {
        id
        firstname
        lastname
        fullname
        email
        status
        roles
        rights
        teams {
          id
          name
          description
          country
          members {
            person {
              id
              firstname
              lastname
              fullname
              email
            }
          }
        }
      }
    }
  `;
  return useMutation(UPDATE_USER, {
    ...options,
    // update(cache, { data: { updateUser } }) {
    //   const { user } = cache.readQuery({ query: GET_USER_ID });
    //   cache.writeQuery({
    //     query: GET_USER_ID,
    //     data: updateUser(user),
    //   });
    // },
  });
};
