import React, { useEffect, useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { Button, Typography, MenuItem, Chip, IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useApolloClient } from '@apollo/react-hooks';
import TextField from '../../forms/TextField';
import AutocompleteField from '../../forms/AutoCompleteField';
import { useHistory, Prompt } from 'react-router';
import useRoutes from '../../hooks/routes';
import { useCreateTeam, searchUsers, useDebounce } from './hooks';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { formatMembersArray, checkMember, COUNTRIES_CODE, FRANCE_CODE } from './utils';
import { getName } from 'country-list';
import { map, prop, path, values as rvalues, filter, remove } from 'ramda';
import { UserAvatar, XSMALL } from '../Avatar';
import DatePickerForm from '../../forms/DateTimePicker';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import messages from './messages';
import { useIntl, FormattedMessage } from 'react-intl';
import useNotify from '../../hooks/notification';
import useLoading from '../../hooks/loading';
import { required, checkTeamNameUniqueness, composeValidators } from '../../forms/utils';
import SelectField from '../../forms/SelectField';
import NavTitle from '../NavTitle';
import CancelButton from '../Buttons/cancel';
import ConfirmButton from '../Buttons/confirm';
import { isManager, isObserver, ROLE, rolesForTeamsCreateRight } from '../../../lib/models/members';
import { TYPE } from '../../../lib/models/teams';
import ReactCountryFlag from 'react-country-flag';
import { hasRight, RIGHT, isAdmin } from '../../roles';
import { useLoggedUser } from '../../hooks/user';

const DEBOUNCE_DELAY = 500;

const useStyles = makeStyles(theme => ({
  button: {
    [theme.breakpoints.up('md')]: {
      width: '75%',
    },
  },
  deleteIcon: {
    marginTop: theme.spacing(1.5),
    cursor: 'pointer',
  },
  datePicker: {
    marginTop: theme.spacing(2),
  },
  itemMember: { height: theme.spacing(3) },
  addMembersForm: { marginBottom: theme.spacing(2.5), width: '100%', justifyContent: 'flex-end' },
}));

const TeamCreate = () => {
  const classes = useStyles();
  const notify = useNotify();
  const setLoading = useLoading();
  const intl = useIntl();
  const routes = useRoutes();
  const history = useHistory();
  const [options, setOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState();
  const [index, setIndex] = useState();
  const debouncedSearchTerm = useDebounce(searchTerm, DEBOUNCE_DELAY);
  const [loggedUser, loadingLoggedUser] = useLoggedUser();

  useEffect(() => {
    if (debouncedSearchTerm) {
      searchUsers(apolloClient, debouncedSearchTerm).then(results => {
        const updatedOptions = [...options];
        updatedOptions[index] = results;
        setOptions([...updatedOptions]);
      });
    }
  }, [debouncedSearchTerm]); // eslint-disable-line

  const [createTeam, { loading: createLoading }] = useCreateTeam({
    onCompleted: ({ createTeam }) =>
      notify.success({ title: intl.formatMessage(messages.teamCreated, { name: createTeam.name }) }),
    onError: error => notify.error({ title: `${intl.formatMessage(messages.cannotCreateNewTeam)} : ${error}` }),
  });

  const apolloClient = useApolloClient();

  const onSubmit = async values => {
    await createTeam({
      variables: {
        name: values.name,
        description: values.description,
        members: formatMembersArray(values.members),
        country: values.country,
        type: values.type,
      },
    });
    history.push(routes.teamList.path);
  };

  useEffect(() => {
    setLoading(createLoading || loadingLoggedUser);
  }, [createLoading, setLoading, loadingLoggedUser]);

  useEffect(() => {
    if (!isAdmin(loggedUser) && !hasRight(loggedUser, [RIGHT.teamsCreate])) history.replace(routes.getDefaultPath());
  }, [loggedUser]); // eslint-disable-line

  const initialStateValue = {
    members: [{ person: loggedUser, role: ROLE.manager }],
    country: FRANCE_CODE,
    type: TYPE.company,
  };
  return (
    <>
      <NavTitle message={intl.formatMessage(messages.createTeam)} />
      <Form
        onSubmit={onSubmit}
        initialValues={!isAdmin(loggedUser) ? initialStateValue : { country: FRANCE_CODE, type: TYPE.company }}
        keepDirtyOnReinitialize
        mutators={{
          ...arrayMutators,
        }}
        render={({
          handleSubmit,
          pristine,
          invalid,
          values,
          submitting,
          errors,
          form: {
            mutators: { push },
          },
        }) => (
          <form onSubmit={handleSubmit}>
            <Prompt when={!pristine && !submitting} message={intl.formatMessage(messages.prompt)} />
            <Grid container alignItems="flex-start" spacing={3} className={classes.root}>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  required
                  validate={composeValidators(
                    required(<FormattedMessage {...messages.requiredName} />),
                    checkTeamNameUniqueness(apolloClient)(<FormattedMessage {...messages.nameTaken} />),
                  )}
                  component={TextField}
                  label={<FormattedMessage {...messages.name} />}
                  name="name"
                  data-testid="team.create.input.name"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  component={TextField}
                  label={<FormattedMessage {...messages.description} />}
                  name="description"
                  multiline
                  rows={3}
                  data-testid="team.create.input.description"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  required
                  component={SelectField}
                  label={<FormattedMessage {...messages.typeLabel} />}
                  name="type"
                  validate={required(<FormattedMessage {...messages.requiredType} />)}
                >
                  {map(type => (
                    <MenuItem key={type} value={type}>
                      <Typography>
                        <FormattedMessage {...messages.type[type]}></FormattedMessage>
                      </Typography>
                    </MenuItem>
                  ))(rvalues(TYPE))}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  required
                  component={SelectField}
                  label={<FormattedMessage {...messages.country} />}
                  placeholder={`${intl.formatMessage(messages.country)}`}
                  name="country"
                  validate={required(<FormattedMessage {...messages.requiredCountry} />)}
                >
                  {map(code => (
                    <MenuItem key={code} value={code}>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <ReactCountryFlag
                            style={{
                              width: '1.3em',
                              height: '1.3em',
                            }}
                            countryCode={code}
                            svg
                          />
                        </Grid>
                        <Grid item>
                          <Typography>{getName(code)}</Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  ))(COUNTRIES_CODE)}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={!!errors.members}
                  size="small"
                  color="primary"
                  onClick={() => push('members', {})}
                  data-testid="team.create.button.addMembers"
                >
                  <AddIcon />
                  <Typography color="inherit" noWrap>
                    <FormattedMessage {...messages.addMembers} />
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={12} container direction="column-reverse">
                <FieldArray name="members">
                  {({ fields }) =>
                    fields.map((member, index) => (
                      <Grid key={member} item container spacing={2} className={classes.addMembersForm}>
                        <Grid item xs={3} className={classes.itemMember}>
                          <Field
                            fullWidth
                            requiredField
                            component={AutocompleteField}
                            disabled={!isAdmin(loggedUser) && values.members[index].person?.id === loggedUser?.id}
                            size="small"
                            name={`${member}.person`}
                            label={<FormattedMessage {...messages.user} />}
                            validate={required(<FormattedMessage {...messages.requiredSelectUser} />)}
                            data-testid="team.create.input.person.id"
                            options={options[index] ? options[index] : []}
                            filterOptions={options =>
                              filter(
                                user =>
                                  !checkMember(user.id, fields.value) ||
                                  user.id === path(['members', index, 'person', 'id'], values),
                              )(options)
                            }
                            getOptionSelected={(option, value) => option.fullname === value.fullname}
                            noOptionsText={<FormattedMessage {...messages.noOptions}></FormattedMessage>}
                            getOptionLabel={option => (option ? option.fullname : option)}
                            renderOption={user => (
                              <Chip
                                key={prop('id', user)}
                                color="primary"
                                avatar={<UserAvatar user={user} size={XSMALL} />}
                                label={user.fullname}
                                size="small"
                              />
                            )}
                            onInputChange={async (event, searchTerm) => {
                              if (!searchTerm.length) {
                                const updatedOptions = [...options];
                                updatedOptions[index] = [];
                                setOptions([...updatedOptions]);
                              }
                              if (searchTerm.length >= 4) {
                                setSearchTerm(searchTerm);
                                setIndex(index);
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={3} className={classes.itemMember}>
                          <Field
                            fullWidth
                            component={SelectField}
                            disabled={
                              hasRight(loggedUser, [RIGHT.teamsCreate]) &&
                              !isAdmin(loggedUser) &&
                              values.members[index].person?.id === loggedUser?.id
                            }
                            required
                            label={<FormattedMessage {...messages.role} />}
                            name={`${member}.role`}
                            validate={required(<FormattedMessage {...messages.requiredRole} />)}
                            data-testid="team.create.input.person.role"
                          >
                            {map(role => (
                              <MenuItem key={role} value={role}>
                                <Typography>
                                  <FormattedMessage {...messages[role]} />
                                </Typography>
                              </MenuItem>
                            ))(
                              hasRight(loggedUser, [RIGHT.teamsCreate]) &&
                                !isAdmin(loggedUser) &&
                                values.members[index].person?.id !== loggedUser?.id
                                ? rolesForTeamsCreateRight
                                : rvalues(ROLE),
                            )}
                          </Field>
                        </Grid>
                        <FormSpy
                          subscription={{
                            values: true,
                          }}
                          onChange={async ({ values }) => {
                            if (isObserver(values?.members[index]) || isManager(values?.members[index])) {
                              values.members[index].activityPeriod = null;
                            }
                          }}
                        />
                        <Grid item xs={6} container spacing={2}>
                          <Grid item xs={5} className={classes.datePickerStyle}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <Field
                                disabled={isObserver(values.members[index]) || isManager(values.members[index])}
                                className={classes.datePicker}
                                name={`${member}.activityPeriod.inceptionDate`}
                                component={DatePickerForm}
                                maxDate={values.members[index].activityPeriod?.expirationDate}
                                placeholder={`${intl.formatMessage(messages.inceptionDate)}`}
                                data-testid="team.edit.input.person.inceptionDate"
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid item xs={5} className={classes.datePickerStyle}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <Field
                                disabled={isObserver(values.members[index]) || isManager(values.members[index])}
                                className={classes.datePicker}
                                name={`${member}.activityPeriod.expirationDate`}
                                component={DatePickerForm}
                                minDate={values.members[index].activityPeriod?.inceptionDate}
                                placeholder={`${intl.formatMessage(messages.expirationDate)}`}
                                data-testid="team.edit.input.person.expirationDate"
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton
                              disabled={
                                hasRight(loggedUser, [RIGHT.teamsCreate]) &&
                                !isAdmin(loggedUser) &&
                                values.members[index].person?.id === loggedUser?.id
                              }
                              onClick={() => {
                                fields.remove(index);
                                setOptions(remove(index, 1, options));
                              }}
                              className={classes.deleteIcon}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))
                  }
                </FieldArray>
              </Grid>

              <Grid item xs={12} sm={6}>
                <CancelButton data-testid="team.create.cancel" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ConfirmButton disabled={pristine || invalid} data-testid="team.create.submit" />
              </Grid>
            </Grid>
          </form>
        )}
      />
    </>
  );
};

export default TeamCreate;
